.contentTitle {
	font-weight: var(--dialog-with-image-contentTitle-font-weight);
	font-size: var(--dialog-with-image-contentTitle-font-size);
	line-height: var(--dialog-with-image-contentTitle-line-height);
	font-family: var(--dialog-with-image-contentTitle-font-family) !important;
	/*padding-top: var(--dialog-with-image-contentTitle-padding-top);*/
	width: 100%;
	/*text-align: center;*/
	color: var(--color-grey-900) !important;
}

.contentText {
	font-family: var(--dialog-with-image-contentText-font-family);
	font-style: var(--dialog-with-image-contentText-font-style);
	font-weight: var(--dialog-with-image-contentText-font-weight);
	font-size: var(--dialog-with-image-contentText-font-size);
	line-height: var(--dialog-with-image-contentText-line-height);
	color: var(--dialog-with-image-contentText-color);
	margin-bottom: var(--dialog-with-image-contentText-margin-bottom);
	padding-top: var(--dialog-with-image-contentText-padding-top);
	align-self: center;
	width: 100%;
	/*text-align: center;*/
}

.dialogIcon {
	min-height: var(--dialog-with-image-and-text-min-height) !important;
	/*max-height: var(--dialog-with-image-and-text-max-height) !important;*/
	/*max-width: var(--dialog-with-image-and-text-max-width);*/
	display: flex;
	flex-direction: column;
}

.proFeatureIcon {
	width: 16px;
	height: 16px;
	line-height: 0;
	overflow: hidden;
}

.proFeatureIconImg {
	width: 100%;
}

.upgradeIcon,
.image {
	/*height: var(--dialog-with-image-and-text-height);*/
	width: 100%;
	border-radius: var(--item-radius-8);
	margin-bottom: 16px;
}

.proFeatureIconWrapper {
	border-radius: var(--item-radius-6);
	background: var(--color-orange-50);
	display: inline-flex;
	padding: 6px 8px;
	align-items: center;
	gap: 4px;
	margin-bottom: 16px !important;
	width: fit-content;
}

.proFeatureText {
	color: var(--color-orange);
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-title-font-weight);
	line-height: normal;
}
