.sectionTitle {
	font-family: var(--profileDialog-naameTitle-font-family);
	font-style: normal;
	font-size: var(--profileDialog-naameTitle-font-size);
	display: flex;
	align-items: center;
	line-height: 20px;
	font-weight: var(--global-title-font-weight);
	color: var(--profileDialog-title-text-color);
}

.descriptionWrapper {
	padding: 10px 8px;
	display: flex;
	border-radius: var(--item-radius-6);
	background-color: var(--color-info-bg);
	color: var(--color-blue-info);
	gap: 8px;
}

.infoIcon {
	margin-top: -3px;
}

.description {
	line-height: 20px;
}

.wrapper {
	width: 100%;
}

.sectionWrapper {
	padding-top: 24px;
	width: 100%;
}

.title {
	color: var(--profileDialog-title-text-color) !important;
	font-family: var(--profileDialog-title-font-family) !important;
	font-style: normal;
	font-weight: var(--profileDialog-title-font-weight) !important;
	font-size: var(--profileDialog-title-font-size) !important;
}

.title {
	padding: 0 !important;
}

.content {
	padding-top: 32px;
	width: 100%;
}

.tooltip {
	z-index: 9999999999 !important;
}

.tooltipContent {
	padding: 16px !important;
}

.scriptTagWrapper {
	display: flex;
	margin-top: 4px;
	align-items: center;
	gap: 8px;
	position: relative;
}

.scriptTag,
.domainInput {
	padding: 8px 12px;
	border-radius: var(--item-radius-6);
	border: 1px solid var(--color-grey-300);
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
	color: var(--color-grey-900);
}

.scriptTag {
	width: 100%;
}

.domainInput {
	width: 100%;
	background: var(--color-white);
	height: 36px;
}

.scriptTagWrapper .copyButton,
.scriptTagWrapper .copyButton:hover {
	padding: 0;
	width: 28px;
	border-radius: var(--item-radius-8);
	background: var(--global-themeColor);
	color: var(--color-white);
	height: 28px;
	font-size: var(--global-base-fontSize);
	display: inline-flex;
	position: absolute;
	right: 7px;
	top: 6px;
	min-width: initial;
}

.tooltip {
	z-index: 9999999 !important;
}

.scriptTagWrapper .copyButton.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.domainsList {
	width: 100%;
	margin-top: 8px;
}

.domainsListItem {
	width: calc(100% - 44px);
	margin-bottom: 8px;
	display: flex;
	gap: 8px;
}

.domainsListItem:last-child {
	margin-bottom: 0;
}

.domainsListItem.isEdited {
	width: 100%;
}

.domainsListItem.disabledDomainsListItem {
	opacity: 0.5;
}

.domainsListItem .domainActionButton,
.domainsListItem .domainActionButton:hover {
	width: 36px;
	min-width: inherit;
	height: 36px;
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-300);
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04), 0 1px 2px 0 rgba(16, 24, 40, 0.04);
	color: var(--color-grey-800);
	background: transparent;
	flex-shrink: 0;
}

.disabledDomainsListItem .domainActionButton {
	cursor: not-allowed;
}

.domainsListItem .domainActionButton.applyButton {
	color: var(--color-dark-green);
}

.domainsListItem .domainActionButton.cancelButton {
	color: var(--color-dark-red);
}

.loader {
	position: absolute;
	z-index: 9;
	pointer-events: painted;
	background: #ffffff63;
}

@media screen and (max-width: 640px) {
	.title {
		padding: var(--profileDialog-title-padding-tablet) !important;
		text-align: center;
		box-sizing: border-box;
	}
}
