/* :: start of defining colors :: */
:root [theme='dark'] {
	--local-themeColor: #36a862;
	--local-scrollbar-width: 4px;
	--local-scrollbar-background: #646464;
	--local-scrollbar-thumb-background: #44474c;
	--local-scrollbar-thumb-hover-background: #373a3c;

	--local-onbording-background: #ffffff;
	--local-onbording-text-color: #000000;

	--local-primaryBackground: #232633;
	--local-invertedBackground: #ffff;
	--local-primaryTextColor: #ffffff;
	--local-invertedTextColor: #000000;
	--local-secondaryTextColor: #eeeeee;
	--local-annotation-background: #ffffff;
	--local-annotation-color: #111111;
	--local-overlay-background: #111111;
	--local-borderColor: rgba(255, 255, 255, 0.1);
	--local-borderColor2: #ffffff20;
	--local-amberColor: #e74036;
	--local-floRowItem-background: #232633;
	--local-action-background: rgba(255, 255, 255, 0.1);
	--local-info-text-color: #fff;
	--local-videoBorderColor: transparent;
	--local--groupedButtonBackground: rgba(255, 255, 255, 0.1);
	--local-flo-background: #171921;
	--local-profileDialog-input-background: #171921;
	--local-switch-track-background: #eeeeee;
	--local-videoPlayer-menu: rgba(238, 238, 238, 0.12);
	--local-videoPlayer-menu-content-background-color: var(--global-primaryBackground);
	--local-videoPlayer-menu-content-border-color: #171921;
	--local-videoPlayer-menu-button-popup-border: 1px solid transparent;
	--local-videoPlayer-menu-button-popup-background-color: #ffffff14;
	--local-list-wrapper-background: var(--local-flo-background);
	--local-comment-container-border: 1px solid rgba(255, 255, 255, 0.2);
	--local-manageFlo-rowItem_box-shadow: 0px 1px 2px 0px #1018280f;
	--local-primaryHeader-conatiner-border-color: #ffffff1a;
	--local-sendMessageContainer-box-shadow-color: rgba(16, 24, 40, 0.71);
	--local-decision-svg-fill: rgba(255, 255, 255, 0.3);
	--local-actionCheck-svg-fill: rgba(255, 255, 255, 0.3);
	--local-decision-icon-color: #f76157;
	--local-notification-border: var(--local-borderColor);
	--local-notification-box-shadow: 0 20px 24px rgba(16, 24, 40, 0.71);
	--local-emailInput-border: 1px solid rgba(255, 255, 255, 0.2);
	--local-message-border-color: #393c47;
	--local-button-border-color: var(--global-themeColor);
	--local-signin-image-spotlight-color: #242633;
	--local-signin-bottom-shadow-color: #1d1f2c;
	--local-loader-background: rgb(0 0 0 / 50%);
	--local-chip-overdue-text-color: #b0b1b5;
	--local-chip-overdue-background: rgb(176, 177, 181, 0.1);
	--local-message-showHideReplies-color: #6a94ff;
	--local-rangeSlider-overlay-color: #3a3c48;
	--local-tool-button-background: rgba(255, 255, 255, 0.1);
	--local-tool-button-color: var(--local-primaryTextColor);
	--local-tool-active-button-background: #ffffffe6;
	--local-tool-active-button-color: #000000;

	--global-scrollbar-width: var(--local-scrollbar-width);
	--global-scrollbar-background: var(--local-scrollbar-background);
	--global-scrollbar-thumb-background: var(--local-scrollbar-thumb-background);
	--global-scrollbar-thumb-hover-background: var(
		--local-scrollbar-thumb-hover-background
	);

	--global-themeColor: var(--local-themeColor);
	--global-primaryBackground: var(--local-primaryBackground);
	--global-invertedBackground: var(--local-invertedBackground);
	--global-primaryTextColor: var(--local-primaryTextColor);
	--global-invertedTextColor: var(--local-invertedTextColor);
	--global-secondaryTextColor: var(--local-secondaryTextColor);
	--global-overlay-background: var(--local-overlay-background);
	--global-floRowItem-background: var(--local-floRowItem-background);
	--global-borderColor: var(--local-borderColor);
	--global-amberColor: var(--local-amberColor);
	--global-action-background: var(--local-action-background);
	--global--groupedButton-background: var(--local--groupedButtonBackground);
	--global-toggleButtonColor: #ffffffb3;
	--global-toggleButtonSelectedBg: var(--global-primaryTextColor);
	--global-toggleButtonSelectedColor: #232633;
	--global-avatar-text-color: var(--global-primaryTextColor);
	--global-chipDefaultBackground: #6a94ff;
	--global-chipTextColor: #232633;
	--svgActiveColor: #fff;
	--global-flo-background: var(--local-flo-background);
	--global-container-background-color: var(--local-flo-background);
	--global-switch-track-background: var(--local-switch-track-background);
	--global-shareFloBody-linkContainer-background: rgba(255, 255, 255, 0.1);
	--globel-videoPlayer-control-play-icon-image: url('../../../public/images/play-light.svg');
	--globel-videoPlayer-control-pause-icon-image: url('../../../public/images/pause-light.svg');
	--global-videoPlayer-videoVolumeUrl: url('../../../public/images/volume-light.svg');
	--global-videoPlayer-videoLowVolumeUrl: url('../../../public/images/volume-low-light.svg');
	--global-videoPlayer-videoMuteVolumeUrl: url('../../../public/images/volume-mute-light.svg');
	--global-videoPlayer-videoFullScreenUrl: url('../../../public/images/maximize-light.svg');
	--global-videoPlayer-progress-control-holder-background: #ffffff1a;
	--global-login-background: #137866;
	--global-login-pageContent-background-color: #137866;
	--global-manageFlo-floRowItem-container-hover-background: #232633;
	--global-menuIcon-active-text-color: #36a862;
	--global-menuIcon-active-background: rgba(54, 168, 98, 0.1);
	--global-Page404-homeButton-color: #ffffff;
	--global-Page404-imageContainer-path-1-background: #1c1f2c;
	--global-Page404-imageContainer-path-2-3-4-background: #21242d;
	--global-Page404-contentText-color: var(--local-primaryTextColor);
	--global-Page500-homeButton-color: #ffffff;
	--global-Page500-imageContainer-path-1-background: #1c1f2c;
	--global-Page500-imageContainer-path-2-to-8-background: #21242d;
	--global-Page500-contentText-color: var(--local-primaryTextColor);
	--global-newFloPage-cardPermissionsTitle-opacity: 0.8;
	--global-newFloPage-cardPermissionsTitle-color: var(--local-primaryTextColor);
	--global-newFloPage-cardCountDown-color: var(--local-themeColor);
	--global-recordButton-color: var(--local-primaryTextColor);
	--global-newFloPage-card-border-color: rgba(255, 255, 255, 0.1);
	--global-newFloPage-optionSelect-background: #171921;
	--global-newFloPage-optionSelectActive-background: rgba(85, 145, 108, 0.1);
	--global-newFloPage-optionText-color: var(--global-primaryTextColor);
	--global-newFloPage-deviceIcon-background-color: #36a86220;
	--global-newFloPage-discardButton-color: #f76157;
	--global-newFloPage-recordButton-background-color: #ffffff10;
	--global-newFloPage-deviceSelectionDropdown-border-color: #ffffff20;
	--global-newFloPage-container-background: #171921;
	--global-newFloPage-clipboardSvg-stroke-color: #8a8b8a;
	--global-newFloPage-nameInput-border-color: #ffffff20;
	--global-newFloPage-optionSelect-svg-fill: #171921;
	--global-newFloPage-optionSelectActive-svg-fill: #293139;
	--global-dropdown-selected-background-color: #ffffff20;
	--global-message-borderBody-border-color: #393c47;
	--global-button-text-color: var(--global-primaryTextColor);
	--global-hover-background-color: #ffffff20;
	--global-loader-background: var(--global-primaryBackground);
	--global-sendMessage-placeholder-fill-color: #eaeaea;
	--global-proceesing-svg-background-color: #1c1f2c;
	--global-proceesing-svg-step-background-color: #232633;
	--global-proceesing-svg-item-background-color: #263238;
	--global-proceesing-svg-square-box-color: #4d5b62;
	--global-proceesing-svg-slider-gray-color: #757b7d;
	--global-proceesing-svg-shirt-color: #90b59e;
	--global-proceesing-svg-collar-color: var(--global-themeColor);
	--global-slider-hightlight-color: #fbb346;
	--global-shareFloBody-collaboratorUserName-color: var(--global-primaryTextColor);
	--global-onboardingBackground: var(--local-onbording-background);
	--global-onboardingBackground-text: var(--local-onbording-text-color);
}

/* :: end of defining colors :: */
:root [theme='dark'] {
}
