/*@import './fonts/styles.css';*/
@import url('https://zeus.floik.com/system/fonts/styles-icomoon.css');
/*@import url('https://zeus.floik.com/system/fonts/styles.css');*/
@import './css/base.css';

* {
	font-family: var(--global-font-family) !important;
	font-size: var(--global-base-fontSize);
}

/* width */
::-webkit-scrollbar {
	width: var(--global-scrollbar-width);
	height: var(--global-scrollbar-height);
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--global-scrollbar-background);
	box-shadow: inset 0 0 5px var(--global-scrollbar-background);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--global-scrollbar-thumb-background);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--global-scrollbar-thumb-hover-background);
}

.floik-container {
	font-family: var(--global-font-family);
	background: var(--global-primaryBackground);
	height: 100%;
	width: 100%;
	overflow: auto;
	margin: 0;
}

.floik-container .MuiButton-text {
	font-family: var(--global-font-family);
	text-transform: none;
}

.MuiButton-contained {
	color: var(--Button-color);
	font-family: var(--button-font-family);
	background: var(--Button-contained-background);
	box-shadow: unset;
	border-radius: var(--button-border-radius);
	border: var(--button-border);
	padding: var(--Button-padding);
}

.MuiToggleButtonGroup-root {
	background: var(--button-background);
	border: var(--button-border);
	border-radius: var(--button-border-radius) !important;
	text-transform: var(--global-text-transform) !important;
}

.MuiToggleButton-root.MuiToggleButton-primary {
	font-family: var(--toggleButton-primary-font-family);
	color: var(--toggleButton-primary-text-color);
	padding: var(--toggleButton-primary-padding);
	font-size: var(--toggleButton-primary-font-size);
	font-weight: var(--toggleButton-primary-font-weight);
	border-top: var(--toggleButton-primary-border-top);
	border-bottom: var(--toggleButton-primary-border-bottom);
	text-transform: var(--global-text-transform) !important;
}

.MuiToggleButton-root.Mui-selected.MuiToggleButton-primary {
	background: var(--toggleButton-primary-selected-background);
	color: var(--toggleButton-primary-selected-color);
	border-radius: var(--toggleButton-primary-selected-border-radius) !important;
}

.MuiToggleButton-root.Mui-selected.MuiToggleButton-primary:hover {
	background: var(--toggleButton-primary-selected-hover-background);
	opacity: var(--toggleButton-primary-selected-hover-opacity);
}

.MuiChip-filled.MuiChip-filledDefault {
	background: var(--chip-filled-background);
	color: var(--chip-filled-color);
	font-weight: var(--chip-filled-font-weight);
}

.Mui-selected.MuiToggleButtonGroup-grouped {
	border-radius: var(--toggleButtongroup-border-radius) !important;
}

.MuiButtonBase-root {
	text-transform: var(--global-text-transform) !important;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight,
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop,
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementLeft,
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
	background: #171921;
	font-weight: 500;
	font-size: var(--global-base-fontSize);
	line-height: 20px;
	pointer-events: none;
	user-select: none;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow .MuiTooltip-arrow {
	color: #171921;
	pointer-events: none;
	user-select: none;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px var(--global-primaryBackground) inset;
	-webkit-text-fill-color: var(--global-primaryTextColor);
	caret-color: var(--global-primaryTextColor);
}

.lf-player-container rect {
	fill: var(--brand-color-click-animation-color);
}

.instruction-editor .public-DraftEditor-content {
	white-space: nowrap !important;
}

.public-DraftEditor-content {
	height: 28px;
	overflow: hidden;
	overflow-x: auto;
	/*padding-bottom: 32px; !* hide the scrollbar *!*/
}

.public-DraftEditorPlaceholder-root {
	color: #fff !important;
	opacity: 0.5 !important;
}

.cta-description-editor .public-DraftEditor-content,
.instruction-description-editor .public-DraftEditor-content {
	height: auto;
}

.cta-description-editor ul,
.cta-description-editor ul li,
.instruction-description-editor ul,
.instruction-description-editor ul li {
	list-style: disc;
	/* padding: revert; */
}

.cta-description-editor ol,
.cta-description-editor ol li,
.instruction-description-editor ol,
.instruction-description-editor ol li {
	/* list-style: auto; */
	/* padding-left: revert; */
}

.cta-description-editor ul,
.cta-description-editor ol,
.instruction-description-editor ul,
.instruction-description-editor ol {
	padding-left: 8px;
}

.cta-description-editor a {
	color: var(--color-primary-500);
}

.cta-description-editor .DraftEditor-root {
	line-height: 18px;
}

.cta-description-editor .public-DraftEditorPlaceholder-inner {
	color: var(--color-grey-900);
}

/* width */
.public-DraftEditor-content::-webkit-scrollbar {
	width: var(--global-scrollbar-width);
	height: var(--global-scrollbar-height);
}

/* Track */
.public-DraftEditor-content::-webkit-scrollbar-track {
	background: transparent;
	box-shadow: unset;
	border-radius: 10px;
}

/* Handle */
.public-DraftEditor-content::-webkit-scrollbar-thumb {
	background: transparent;
	border-radius: 10px;
}

/* Handle on hover */
.public-DraftEditor-content::-webkit-scrollbar-thumb:hover {
	background: var(--global-scrollbar-thumb-hover-background);
}

.public-DraftStyleDefault-block > span {
	white-space: pre;
}

div.DraftEditor-root {
	/*border: 1px solid #000;*/
	/*background-color: beige;*/

	height: 100%;
	width: 100%;
	overflow-y: auto;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
	height: 100%;
}

.public-DraftEditor-content {
	overflow: auto;
}

.instruction-description-editor .public-DraftStyleDefault-block > span {
	/*white-space: normal;*/
	color: var(--global-primaryTextColor);
	white-space: break-spaces;
}

.public-DraftEditorPlaceholder-root {
	position: absolute;
	pointer-events: none;
	opacity: 0.8;
}

.instruction-description-editor
	.public-DraftEditor-content
	.public-DraftStyleDefault-block {
	/* Override default of 'pre-wrap' so that whitespace characters wrap onto new lines as expected. */
	white-space: break-spaces;
}

.smallDesktopBanner,
.mobileBanner {
	display: none;
}

.title3 {
	padding-left: 8px;
	color: var(--global-primaryTextColor);
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (max-width: 766px) or (max-height: 576px) {
	.mobileBanner {
		display: flex;
		position: fixed;
		flex-direction: column;
		height: 100vh;
		width: 100vw;
		top: 0;
		left: 0;
		z-index: 99999999;
		background: white;
		justify-content: center;
		align-items: center;
		font-size: 2rem;
		font-weight: 500;
		color: var(--color-dark-dark-grey);
		text-align: center;
		padding: 8px;
		line-height: 1.4;
	}
	.mobileBanner p {
		font-size: 2rem;
		font-weight: 500;
		line-height: 1.4;
	}
}

blockquote {
	margin: 0;
}

code {
	color: var(--primaryTextColor);
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
	padding: 8px 0;
}

.hotspot-description-editor .public-DraftEditor-content > div:first-child {
	padding-bottom: 24px;
}

div.diamondDialogContentWrapper {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-height: calc(100vh - 128px);
}

.diamondDialogContent {
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
}
