.dialogRoot {
	z-index: var(--newFloPage-dialogRoot-z-index) !important;
}

.dialogWrapper {
	max-width: 100% !important;
}

.dialogContainer {
	background: var(--newFloPage-container-background);
}

.saveGridContainer {
	height: 100%;
	justify-content: space-around;
	flex-wrap: nowrap;
}

.dialogContainer .dialogWrapper {
	transition: all 5s;
	border: unset;
	border-radius: unset;
	align-items: center;
	box-shadow: none;
	background: transparent;
}

.dalogSaveContianer .dialogWrapper {
	/*overflow: hidden !important;*/
	transition: all 5s;
	border: unset;
	border-radius: unset;
	box-shadow: var(--newFloPage-card-box-shadow);
	width: var(--newFloPage-save-dialogWrapper-width);
	background: transparent;
}

.container {
	background-color: var(--newFloPage-container-background);
	height: var(--newFloPage-container-height);
	position: absolute;
	top: var(--newFloPage-container-top);
	left: var(--newFloPage-container-left);
	width: var(--newFloPage-container-width);
}

.processingSubTitle .progressBar,
.progressBarRoot .progressBarColorPrimary {
	background-color: var(--newFloPage-linearProgress-color);
}

.container.recordStopped {
	display: none;
}

.dialogContainer .card,
.card {
	box-sizing: border-box;
	/*min-width: var(--newFloPage-card-min-width);*/
	background: var(--newFloPage-card-background) !important;
	border: var(--newFloPage-card-border);
	box-shadow: var(--newFloPage-card-box-shadow);
	border-radius: var(--newFloPage-card-border-radius);
	/*padding: var(--newFloPage-card-padding);*/
	overflow: auto !important;
	width: fit-content;
	height: fit-content;
}

.dialogContainer .card.templateDetails {
	max-width: 90vw;
}

.templateDetails.hideDeviceSelectionPopup {
	border: 0;
}

.savingContainer {
	width: min-content !important;
	padding: 40px 20px;
}

.cardClose {
	position: absolute !important;
	right: 8px;
	top: 8px;
	color: var(--newFloPage-cardClose-color) !important;
}

.cardClose svg {
	width: 20px;
	height: 20px;
}

.cardTitle {
	position: sticky;
	width: 100%;
	font-style: var(--newFloPage-cardTitle-font-style);
	font-weight: var(--newFloPage-cardTitle-font-weight);
	font-size: var(--newFloPage-cardTitle-font-size);
	line-height: var(--newFloPage-cardTitle-line-height);
	top: var(--newFloPage-cardTitle-top);
	/* height: var(--newFloPage-cardTitle-height); */
	color: var(--newFloPage-cardTitle-color);
	padding: var(--newFloPage-cardTitle-padding);
	text-transform: var(--newFloPage-cardTitle-text-transform);
}

.toolTitle {
	position: sticky;
	display: flex;
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	top: var(--newFloPage-cardTitle-top);
	text-transform: var(--newFloPage-cardTitle-text-transform);
	color: var(--global-primaryTextColor);
}

.toolsContainer {
	display: flex;
	flex-direction: row;
}

.toolsContainer .toolButton:hover,
.toolsContainer .toolButton {
	min-width: unset;
	width: 36px;
	height: 36px;
	padding: 0;
	margin: 0 12px 0 0;
	color: var(--newFloPage-tool-button-color);
	background-color: var(--newFloPage-tool-button-background);
}

.toolsContainer .toolButton:hover {
	opacity: 0.8;
}

.toolsContainer .toolButton.active {
	color: var(--newFloPage-tool-active-button-color);
	background-color: var(--newFloPage-tool-active-button-background);
}

.divider {
	border-color: var(--global-borderColor);
	margin: 9px 0 !important;
}

.saveViewTitle {
	text-align: initial;
}

.cardSubTitle {
	font-style: var(--newFloPage-cardSubTitle-font-style);
	font-weight: var(--newFloPage-cardSubTitle-font-weight);
	font-size: var(--newFloPage-cardSubTitle-font-size);
	line-height: var(--newFloPage-cardSubTitle-line-height);
	color: var(--newFloPage-cardSubTitle-color);
	padding: var(--newFloPage-cardSubTitle-padding);
	opacity: var(--newFloPage-cardSubTitle-opacity);
}

.dialogWrapper .countDownContainer {
	height: var(--newFloPage-cardCountDown-height);
	/*width: 100%;*/
	z-index: var(--newFloPage-cardCountDown-z-index);
	display: flex;
	flex-direction: column;
	justify-content: var(--newFloPage-cardCountDown-justify-content);
	align-items: var(--newFloPage-cardCountDown-align-items);
}

.dialogWrapper .cardCountDown {
	font-weight: var(--newFloPage-cardCountDown-font-weight);
	font-size: var(--newFloPage-cardCountDown-font-size);
	color: var(--newFloPage-cardCountDown-color);
	text-align: var(--newFloPage-cardCountDown-text-align);
	line-height: var(--newFloPage-cardCountDown-line-height);
}
.cardCountDownSubTitle {
	color: var(--global-primaryTextColor);
	opacity: var(--newFloPage-cardCountDownSubTitle-opacity);
	font-weight: var(--newFloPage-cardCountDownSubTitle-font-weight);
	font-size: var(--newFloPage-cardCountDownSubTitle-font-size);
	line-height: var(--newFloPage-cardCountDownSubTitle-line-height);
	padding-bottom: var(--newFloPage-cardCountDownSubTitle-padding-bottom);
}

.cardCountDownTitle {
	color: var(--global-primaryTextColor);
	font-weight: var(--newFloPage-cardCountDownTitle-font-weight);
	font-size: var(--newFloPage-cardCountDownTitle-font-size);
	line-height: var(--newFloPage-cardCountDownTitle-line-height);
}

.cardRecordingStart {
	font-weight: var(--newFloPage-cardRecordingStart-font-weight);
	font-size: var(--newFloPage-cardRecordingStart-font-size);
	color: var(--newFloPage-cardRecordingStart-color);
	text-align: var(--newFloPage-cardRecordingStart-text-align);
	height: var(--newFloPage-cardRecordingStart-height);
	display: flex;
	justify-content: var(--newFloPage-cardRecordingStart-justify-content);
	align-items: var(--newFloPage-cardRecordingStart-align-items);
	position: absolute;
	width: 100%;
	z-index: var(--newFloPage-cardRecordingStart-z-index);
}

.cardBodyWrapper {
}

.cardBody {
	display: grid;
	grid-template-rows: var(--newFloPage-cardBody-grid-template-rows);
	grid-template-columns: var(--newFloPage-cardBody-grid-template-columns);
	grid-gap: var(--newFloPage-cardBody-grid-gap);
	padding: var(--newFloPage-cardBody-padding);
	height: var(--newFloPage-cardBody-height);
	/*overflow: hidden;*/
}

.cardRecordingBody {
	min-width: 400px;
	width: 100%;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	font-size: var(--newFloPage-cardRecordingBody-font-size);
	font-weight: var(--newFloPage-cardRecordingBody-font-weight);
	/*padding: var(--newFloPage-cardRecordingBody-padding);*/
	color: var(--newFloPage-cardRecordingBody-color);
	text-align: var(--newFloPage-cardRecordingBody-text-align);
}

.cardRecordingBody.elapsed {
	flex-flow: column;
	justify-content: space-evenly;
	padding: var(--newFloPage-cardRecordingBody-elapsed-padding);
	min-width: auto;
}

.recordingView {
	padding: 0;
	/*width: 70vw !important;*/
	height: auto;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
}

.cardRecordingBody.waiting {
	min-width: auto;
	padding: 28px 0;
}

.cardRecordingBody .buttonsWrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.restartButtonIcon {
	width: 14px;
	height: 14px;
}

.discardButton,
.discardButton:hover,
.discardButton:disabled {
	color: var(--color-red-white) !important;
}

.restartButton,
.restartButton:hover,
.restartButton:disabled {
	color: var(--global-themeColor) !important;
}

.restartButton:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

.restartButtonText {
	padding-left: 4px;
	font-size: var(--global-base-fontSize);
	font-weight: 500;
}

.cardRecordingText {
	font-style: var(--newFloPage-cardRecordingText-font-style);
	font-weight: var(--newFloPage-cardRecordingText-font-weight);
	font-size: var(--newFloPage-cardRecordingText-font-size);
	line-height: var(--newFloPage-cardRecordingText-line-height);
	color: var(--newFloPage-cardRecordingText-color);
}

.processing {
	font-weight: var(--newFloPage-processing-font-weight);
	font-size: var(--newFloPage-processing-font-size);
	text-align: var(--newFloPage-processing-text-align);
	color: var(--newFloPage-processingSubTitle-color);
	line-height: var(--newFloPage-processing-line-height);
	padding-bottom: 24px;
}

.processingSubTitle {
	font-weight: var(--newFloPage-processingSubTitle-font-weight);
	font-size: var(--newFloPage-processingSubTitle-font-size);
	text-align: var(--newFloPage-processingSubTitle-text-align);
	line-height: var(--newFloPage-processingSubTitle-line-height);
	color: var(--newFloPage-processingSubTitle-color);
}

.processingSubTitle * {
	opacity: var(--newFloPage-processingSubTitle-opacity);
}

.uploadSpeed {
	padding-top: 2px;
	font-size: var(--global-mini-font);
	opacity: 0.7;
}

.processingSubTitle .uploadProgress {
	font-size: var(--global-mini-font);
	opacity: 0.7;
}

.processingSubTitle .stats {
	opacity: 1;
	margin-top: 16px;
}

.processingSubTitle .stats * {
	opacity: 1;
}

.processingSubTitle .dashed {
	left: 0;
	background-image: radial-gradient(
		var(--newFloPage-linearProgress-color) 0%,
		var(--newFloPage-linearProgress-color) 16%,
		transparent 42%
	);
}

.loadingIconContainer {
	padding: var(--newFloPage-loadingIconContainer-padding);
}

.timeElapsed {
	padding: var(--newFloPage-timeElapsed-padding);
	font-size: var(--newFloPage-timeElapsed-font-size);
	font-weight: var(--newFloPage-timeElapsed-font-weight);
	color: var(--newFloPage-timeElapsed-color);
	text-align: var(--newFloPage-timeElapsed-text-align);
	line-height: var(--newFloPage-timeElapsed-line-height);
}

.buttonsWrapper .recordButton,
.buttonsWrapper .recordButton:hover {
	margin-right: var(--newFloPage-recordButton-margin-right);
	background-color: var(--newFloPage-recordButton-background-color);
	color: var(--newFloPage-recordButton-color);
	font-style: var(--newFloPage-recordButton-font-style);
	font-size: var(--newFloPage-recordButton-font-size);
	line-height: var(--newFloPage-recordButton-line-height);
	text-transform: var(--newFloPage-recordButton-text-transform);
	font-weight: var(--newFloPage-recordButton-font-weight);
	border-radius: var(--newFloPage-recordButton-border-radius);
	border: var(--newFloPage-recordButton-border) !important;
	width: var(--newFloPage-recordButton-width);
}

.buttonsWrapper .recordButton.disabled {
	opacity: var(--newFloPage-recordButtonDisabled-opacity);
	color: var(--newFloPage-recordButton-color);
}

.buttonsWrapper .stopButton,
.buttonsWrapper .stopButton:hover {
	font-style: var(--newFloPage-stopButton-font-style);
	border: var(--newFloPage-stopButton-border) !important;
	width: var(--newFloPage-stopButton-width);
	background-color: var(--newFloPage-stopButton-background-color);
	color: var(--newFloPage-stopButton-color);
	text-transform: var(--newFloPage-stopButton-text-transform);
	font-weight: var(--newFloPage-stopButton-font-weight);
	border-radius: var(--newFloPage-stopButton-border-radius);
	font-size: var(--newFloPage-stopButton-font-size);
}

.buttonsWrapper .stopButton.disabled {
	opacity: var(--newFloPage-stopButtonDisabled-opacity);
	color: var(--newFloPage-stopButton-color);
}

.buttonIcon {
	padding-right: var(--newFloPage-buttonIcon-padding-right);
}

.buttonIcon path {
	color: var(--newFloPage-buttonIcon-text-color);
	fill: var(--newFloPage-buttonIconPath-fill-color);
}

.buttonIconContainer {
	display: flex;
	align-items: center;
	font-weight: var(--newFloPage-buttonIcon-font-weight);
}

.recordingVideoPreview {
	border-radius: 8px;
	height: fit-content;
	width: 100%;
	margin-bottom: 16px;
	max-width: 300px;
}

.selectedDevice {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background: var(--color-light-grey);
	margin-bottom: 16px;
	border-radius: 8px;
	padding: 8px 12px;
}

.selectedDeviceIcon {
	height: 16px;
	color: var(--global-primaryTextColor);
}

.deviceName {
	width: max-content;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 8px;
}

.cardRecording {
	display: inline-flex;
	background: rgba(231, 64, 54, 0.1);
	border-radius: 8px;
	padding: 8px 16px;
	margin-bottom: 32px;
	justify-content: center;
	align-items: center;
}

.recordingIcon {
	height: 20px;
	width: 20px;
}

.recordingText {
	color: #e74036;
	padding-left: 8px;
	font-weight: 600;
	font-size: 16px;
}

.cardRecording.paused {
	background: rgba(35, 38, 51, 0.1);
}

.recordingText.paused {
	color: #949494;
}

.recordingScreenLeftSection {
	overflow: auto;
	/*max-width: 350px !important;*/
	height: 100%;
}

.recordingScreenRightWrapper,
.recordingScreenLeftWrapper {
	padding: 32px 48px;
	overflow: auto;
	height: 100%;
	white-space: nowrap;
}

.recordingScreenRightWrapper {
	padding: 32px 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: auto;
}

.recordingScreenRightSection {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	background: #fafafa;
	height: 100%;
	max-width: 100%;
	flex-grow: 1;
}

.shrink {
	min-width: 358px !important;
	max-width: 360px !important;
}

.clicksCaptured {
	font-size: 22px;
	line-height: 26px;
	text-align: center;
	color: var(--global-secondaryTextColor);
	margin-bottom: 20px;
}

.clicksContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	min-width: 160px;
	min-height: 100px;
	left: 860px;
	top: 401px;
	font-size: 48px;
	font-weight: 600;
	background: rgba(38, 96, 244, 0.1);
	border-radius: 24px;
	color: var(--color-dark-primary);
}

.recordingContentWrapper {
	background: var(--global-primaryTextColor);
	height: 84px;
	width: 100%;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	margin-bottom: 16px;
}

.recordingContentType {
	background: #fbb346;
	border-radius: 8px 0;
	display: inline-block;
	padding: 2px 10px;
	color: var(--global-primaryTextColor);
	font-weight: 600;
}

.recordingContentTabContainer {
	align-self: flex-start;
	margin: auto 0;
	padding: 16px;
}

.recordingContentTabIcon {
}

.recordingContentTabTitle {
	/*padding-left: 12px;*/
	font-size: var(--global-base1-fontSize);
	line-height: 20px;
	color: #ffffff;
	overflow: hidden;
}

.optionSelect {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: var(--newFloPage-optionSelect-width);
	height: var(--newFloPage-optionSelect-height);
	background: var(--newFloPage-optionSelect-background);
	border-radius: var(--newFloPage-optionSelect-border-radius);
	flex-flow: var(--newFloPage-optionSelect-flex-flow);
	padding: var(--newFloPage-optionSelect-padding);
	border: var(--newFloPage-optionSelect-border);
}

.optionSelect.active,
.optionSelect:hover {
	border: var(--newFloPage-optionSelectActive-border);
	background: var(--newFloPage-optionSelectActive-background);
}

.optionSelect.active path,
.optionSelect:hover path,
.optionSelect.active rect,
.optionSelect:hover rect,
.optionSelect.active .optionText,
.optionSelect:hover .optionText {
	stroke: var(--newFloPage-optionSelectActive-svg-stroke);
	color: var(--newFloPage-optionTextActive-color);
	opacity: var(--newFloPage-optionTextActive-opacity);
	font-weight: var(--newFloPage-optionTextActive-font-weight);
}

.optionSelect.disabled path,
.optionSelect.disabled:hover path,
.optionSelect.disabled rect,
.optionSelect.disabled:hover rect,
.optionSelect.disabled .optionText,
.optionSelect.disabled:hover .optionText {
	stroke: var(--newFloPage-optionSelect-svg-stroke);
	color: var(--newFloPage-optionText-color);
	opacity: var(--newFloPage-optionTextActive-opacity);
	font-weight: var(--newFloPage-optionText-font-weight);
}

.optionSelect path {
	stroke: var(--newFloPage-optionSelect-svg-stroke);
}
.optionSelect rect {
	fill: var(--newFloPage-optionSelect-svg-fill);
}

.optionSelect:hover rect,
.optionSelect.active rect {
	fill: var(--newFloPage-optionSelectActive-svg-fill);
}

.optionSelect.screenCam > svg > rect:nth-child(4) {
	stroke: var(--newFloPage-optionSelect-svg-fill);
}

.optionSelect.screenCam:hover > svg > rect:nth-child(4),
.optionSelect.screenCam.active > svg > rect:nth-child(4) {
	stroke: var(--newFloPage-optionSelectActive-svg-fill);
}

.optionSelect.screenCam > svg > rect:nth-child(5) {
	stroke: var(--newFloPage-optionSelect-svg-stroke);
}

.optionSelect.screenCam:hover > svg > rect:nth-child(5),
.optionSelect.screenCam.active > svg > rect:nth-child(5) {
	stroke: var(--newFloPage-optionSelectActive-svg-stroke);
}

.optionSelect.disabled,
.optionSelect.disabled:hover {
	border: unset;
	opacity: var(--newFloPage-optionSelectDisablede-opacity);
	user-select: none;
	cursor: no-drop;
}

.optionSelect:hover {
}

.image {
	height: var(--newFloPage-image-height);
	width: var(--newFloPage-image-width);
}

.optionText {
	font-style: var(--newFloPage-optionText-font-style);
	font-weight: var(--newFloPage-optionText-font-weight);
	font-size: var(--newFloPage-optionText-font-size);
	line-height: var(--newFloPage-optionText-line-height);
	opacity: var(--newFloPage-optionText-opacity);
	color: var(--newFloPage-optionText-color);
	padding-top: var(--newFloPage-optionText-padding-top);
}

.cardPermissionsTitle {
	font-style: var(--newFloPage-cardPermissionsTitle-font-style);
	font-weight: var(--newFloPage-cardPermissionsTitle-font-weight);
	font-size: var(--newFloPage-cardPermissionsTitle-font-size);
	line-height: var(--newFloPage-cardPermissionsTitle-line-height);
	opacity: var(--newFloPage-cardPermissionsTitle-opacity);
	color: var(--newFloPage-cardPermissionsTitle-color);
	padding: var(--newFloPage-cardPermissionsTitle-padding);
}

.cardFooter {
	flex-flow: var(--newFloPage-footer-flex-flow);
	gap: var(--newFloPage-footer-gap);
}

.cardFooter .startButton,
.cardFooter .startButton.disabled {
	text-transform: inherit;
	width: 100%;
	background: var(--newFloPage-footer-startButton-background);
	height: var(--newFloPage-footer-startButton-height);
	font-weight: var(--newFloPage-footer-startButton-font-weight);
	font-size: var(--newFloPage-footer-startButton-font-size);
	line-height: var(--newFloPage-footer-startButton-line-height);
	border-radius: var(--newFloPage-footer-startButton-border-radius);
	margin-top: var(--newFloPage-footer-startButton-margin-top);
	color: var(--newFloPage-footer-startButton-color);
}

.cardFooter .startButton:hover {
	background: var(--newFloPage-footer-startButton-hover-background);
}

.cardFooter .startButton.disabled {
	opacity: var(--newFloPage-footer-startButton-disabled-opacity);
}

.cardFooter .nameInput {
	width: 100%;
	height: var(--newFloPage-nameInput-height);
	margin: var(--newFloPage-nameInput-margin);
}

.cardFooter .nameInput input {
	color: var(--newFloPage-nameInput-color);
}

.cardFooter .nameInput label {
	color: var(--newFloPage-nameInputLabel-color);
	opacity: var(--newFloPage-nameInputLabel-opacity);
}

.cardFooter .nameInput fieldset {
	border: var(--newFloPage-nameInput-border) !important;
}

.buttonsContainer {
	display: flex;
	flex-flow: var(--newFloPage-buttonsContainer-flex-flow);
	align-items: flex-start;
}

.buttonsContainer .saveButton,
.buttonsContainer .saveButton.disabled {
	background-color: var(--newFloPage-footer-saveButton-background) !important;
	color: var(--newFloPage-footer-saveButton-color);
	text-transform: inherit;
	height: var(--newFloPage-saveButton-height);
	font-weight: var(--newFloPage-saveButton-font-weight);
	font-size: var(--newFloPage-saveButton-font-size);
	line-height: var(--newFloPage-saveButton-line-height);
	width: 100%;
	display: block;
}

.buttonsContainer .saveButton.disabled {
	opacity: var(--profileDialog-saveButtonDisabled-opacity);
}

.buttonsContainer .saveButton:hover {
	background: var(--newFloPage-saveButton-hover-background);
}

.buttonsContainer .discardButton {
	padding: var(--newFloPage-discardButton-padding) !important;
	color: var(--newFloPage-discardButton-color) !important;
	font-weight: var(--newFloPage-discardButton-font-weight);
	font-size: var(--newFloPage-discardButton-font-size);
	line-height: var(--newFloPage-discardButton-line-height);
	text-transform: var(--newFloPage-discardButton-text-transform);
}

.buttonsContainer .discardButton svg {
	margin-right: var(--newFloPage-discardButtonSvg-margin-right);
}

.buttonsContainer .discardButton svg path {
	stroke: var(--newFloPage-discardButton-color);
}

.clipboard {
	margin-bottom: var(--newFloPage-clipboard-margin-bottom) !important;
}

.clipboard svg > g > g:nth-child(1) > g:nth-child(1) path {
	stroke: var(--newFloPage-clipboardSvg-stroke-color);
}

.clipboard svg > g > g:nth-child(1) > g:nth-child(2) path,
.clipboard svg > g > g:nth-child(2) > g path {
	stroke: var(--newFloPage-clipboardSvg-stroke-color2);
}

.pausedIcon {
	margin: var(--newFloPage-pausedIcon-margin) !important;
	color: var(--newFloPage-pausedIcon-color) !important;
	animation: fadeOut 3s infinite;
	animation-delay: 1s;
}

@keyframes fadeOut {
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.pausedIcon svg > g > g:nth-child(4) > g > path,
.pausedIcon svg > g > g:nth-child(5) > g > path {
	stroke: var(--newFloPage-clipboardSvg-stroke-color2);
}

.pausedIcon svg > g > g:nth-child(1) > g > path {
	fill: var(--newFloPage-card-background) !important;
}

.pausedIcon svg > g > g:nth-child(3) > g > path {
	stroke: var(--newFloPage-clipboardSvg-stroke-color);
}

.errorMessage {
	color: var(--newFloPage-errorMessage-color) !important;
	margin: var(--newFloPage-errorMessage-margin);
}

.discardButton:hover {
	background: var(--newFloPage-discardButton-hover-background) !important;
}

.saving {
	padding: var(--newFloPage-saving-padding);
	display: inline-flex;
	width: 100%;
}

.videoContainer {
	height: 100% !important;
}

@media screen and (max-width: 990px) {
	.videoContainer {
		height: calc(100% + 10px) !important;
	}
}

.clapContainer {
	margin: var(--newFloPage-clapContainer-margin);
}

.hide {
	display: none !important;
}

.stopIcon {
	color: var(--newFloPage-stopIcon-color);
}

.videoPlayback {
	height: var(--newFloPage-videoPlayback-height);
	width: var(--newFloPage-videoPlayback-width);
	border-radius: var(--newFloPage-videoPlayback-border-radius);
	position: absolute;
	bottom: var(--newFloPage-videoPlayback-bottom);
	left: var(--newFloPage-videoPlayback-left);
	z-index: var(--newFloPage-videoPlayback-z-index);
}

.deviceSelectorRow {
	display: flex;
	align-items: center;
	margin-bottom: var(--newFloPage-deviceSelectorRow-margin-bottom);
	justify-content: center;
	width: 100%;
}

.disabledDropdown {
	opacity: 0.8;
	pointer-events: none;
}

.deviceIcon path,
.deviceIcon line,
.deviceIcon rect,
.selectedDeviceIcon path,
.selectedDeviceIcon line,
.selectedDeviceIcon rect {
	stroke: var(--newFloPage-deviceIcon-path-stroke);
}

.deviceIconWrapper.disabled .deviceIcon path,
.deviceIconWrapper.disabled .deviceIcon line,
.deviceIconWrapper.disabled .deviceIcon rect,
.selectedDevice.disabled .selectedDeviceIcon path,
.selectedDevice.disabled .selectedDeviceIcon line,
.selectedDevice.disabled .selectedDeviceIcon rect {
	stroke: var(--newFloPage-deviceIcon-disabled-path-stroke);
}

.deviceIcon {
	position: relative;
	height: var(--newFloPage-deviceIcon-height) !important;
	width: var(--newFloPage-deviceIcon-width) !important;
	padding: var(--newFloPage-deviceIcon-padding);
	border-radius: var(--newFloPage-deviceIcon-border-radius);
	flex-shrink: 0;
}

.deviceIconWrapper {
	position: relative;
	height: var(--newFloPage-deviceIcon-height) !important;
	width: var(--newFloPage-deviceIcon-width) !important;
	min-width: initial !important;
	padding: 0 !important;
}

.deviceIconWrapper:hover {
	background: transparent !important;
}

.deviceIconWrapper:before {
	position: absolute;
	content: '';
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;
	border-radius: var(--newFloPage-deviceIcon-border-radius);
	background-color: var(--newFloPage-deviceIcon-background);
	opacity: 0.1;
}

.deviceIconWrapper.disabled:before {
	background-color: var(--newFloPage-deviceIcon-disabled-background);
}

.deviceSelection {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 24px 0 8px 0;
	width: 100%;
}

.deviceTitleWrapper {
	display: flex;
	justify-content: flex-start;
	/*padding: 0 0 0 32px;*/
	width: 100%;
}

.deniedPermissionContainer {
	width: min-content;
}

.deniedIcon {
}

.deniedPermissionTitleContainer {
	display: flex;
	align-items: center;
	padding: 0 0 16px 0;
}

.deniedPermissionTitle {
	padding-left: 8px;
}

.deniedPermissionImage {
	/*width: 260px;*/
	height: 300px;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	background-size: contain;
}

.deniedPermissionDescription {
	display: flex;
	padding: 16px 0;
	justify-content: flex-start;
	text-align: left;
	font-weight: 400;
	font-size: var(--global-base-fontSize);
	line-height: 18px;
	min-width: 300px;
}

.deviceSelectionDropdown {
	width: 100%;
	max-width: var(--newFloPage-deviceSelectionDropdown-width);
	min-width: var(--newFloPage-deviceSelectionDropdown-width) !important;
	margin: var(--newFloPage-deviceSelectionDropdown-margin) !important;
	border: var(--newFloPage-deviceSelectionDropdown-border) !important;
	border-radius: var(--newFloPage-deviceSelectionDropdown-border-radius);
}

.infinity svg > g g {
	transform: translate(34%, 33%) scale(0.6) !important;
}
.infinity svg path {
	stroke: var(--newFloPage-infinity-color);
}

.saveBodyContainer {
	display: flex;
	justify-content: space-between;
	padding: var(--newFloPage-card-padding);
	height: 95%;
}

.saveBodyTitle {
	padding-bottom: 24px;
}

.leftSaveGridContianer {
	height: 100%;
	max-width: 100% !important;
}

.rightSaveGridContianer {
	max-width: 250px !important;
	min-width: 150px !important;
	text-align: center;
}

.deviceIconWrapper.noEvents {
	cursor: default;
	pointer-events: none;
}

@media screen and (max-width: 640px) {
	.dialogContainer .card {
		/*padding: var(--newFloPage-card-padding-tablet);*/
		min-width: auto;
	}
	.cardRecordingBody {
		min-width: auto;
	}
	.cardBody {
		grid-template-columns: auto;
		grid-template-rows: auto;
		justify-content: center;
	}
	.cardTitle {
		position: static;
	}
	.cardSubTitle {
		text-align: center;
	}
	.cardClose {
		top: var(--newFloPage-cardClose-top-tablet);
		right: var(--newFloPage-cardClose-right-tablet);
		position: absolute !important;
	}
}

.hideVideo {
	display: none;
}

.networkSpeed {
	padding: 2px 0;
	color: var(--global-primaryTextColor);
}

.networkSpeed.fair {
	color: var(--global-primaryTextColor);
}

.networkSpeed.weak {
	color: var(--color-orange);
}

.networkSpeed.vweak {
	color: var(--color-dark-red);
}

.dialogRootMessage {
	z-index: 999999999 !important;
}
