.switchContainer {
	display: flex;
	padding-bottom: var(--switch-padding-bottom);
}

.switchContainer.disabled {
	cursor: not-allowed;
}

.switchContainer:last-child {
	padding-bottom: 0;
}

.switchContainer .switchElement {
	padding: var(--switch-element-padding);
	width: var(--switch-element-width);
	height: max-content;
}

.switchContainer .switchLabel {
	padding-top: var(--switch-label-padding-top);
	color: var(--switch-label-color);
	font-family: var(--switch-label-font-family);
	font-weight: var(--switch-label-font-weight);
	font-size: var(--switch-label-font-size);
	line-height: var(--switch-label-line-height);
	margin: var(--switch-label-margin);
	display: flex;
	gap: 8px;
}

.switchContainer .switchBase {
	top: var(--switch-base-top);
	left: var(--switch-base-left);
}

.switchContainer .switchBase:hover {
	background-color: var(--switch-base-hover-background);
}

.switchContainer .switchBase.switchChecked {
	color: var(--switch-checked-text-color);
	left: var(--switch-checked-left);
}

.switchContainer .switchTrack {
	background: var(--switch-track-background);
	height: var(--switch-track-height);
	width: var(--switch-track-width);
	border-radius: 16px;
}

.switchContainer .switchBase.switchChecked + .switchTrack {
	background-color: var(--switch-track-check-background);
	opacity: var(--switch-track-check-opacity);
	border-radius: 16px;
}

.switchContainer .switchThumb {
	width: var(--switch-thumb-width);
	height: var(--switch-thumb-height);
}

.switchContainer .icon {
	margin-right: var(--switch-icon-margin-right);
	margin-top: var(--switch-icon-margin-top);
}

.switchContainer.disabled .switchBase {
	cursor: not-allowed;
	color: var(--local-switch-base) !important;
}

.switchContainer.disabled .switchBase + .switchTrack {
	background: #f6f5f6 !important;
	opacity: unset;
}

.switchContainer.disabled .switchBase.switchChecked + .switchTrack {
	background: var(--local-switch-track-bg-disabled) !important;
	opacity: unset;
}
