.profileSection {
	align-items: center;
}

.avatar {
	border-radius: var(--avatar-border-radius);
	/*background: #2C302E;*/
	color: var(--avatar-text-color);
	height: var(--avatar-height);
	width: var(--avatar-width);
	overflow: hidden;
	z-index: 1;
	flex-shrink: 0;
}

.count {
	position: absolute;
}

.circular {
	border-radius: var(--avatar-circular-border-radius) !important;
}

.small {
	height: var(--avatar-small-height);
	width: var(--avatar-small-width);
}

.profilePicContainer {
	background-color: var(--avatar-background);
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: auto;
}
