button.button,
button.button:hover {
	cursor: pointer;
	color: var(--Button-color);
	font-size: var(--global-base1-fontSize);
	font-weight: var(--global-title-font-weight);
	margin-bottom: var(--Button-margin-bottom);
	min-width: var(--Button-min-width);
	margin-right: 0;
	height: var(--Button-height);
	width: var(--Button-width);
	border-radius: 8px;
}

.button.root,
.button.root:hover {
}

.button.text,
.button.text:hover,
.button.text:disabled {
	color: var(--color-black);
	width: auto;
}

.button.outlined,
.button.outlined:hover {
	border: var(--Button-outlined-border);
	width: auto;
	color: var(--button-tertiary-text-color);
	background-color: var(--button-tertiary-bg-color);
}

.button.outlined:hover {
	background-color: var(--button-tertiary-hover-bg-color);
}

.button.contained,
.button.contained:hover,
.button.contained:disabled {
	background-color: var(--Button-contained-background);
	color: var(--color-white);
	width: auto;
	border-radius: calc(var(--global-border-radius) * 2);
}

.button.contained:hover {
	background: var(--button-hover-bg-color);
}

.button.contained:disabled {
	border: unset;
	background: var(--button-disabled-bg-color);
}

.button.contained.secondary:disabled {
	color: red;
}
