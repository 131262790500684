.dialogRoot {
	z-index: 999999 !important;
}

.container .title {
	font-weight: var(--dialog-title-font-weight);
	font-size: 24px;
	line-height: 32px;
	color: var(--color-grey-900) !important;
	font-family: var(--global-font-family) !important;
	padding: 0 0 16px 0 !important;
	word-wrap: break-word;
}

.galleryWrapper {
	display: flex;
	height: calc(100% - 140px);
}

.leftPanel {
	display: flex;
	flex-direction: column;
	border-right: 1px solid #e9e9eb;
	padding-right: 16px;
	overflow-y: auto;
	flex-shrink: 0;
	width: 208px;
}

.dialogContent {
	padding: 0 0 0 24px !important;
	font-family: var(--global-font-family) !important;
	overflow-y: unset !important;
}

.loaderWrapper {
	width: 100%;
	height: 200px;
	position: relative;
}

.dialogRoot .container {
	max-width: min(1200px, calc(100% - 60px));
	min-width: 500px;
	border-radius: 16px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	background: var(--color-white);
	padding: 32px 32px 28px 32px !important;
	box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
		0 20px 24px -4px rgba(16, 24, 40, 0.08);
	height: 90vh;
	max-height: 800px;
}

.subHeading {
	color: var(--color-grey-900);
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-base-font-weight);
	line-height: 20px;
	padding: 8px 12px;
	border-radius: var(--item-radius-8);
	cursor: pointer;
}

.subHeading:hover {
	color: var(--local-themeColor);
}

.active {
	background: #efeffb;
	color: #655be1;
}

.imagesList {
	margin: 10px -6px 0 -6px;
	padding: 6px 6px 0;
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	max-height: min(400px, calc(100vh - 300px));
	overflow: auto;
}

.imagesListWrapper {
	position: relative;
	left: -6px;
}

.imgUploadInput {
	height: 0;
}

.uploadButtonText {
	color: var(--color-white);
	line-height: 20px;
	padding-left: 6px;
}

.subHeadingWrapper {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	justify-content: end;
}

.actions {
	padding: 32px 0 0 0 !important;
}

.dialogRoot .cancelButton,
.dialogRoot .cancelButton:hover {
	border: 1px solid var(--color-grey-300);
	padding: 7px 12px;
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	border-radius: 8px !important;
	box-sizing: content-box;
	line-height: unset !important;
	height: auto;
	gap: 8px;
}

.dialogRoot .cancelButtonText {
	opacity: 0.9;
	font-size: var(--global-base-fontSize);
	color: var(--color-grey-900);
}

.dialogRoot .cancelButton:hover {
	background: var(--color-border);
}

.actions .confirmButton:hover,
.actions .confirmButton,
.uploadButton,
.uploadButton:hover {
	padding: 7px 12px !important;
	height: auto !important;
	line-height: unset !important;
}

.actions .confirmButton:hover,
.uploadButton:hover {
	opacity: 1;
}

.center .confirmButton:hover,
.center .confirmButton {
	margin-left: 0 !important;
}

.actions .confirmButton:hover {
	opacity: var(--dialog-skipButton-hover-opacity);
}

.actions .confirmButton.disabled,
.uploadButton.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.imagesListItem.disabled,
.imagesListItem .disabled {
	cursor: not-allowed;
}

.imagesListItem.disabled {
	opacity: 0.5;
}

.actions .deleteButton:hover,
.actions .deleteButton {
	background-color: var(--dialog-deleteButton-background) !important;
	border-color: var(--dialog-deleteButton-background) !important;
	color: var(--dialog-deleteButton-text-color) !important;
	font-family: var(--global-font-family) !important;
	box-shadow: none !important;
	box-sizing: content-box;
	line-height: 22px;
}

.actions .deleteButton:hover {
	opacity: var(--dialog-deleteButton-hover-opacity);
}

.imagesListItem {
	position: relative;
	width: 168px;
	height: 168px;
	border-radius: var(--item-radius-8);
	flex-shrink: 0;
	cursor: pointer;
	border: 1px solid var(--color-border2);
	background: var(--color-border);
}

.imagesListItem.selectedImagesListItem:before {
	content: '';
	position: absolute;
	width: calc(100% + 12px);
	height: calc(100% + 12px);
	border: 4px solid var(--global-themeColor);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
}

.imageItem {
	pointer-events: none;
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.emptyMessage {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: var(--global-title-font-weight);
	color: var(--color-grey-900);
	font-size: 20px;
}

.maxFileSize {
	display: inline-block;
	color: var(--global-tertiaryTextColor);
	margin-right: 16px;
}

.subTitle {
	color: var(--global-tertiaryTextColor);
	line-height: 20px;
	font-weight: var(--global-base-font-weight);
}

.processingLoaderContainer {
	position: absolute;
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: var(--color-black);
	opacity: 0.4;
	background: linear-gradient(180deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
	border: 1px solid var(--color-border2);
	border-radius: var(--item-radius-8);
	z-index: 999;
	top: 0;
}

.processingLoaderContainerImg {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: var(--color-black);
	opacity: 0.4;
	background: linear-gradient(180deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
	border: 1px solid var(--color-border2);
	border-radius: var(--item-radius-8);
	z-index: 999;
	top: 0;
}

.processingSvg {
	animation: fadeInFromNone 2s ease infinite;
}

@keyframes fadeInFromNone {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.05;
	}

	100% {
		opacity: 1;
	}
}

.processingText {
	font-size: 14px;
	font-weight: 400;
}

.padding-top {
	padding-top: 8px;
}

.retryWrapper {
	margin-top: 8px;
}

.audioItem {
	cursor: pointer;
	width: 100%;
	height: 40px;
	border-radius: 8px;
	border: 2px solid var(--color-border);
	background-size: cover;
	background-position: center center;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 4px 12px 4px 12px;
	font-weight: var(--global-title-font-weight);
}

.fileDetails {
	display: inline-flex;
	align-items: center;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	padding-left: 8px;
}

.audioSpan {
	padding-left: 4px;
	font-size: 14px;
	font-weight: 400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: var(--global-primaryTextColor);
}

.audiosListItem {
	position: relative;
	width: 168px;
	height: 40px;
	border-radius: var(--item-radius-8);
	flex-shrink: 0;
	cursor: pointer;
	border: 1px solid var(--color-border2);
}

.audiosListItem.selectedImagesListItem:before {
	content: '';
	position: absolute;
	width: calc(100% + 12px);
	height: calc(100% + 12px);
	border: 4px solid var(--global-themeColor);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	z-index: -1;
}

.playIcon {
	display: inline-flex;
}

.subLine {
	border-bottom: 1px solid var(--global-border-color);
	padding-bottom: 8px;
	margin-bottom: 8px;
}

.diamondIcon {
	margin-left: 8px;
}

.videoScreenCaptureWrapper {
	width: 100%;
	height: 100%;
}
