.diamondIcon {
	width: 16px;
	height: 16px;
	line-height: 0;
	overflow: hidden;
}

.diamondIconImg {
	width: 100%;
}
