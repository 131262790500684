.container {
	height: 100%;
	background: var(--login-background) !important;
	overflow: hidden;
}

.header {
	font-family: var(--global-font-family) !important;
	font-size: var(--login-header-font-size);
	font-weight: var(--login-header-font-weight);
	padding-bottom: var(--login-header-padding-bottom);
	color: var(--login-header-text-color) !important;
}

.linea {
	background-color: var(--login-linea-background);
	height: var(--login-linea-height);
	width: var(--login-linea-width);
	margin: var(--login-linea-margin);
}

.pageContent {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-family: var(--login-pageContent-font-family);
	flex-direction: var(--login-pageContent-flex-direction);
	/* background-image: var(--login-pageContent-background-image); */
	background-color: var(--login-pageContent-background-color) !important;
	font-size: var(--login-pageContent-font-size);
	color: var(--login-pageContent-text-color) !important;
}

.floikInfo {
	font-weight: var(--login-pageContent-info-font-weight);
	font-size: var(--login-pageContent-info-font-size);
	padding: var(--login-pageContent-info-padding);
}
