.wrapper {
	width: 100%;
	height: 83px;
	background: white;
	top: 31px;
	position: absolute;
}

.currentTime {
}

.timeValue {
	white-space: nowrap;
}

.container {
	display: flex;
	align-items: center;
	gap: 22px;
}

.colorPrimary {
	color: var(--color-primary-400);
}

.colorSecondary {
	color: var(--color-grey-400);
}

.thumbColorPrimary {
	color: white;
	border: 4px solid var(--color-primary-400);
	width: 18px;
	height: 18px;
}

.rail {
	color: var(--color-grey-400);
}
