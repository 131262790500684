.title {
	padding: 0 0 20px 0;
	color: var(--global-primaryTextColor);
	font-family: var(--profileDialog-title-font-family);
	font-style: normal;
	font-weight: var(--global-title-font-weight);
	font-size: var(--global-title-fontSize);
}

.toggleLogoContainer {
	padding-bottom: 24px;
}

.sectionTitle {
	padding: 0 0 5px 0;
	color: var(--global-primaryTextColor) !important;
	font-style: normal;
	font-weight: var(--global-title-font-weight);
	font-size: var(--global-base1-fontSize);
}

.disable {
	opacity: 0.6;
	cursor: not-allowed;
}

.sectionSubTitle {
	padding: 0 0 5px 0;
	color: var(--global-secondaryTextColor);
	font-style: normal;
	font-size: var(--global-base-fontSize);
}

.uploadInput {
	display: none;
}

.uploadImgBtn {
	border: var(--brandingDialog-btn-border-color) 1px dashed;
	width: 100%;
	padding: 15px;
	border-radius: 8px;
	margin-top: 8px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.uploadingImage {
	cursor: not-allowed;
	opacity: 0.7;
}

.uploadingImage imput {
	pointer-events: none;
}

.brandImageContainer,
.brandImage {
	position: relative;
	height: 48px;
	width: 98px;
	object-fit: contain;
	border-radius: 8px;
	transition: opacity 150ms ease-in;
}

.brandImageContainer:before {
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	height: 100%;
	width: 100%;
	z-index: 9;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.8);
	/* Border */

	border: 1px solid var(--color-border-color);
	backdrop-filter: blur(3px);
	opacity: 0;
	border-radius: 8px;
	transition: opacity 150ms ease-in;
}

.removeImageText {
	opacity: 0;
}

.canRemoveImage .removeImageText {
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	/*display: flex;*/
	justify-content: center;
	align-items: center;
	content: '';
	height: 100%;
	font-size: var(--global-base1-fontSize);
	width: 100%;
	color: var(--color-dark-red);
	font-weight: var(--global-bold-font-subtext);
	z-index: 9;
	display: flex;
	opacity: 0;
	transition: opacity 150ms ease-in;
}

.canRemoveImage:hover.brandImageContainer:before,
.canRemoveImage:hover .removeImageText {
	opacity: 1;
}

.uploadText1 {
	font-size: var(--global-button-fontSize);
	color: var(--color-dark-primary);
	padding-bottom: 4px;
}

.uploadText2 {
	color: var(--global-secondaryTextColor);
	font-style: normal;
	font-size: var(--global-base-fontSize);
}

.tabName {
	color: var(--global-secondaryTextColor) !important;
	font-style: normal;
	font-weight: var(--global-bold-font-subtext);
	font-size: var(--global-base-fontSize);
}

.tabs {
	border-bottom: #f3f3f3 1px solid;
	display: flex;
	padding: 5px 0 0 0;
	margin-bottom: 8px;
}

.tab {
	padding: 5px 12px 7px 12px;
	color: var(--global-secondaryTextColor) !important;
	font-style: normal;
	font-size: var(--global-base-fontSize);
	border-bottom: white 2px solid;
	cursor: pointer;
}

.tab.selected {
	font-weight: var(--global-bold-font-subtext);
	border-bottom-color: var(--color-dark-primary);
}

.tab:hover {
	color: var(--color-dark-primary);
	border-bottom-color: var(--color-dark-primary);
}

.guideView {
	background-color: #f8fafc;
	background: url('../../Common/images/guide-view.png') no-repeat center;
	width: 320px;
	height: 200px;
	margin-top: 10px;
	position: relative;
}

.guideView .posBtn {
	background: #fff;
	width: 48px;
	min-width: 48px;
	padding: 0;
	height: 30px;
	border: 1px dashed var(--color-dark-primary);
	position: absolute;
}

.guideView .posBtn:hover,
.guideView .posBtn.selected {
	background: var(--color-dark-primary);
	border: 1px solid var(--color-dark-primary);
}

.guideView .posBtn.posTL {
	top: 50px;
	left: 50px;
}

.guideView .posBtn.posTR {
	top: 50px;
	right: 50px;
}

.guideView .posBtn.posBL {
	bottom: 24px;
	left: 50px;
}

.guideView .posBtn.posBR {
	bottom: 24px;
	right: 50px;
}

.disable .posBtn {
	cursor: not-allowed;
}

.colorPicker {
	display: flex;
	align-items: center;
	margin-top: 10px;
	position: relative;
	width: 150px;
}

.colorViewer {
	width: 32px;
	height: 32px;
	border-radius: 100%;
	margin-right: 10px;
}

.colorInput {
	width: 100px;
	height: 32px;
	border: var(--brandingDialog-border-color) 1px solid;
	width: 100px;
	padding: 6px 18px;
	box-shadow: none;
	border-radius: 8px;
}

.colorInput::after,
.colorInput::before {
	display: none;
}

.colorInputSelector {
	visibility: hidden;
	position: absolute;
	left: 0;
	top: 15px;
	z-index: -1;
}

.previewBoxes {
	display: flex;
	flex-wrap: wrap;
}

.previewBox {
	width: 207px;
	height: 134px;
	margin: 0 7px 7px 0;
	border: 1px solid #f2f3f5;
	border-radius: 4px;
	position: relative;
}

.previewBox:last-child {
	margin-right: 0;
}

.previewBoxWhite {
	box-shadow: 0px 0.672126px 1.34425px rgba(16, 24, 40, 0.06);
	background-color: white;
	position: absolute;
	border-radius: 5px;
}

.previewBoxGrey {
	position: absolute;
	background: linear-gradient(90.62deg, #f7f7f7 0%, rgba(243, 243, 243, 0.35) 100%);
	border-radius: 5px;
}

.previewBoxBrandColor {
	border-radius: 5px;
	position: absolute;
}

.switchContainer {
	margin-top: 10px;
}
