.dialogRoot {
	z-index: var(--global-tooltip-z-index) !important;
}

.dialogWrapper {
	max-width: 100% !important;
}

.dialogContainer {
	background: var(--global-transparent-bg);
}

.saveGridContainer {
	height: 100%;
	justify-content: space-around;
	flex-wrap: nowrap;
}

.dialogContainer .dialogWrapper {
	transition: all 5s;
	border: unset;
	border-radius: unset;
	align-items: center;
	box-shadow: none;
	background: transparent;
}

.dalogSaveContianer .dialogWrapper {
	transition: all 5s;
	border: unset;
	border-radius: unset;
	box-shadow: var(--accountSettings-box-shadow);
	width: var(--newFloPage-save-dialogWrapper-width);
	background: transparent;
}

.dialogContainer .cardBody {
	height: 70vh;
	box-sizing: border-box;
	min-width: 260px;
	width: 1000px;
	background: var(--global-primaryBackground) !important;
	border: 1px solid var(--global-border-color);
	box-shadow: var(--accountSettings-box-shadow);
	border-radius: 16px;
	overflow: hidden !important;
	max-width: 1000px;
	flex-wrap: nowrap;
}

.closeIcon {
	color: var(--global-secondaryTextColor) !important;
	font-size: 16px;
}

.sidebar {
	padding: 32px 16px;
	background-color: var(--color-light-grey);
	width: 280px;
	flex-shrink: 0;
	height: 100%;
	border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.sidebar .heading {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 30px;
	padding-left: 12px;
	color: var(--global-primaryTextColor);
}

.sidebar .btn {
	width: 100%;
	text-align: left;
	justify-content: flex-start;
	padding: 10px 16px;
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-base-font-weight);
	line-height: normal;
	color: var(--global-primaryTextColor);
	border-radius: var(--item-radius-8);
	margin-bottom: 4px;
}

.sidebar .btn.brandingBtnWithDiamond {
	padding: 6px 16px;
}

.sidebar .btn:first-child,
.sidebar .btn:last-child {
	margin-bottom: 0;
}

.hr {
	width: calc(100% - 32px);
	height: 1px;
	margin: 12px auto;
	background: var(--global-borderColor);
}

.sidebar .btn:hover,
.sidebar .btn.active {
	color: var(--color-dark-primary);
	background-color: #edf1fa;
	font-weight: var(--global-title-font-weight);
}

.sidebar .btn .btnIcon {
	fill: var(--color-dark-grey);
	margin-right: 8px;
}

.sidebar .btn .teamIcon {
	margin-right: 8px;
	color: var(--color-dark-grey);
}

.sidebar .btn:hover .teamIcon,
.sidebar .btn.active .teamIcon {
	color: var(--color-dark-primary);
}

.sidebar .btn:hover .btnIcon,
.sidebar .btn.active .btnIcon {
	fill: var(--color-dark-primary);
}

.sidebar .btn .billingIcon {
	margin-right: 8px;
	width: 16px;
	height: 16px;
}

.sidebar .btn.hover .billingIcon:hover,
.sidebar .btn.active .billingIcon:active {
	color: var(--color-dark-primary);
	background-color: #edf1fa;
	margin-right: 8px;
}

.rightSection {
	padding: 28px;
	flex-grow: 1;
	height: 100%;
	min-height: 400px;
	overflow: auto;
	position: relative;
}

.rightSection .title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
	.dialogContainer .cardBody {
		width: calc(100vw - 70px);
	}
}

.diamondIconWrapper {
	margin-left: 8px;
	width: 24px;
	height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background: #e0870126;
}
