.customControls {
	position: relative;
	border-radius: 4px;
	background-color: rgba(43, 51, 63, 0.7);
	user-select: none;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #1c1f2c;
	opacity: 0;
}

.customControlsContainer {
	background-color: #ffffff;
	padding: 36px 24px 24px 24px;
}

.customControlsContainer.disable {
	opacity: 0.7;
	pointer-events: none;
}

.customControlsContainer.trimEnable {
	padding: 28px 24px 24px 24px;
}

.paused {
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	background-image: var(--videoPlayer-play-control-play-icon-image);
	background-size: contain;
}

.playing {
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	background-image: var(--videoPlayer-play-control-pause-icon-image);
	background-size: contain;
}

.progressContainer {
	position: relative;
	width: 100%;
	background-color: var(--videoPlayer-progress-bar-background);
	/*background: var(--brand-color-videoPlayer-progress-control-bar-background);*/
	height: 15px;
	border-radius: var(--videoPlayer-progress-control-border-radius);
	margin-bottom: 24px;
	overflow: hidden;
	cursor: pointer;
}

.trimEnable .progressContainer {
	height: 32px;
}

.hide {
	pointer-events: none;
	visibility: hidden;
	margin-bottom: 30px;
}

.disabled {
	pointer-events: none;
}

.progress {
	width: 0;
	/*background: var(--brand-color-videoPlayer-progress-control-bar-background);*/
	background: var(--global-themeColor);
	height: 100%;
	border-radius: 4px;
	pointer-events: none;
	transition: 150ms ease-out;
}

.trimmedArea {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: var(--color-grey-300);
	opacity: 0.6;
	pointer-events: none;
	background: repeating-linear-gradient(
		45deg,
		var(--color-grey-300),
		var(--color-grey-300) 2px,
		var(--color-white) 2px,
		var(--color-white) 10px
	);
}

.overlayTrimmed {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--color-black);
	opacity: 0.5;
	pointer-events: none;
	z-index: 9999;
	background: repeating-linear-gradient(
		45deg,
		var(--color-grey-600),
		var(--color-grey-600) 2px,
		var(--color-grey-800) 2px,
		var(--color-grey-800) 10px
	);
}

.play-status {
	height: 20px;
	width: 20px;
	color: var(--color-black);
	cursor: pointer;
}

.sound,
.muted {
	cursor: pointer;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	margin: 0 0 0 20px;
	background-size: contain;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

/*.sound {*/
/*	background-image: var(--videoPlayer-videoVolumeUrl);*/
/*}*/

/*.muted {*/
/*	background-image: var(--videoPlayer-videoMuteVolumeUrl);*/
/*}*/

.buttonControllerContainer {
	display: flex;
	justify-content: space-between;
}

.buttonControllerContainer.disable {
	pointer-events: none;
}

.duration {
	margin: 0 0 0 20px;
	color: var(--global-primaryTextColor);
}

.leftCustomContols {
	display: flex;
	align-items: center;
}
