.videoPlayerContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	height: var(--videoPlayer-container-height);
}

.floatingCommentContainer {
	position: absolute;
	display: flex;
	top: var(--videoPlayer-container-top);
	left: var(--videoPlayer-container-left);
	padding: var(--videoPlayer-container-padding);
	flex-flow: var(--videoPlayer-container-flex-flow);
	border-radius: var(--videoPlayer-container-border-radius);
	color: var(--videoPlayer-container-color);
	background: var(--videoPlayer-container-background);
	border: var(--videoPlayer-container-border);
	z-index: 9999;
}

.messageContainer {
	display: flex;
	align-items: flex-end;
}

.sendIcon,
.sendIcon:disabled,
.sendIcon:hover,
.messageContainer .sendIcon,
.messageContainer .sendIcon:disabled,
.messageContainer .sendIcon:hover {
	background: var(--videoPlayer-sendIcon-background);
	padding: var(--videoPlayer-sendIcon-padding);
	margin: var(--videoPlayer-sendIcon-margin);
	border-radius: var(--videoPlayer-sendIcon-border-radius);
	width: var(--videoPlayer-sendIcon-width);
	height: var(--videoPlayer-sendIcon-height);
	text-align: var(--videoPlayer-sendIcon-text-align);
	/*background: var(--themeColor);*/
}

.sendIcon:hover {
	opacity: 0.8;
}

.sendIcon:disabled {
	opacity: 0.5;
}

.textArea {
	border-radius: var(--videoPlayer-textArea-border-radius);
	background: var(--videoPlayer-textArea-background);
	border: var(--videoPlayer-textArea-border);
	resize: var(--videoPlayer-textArea-resize);
	font-size: var(--videoPlayer-textArea-font-size);
	font-weight: var(--videoPlayer-textArea-font-weight);
	outline: var(--videoPlayer-textArea-outline);
	padding: var(--videoPlayer-textArea-padding);
	color: var(--videoPlayer-textArea-color);
	max-height: var(--videoPlayer-textArea-max-height);
	overflow-y: hidden !important;
}

.textArea:focus {
	outline: var(--videoPlayer-textArea-focus-outline);
}

.header {
	display: flex;
	font-weight: var(--videoPlayer-font-weight);
	justify-content: space-between;
}

.videoContainer {
	position: relative;
	display: flex;
	width: inherit;
	flex-shrink: 0;
	justify-content: center;
	/*overflow: hidden;*/
	border-radius: var(--videoPlayer-videoContainer-border-radius);
	height: var(--videoPlayer-videoContainer-height);
}

.playerAnnotationContainer {
	position: absolute;
	height: var(--videoPlayer-playerAnnotationContainer-height);
	bottom: var(--videoPlayer-playerAnnotationContainer-bottom);
}

.playerAmplitudeContainer {
	position: absolute;
	height: var(--videoPlayer-playerAmplitudeContainer-height);
	bottom: var(--videoPlayer-playerAmplitudeContainer-bottom);
	pointer-events: none;
	user-select: none;
	opacity: 0;
	transition: opacity 300ms;
}

.playerAmplitudeContainer.amplitudeView {
	opacity: 1;
}

.moveThumbnail :global(.vjs-vtt-thumbnail-display) {
	bottom: var(--videoPlayer-thumbnail-onAmplitude--bottom) !important;
}

.annotation {
	position: absolute;
	cursor: pointer;
	height: var(--videoPlayer-annotation-height);
	width: var(--videoPlayer-annotation-width);
	justify-content: center;
	align-items: center;
	display: flex;
	color: var(--videoPlayer-annotation-color);
}

.profilePicContainer {
	cursor: pointer;
	background-color: var(--videoPlayer-annotation-background);
}

.presenterProfile {
	position: absolute;
	z-index: 99999;
	left: 0;
	top: 0;
	transform: translateX(45%);
}

.presenterProfilePicContainer {
	background-color: transparent !important;
}

.annotation:hover {
	z-index: 9;
}

.annotation.active {
	border: var(--videoPlayer-annotation-active-border);
	box-sizing: content-box;
	bottom: var(--videoPlayer-annotation-active-bottom);
	z-index: 9;
}

.annotation.active::after {
	content: '';
	position: absolute;
	border: var(--videoPlayer-annotation-active-pseudoAfter-border);
	height: 100%;
	width: 100%;
	border-radius: var(--videoPlayer-annotation-active-pseudoAfter-border-radius);
}

.resolved,
.annotation.active.resolved {
	filter: var(--videoPlayer-active-filter);
}

.closeIcon {
	cursor: pointer;
}

.hidden {
	display: none;
}

.hiddenOpacity {
}

.mainVideoPlayer {
	overflow: hidden;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9;
	background: var(--videoPlayer-container-overlay);
}

.resizeContainer {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 0 !important;
	pointer-events: none;
}

.resizeContainer.resizeActive {
	z-index: 99 !important;
	pointer-events: all;
}

.uiActivityBorder {
	top: 0;
	position: absolute;
	border: none;
	width: 100px;
	height: 100px;
	border-radius: 16px;
}

.commentIcon {
	color: #42a1a1;
}

.uiActivityMouseClick {
	/*position: absolute;*/
	top: 0;
	left: 80px;
	height: 12px;
	width: 12px;
	/*transform: translate(-12px, -2px);*/
	/*border: 1px solid white;*/
	/*background: white;*/
	border-radius: 50%;
	/*animation: radiation 1s infinite;*/
	/*opacity: 0.8;*/
}

.uiActivityWrapper {
	position: absolute;
	overflow: hidden;
	/*left: 0;*/
	top: 0;
	/*pointer-events: none;*/
}

.uiActivityWrapper.isEditingWrapper {
	z-index: 9;
}

.uiActivityContainer {
	position: relative;
	top: 0;
	/*left: 0;*/
	overflow: hidden;
	/*pointer-events: none;*/
}

.ctaHeader {
	font-family: 'Rubik';
	font-weight: 600;
	font-size: 20px;
	color: #232633;
	width: 100%;
	text-align: center;
	padding-bottom: 16px;
}

.ctaBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 350px;
}

.ctaDescription {
	font-family: 'Rubik';
	font-weight: 600;
	font-size: var(--global-base-fontSize);
	display: flex;
	align-items: center;
	text-align: center;

	/* dark- grey */
	color: var(--global-primaryTextColor);
	opacity: 0.7;
	padding-bottom: 24px;
}

.ctaButton,
.ctaButton:hover {
	color: var(--global-button-text-color) !important;
	background: var(--global-themeColor) !important;
}

.ctaButton:hover {
	opacity: 0.8;
}

.brandImageContainer {
	position: absolute;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.brandImageWrapper {
	position: relative;
}

/*SHAKER*/
@keyframes wiggle {
	2% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	4% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
	6% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	8% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
	10% {
		-webkit-transform: translateX(2px) rotate(1deg);
		transform: translateX(2px) rotate(1deg);
	}
	12% {
		-webkit-transform: translateX(-2px) rotate(-1deg);
		transform: translateX(-2px) rotate(-1deg);
	}
	14% {
		-webkit-transform: translateX(2px) rotate(1deg);
		transform: translateX(2px) rotate(1deg);
	}
	16% {
		-webkit-transform: translateX(-2px) rotate(-1deg);
		transform: translateX(-2px) rotate(-1deg);
	}
	18% {
		-webkit-transform: translateX(1px) rotate(0);
		transform: translateX(1px) rotate(0);
	}
	20% {
		-webkit-transform: translateX(-1px) rotate(0);
		transform: translateX(-1px) rotate(0);
	}
}

.floatingCommentContainer.wiggle {
	display: inline-block;
	animation: wiggle 2.7s infinite;
	z-index: 9999;
}

.videoWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.overlayCrop {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: var(--videoPlayer-background);
	/*background: #6c6cf1;*/
	z-index: 1;
	pointer-events: none;
	user-select: none;
}
/*END SHAKER*/

.isEditing:after {
	content: '';
	position: absolute;
	top: -30px;
	left: -30px;
	padding: 30px;
	border-radius: 4px;
	background: transparent;
	cursor: move;
	z-index: 9;
}

.editingWrapper {
	pointer-events: none;
}

.infinity {
	position: absolute;
	/*bottom: -16px;*/
	/*left: -28px;*/
	bottom: -6px;
	left: -16px;
}

.adventureCtaDialogRoot .adventureCtaDialogContainer {
	padding: 0 !important;
	background: transparent;
	box-shadow: none;
	width: 480px;
}

.adventureCtaDialogRoot .adventureCtaDialogMessage {
	padding: 40px;
}

.hide {
	display: none;
}

.loader {
	position: absolute;
	z-index: 9;
	pointer-events: painted;
	background: #ffffff63;
}

.audioPlayer {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

.floik-hotspot-container {
	background: transparent;
	user-select: none;
	position: absolute;
	/*z-index: 99;*/
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.popOver {
	z-index: 999;
}

.popOverSwiftPlay {
	z-index: 99999;
}

.animation {
	animation-duration: 150ms;
	animation-timing-function: linear;
}

.popOver .moveInFromRight {
	animation-name: moveInFromRight-80;
}

.popOver .moveInFromLeft {
	animation-name: moveInFromLeft-80;
}

.popOver .moveInFromTop {
	animation-name: moveInFromTop-80;
}

.popOver .moveInFromBottom {
	animation-name: moveInFromBottom-80;
}

[class*='videoWidthLessThan800'] .popOver .moveInFromRight {
	animation-name: moveInFromRight-60;
}

[class*='videoWidthLessThan800'] .popOver .moveInFromLeft {
	animation-name: moveInFromLeft-60;
}

[class*='videoWidthLessThan800'] .popOver .moveInFromTop {
	animation-name: moveInFromTop-60;
}

[class*='videoWidthLessThan800'] .popOver .moveInFromBottom {
	animation-name: moveInFromBottom-60;
}

@keyframes moveInFromLeft-60 {
	0% {
		right: 60px;
	}
	100% {
		right: 0;
	}
}

@keyframes moveInFromRight-60 {
	0% {
		left: 60px;
	}
	100% {
		left: 0;
	}
}

@keyframes moveInFromTop-60 {
	0% {
		bottom: 60px;
	}
	100% {
		bottom: 0;
	}
}

@keyframes moveInFromBottom-60 {
	0% {
		top: 60px;
	}
	100% {
		top: 0;
	}
}

@keyframes moveInFromLeft-80 {
	0% {
		right: 80px;
	}
	100% {
		right: 0;
	}
}

@keyframes moveInFromRight-80 {
	0% {
		left: 80px;
	}
	100% {
		left: 0;
	}
}

@keyframes moveInFromTop-80 {
	0% {
		bottom: 80px;
	}
	100% {
		bottom: 0;
	}
}

@keyframes moveInFromBottom-80 {
	0% {
		top: 80px;
	}
	100% {
		top: 0;
	}
}

.box {
	padding: 16px;
	border-radius: var(--item-radius-8);
	background: var(--brand-color);
}

.contentEditable {
	color: var(--color-white);
	font-family: var(--form-fonts);
	font-style: var(--global-font-style-normal);
	white-space: normal;
	min-width: 100%;
	max-width: 400px;
	border: 1px dashed transparent;
}

.title {
	font-size: var(--global-title-fontSize) !important;
	font-weight: var(--global-title-font-weight) !important;
	/*line-height: 15px !important;*/
	max-height: 100px;
	overflow: auto;
	white-space: initial;
	word-wrap: break-word;
	padding: 2px;
}

.title p,
.title div,
.title a {
	color: var(--color-white);
}

.description {
	max-height: 100px;
	overflow: auto;
}

.description * {
	font-size: var(--global-base1-fontSize) !important;
	font-weight: var(--global-base-font-weight) !important;
	line-height: 23px !important;
	padding-left: 0;
	white-space: break-spaces;
	word-wrap: break-word;
}

.description strong {
	font-weight: 700 !important;
}

.description ol,
.description ol li,
.description ul,
.description ul li {
	overflow: unset;
	white-space: unset;
}

.description p,
.description div,
.description code,
.description a {
	color: var(--color-white);
}

.title p,
.description p {
	margin-bottom: 4px;
}

.title p:last-child,
.description p:last-child {
	margin-bottom: 0;
}

.title a,
.description a {
	background: var(--videoPlayer-link-background);
	padding: 1px 2px;
	display: inline;
	box-sizing: content-box;
	border-radius: 2px;
}

.description ul,
.description ul li {
	list-style: disc;
	/* padding-left: revert; */
}

.description ul ul,
.description ul ul li {
	list-style: circle;
	margin: 0;
}

.description ul ul ul,
.description ul ul ul li {
	list-style: disc;
	margin: 0;
}

.description ul ul ul ul,
.description ul ul ul ul li {
	list-style: disc;
	margin: 0;
}

.description ol,
.description ol li {
	list-style: auto;
	/* padding-left: revert; */
}

.description ol li {
	list-style: decimal;
}

.description ol ol,
.description ol ol li {
	list-style: lower-alpha;
	margin: 0;
}

.description ol ol ol,
.description ol ol ol li {
	list-style: lower-roman;
	margin: 0;
}

.description ol ol ol ol,
.description ol ol ol ol li {
	list-style: decimal;
	margin: 0;
}

.description ul,
.description ol {
	padding-left: 20px;
	margin: 8px 0;
}

.arrow {
	position: absolute;
}

[data-popper-placement='left-start'] .arrow,
[data-popper-placement='left-end'] .arrow,
[data-popper-placement='left'] .arrow {
	right: 0;
}
[data-popper-placement='top-start'] .arrow,
[data-popper-placement='top-end'] .arrow,
[data-popper-placement='top'] .arrow {
	bottom: 0;
}

.arrow:before {
	position: absolute;
	content: '';
	width: 0;
	height: 0;
}

[data-popper-placement='left-start'] .arrow:before,
[data-popper-placement='left-end'] .arrow:before,
[data-popper-placement='left'] .arrow:before {
	border: 8px solid transparent;
	border-left: 10px solid var(--brand-color);
	transform: translate(0%, -50%);
}

[data-popper-placement='right-start'] .arrow:before,
[data-popper-placement='right-end'] .arrow:before,
[data-popper-placement='right'] .arrow:before {
	border: 8px solid transparent;
	border-right: 10px solid var(--brand-color);
	transform: translate(-100%, -50%);
}

[data-popper-placement='bottom-start'] .arrow:before,
[data-popper-placement='bottom-end'] .arrow:before,
[data-popper-placement='bottom'] .arrow:before {
	border: 8px solid transparent;
	border-bottom: 10px solid var(--brand-color);
	transform: translate(-50%, -100%);
}

[data-popper-placement='top-start'] .arrow:before,
[data-popper-placement='top-end'] .arrow:before,
[data-popper-placement='top'] .arrow:before {
	border: 8px solid transparent;
	border-top: 10px solid var(--brand-color);
	transform: translate(-50%, 0%);
}

.backgroundRef {
	width: 32px;
	height: 32px;
	position: absolute;
	left: -16px;
	top: -16px;
}

.tooltipFooter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
}

.nextButton {
	padding: 1px 6px;
	box-sizing: content-box;
	color: var(--color-black) !important;
	background: var(--color-white) !important;
	border: 1px solid transparent !important;
	border-radius: 6px !important;
	margin-top: 4px;
}

.prevButton {
	background: #ffffff33;
	color: var(--color-white) !important;
	margin-top: 4px;
	border-radius: 6px;
}
