.container {
	display: flex;
	/*height: var(--Page404-container-height);*/
	background: var(--Page404-container-background);
	z-index: 9999;
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
}

.image {
	max-width: var(--Page404-image-max-width);
	height: var(--Page404-image-height);
}

.homeButton,
.homeButton:hover {
	background: var(--Page404-homeButton-background);
	border-radius: var(--Page404-homeButton-border-radius);
	padding: var(--Page404-homeButton-padding);
	text-decoration: var(--Page404-homeButton-text-decoration);
	color: var(--Page404-homeButton-color);
	display: block;
	width: var(--Page404-homeButton-width);
	cursor: pointer;
}

.imageContainer {
	padding-right: var(--Page404-imageContainer-padding-right);
	text-align: var(--Page404-imageContainer-text-align);
	margin: var(--Page404-imageContainer-margin);
}

.container .contentContainer {
	padding-left: var(--Page404-container-contentContainer-padding-left);
	text-align: var(--Page404-container-contentContainer-text-align);
	margin: var(--Page404-container-contentContainer-margin);
}

.imageContainer path:nth-child(1) {
	fill: var(--Page404-imageContainer-path-1-background);
}

.imageContainer path:nth-child(2),
.imageContainer path:nth-child(3),
.imageContainer path:nth-child(4) {
	fill: var(--Page404-imageContainer-path-2-3-4-background);
}

.contentTitle {
	font-family: var(--Page404-contentTitle-font-family);
	font-style: var(--Page404-contentTitle-font-style);
	font-weight: var(--Page404-contentTitle-font-weight);
	font-size: var(--Page404-contentTitle-font-size);
	line-height: var(--Page404-contentTitle-line-height);
	color: var(--Page404-contentTitle-color);
	margin-bottom: var(--Page404-contentTitle-margin-bottom);
}

.contentText {
	font-family: var(--Page404-contentText-font-family);
	font-style: var(--Page404-contentText-font-style);
	font-weight: var(--Page404-contentText-font-weight);
	font-size: var(--Page404-contentText-font-size);
	line-height: var(--Page404-contentText-line-height);
	color: var(--Page404-contentText-color);
	opacity: var(--Page404-contentText-opacity);
	margin-bottom: var(--Page404-contentText-margin-bottom);
}
