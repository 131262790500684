.container {
	background: var(--color-dark-orange);
	border-radius: 4px;
	padding: 4px;
	font-size: var(--global-base-font-weight);
	font-weight: var(--global-base-font-weight);
	margin: 0 0 16px 0;
	white-space: normal;
	color: var(--global-primaryBackground);
}
