.title {
	color: var(--profileDialog-title-text-color) !important;
	font-family: var(--profileDialog-title-font-family) !important;
	font-style: normal;
	font-weight: var(--profileDialog-title-font-weight) !important;
	font-size: var(--settings-title-font-size) !important;
	line-height: normal;
}

.title {
	padding: 0 !important;
}

.content {
	display: flex;
	align-items: center;
	padding: 8px 0 0 0 !important;
}

.contentWrapper {
	padding: 24px 0 0 0 !important;
}

.subHeading {
	width: 120px;
	line-height: 20px;
	color: var(--color-grey-700);
}

.subHeadingValue {
	color: var(--color-grey-900);
	line-height: 20px;
	display: flex;
}

.upgradePendingTextWrapper {
	display: flex;
	gap: 12px;
	align-items: center;
}

.upgradePendingText {
	display: inline-flex;
	gap: 8px;
	color: var(--color-orange);
	line-height: 20px;
	align-items: center;
}

.paymentStatusContainer {
	display: flex;
	margin-top: 8px;
	align-items: center;
}

.paymentStatus {
	line-height: 20px;
	color: var(--color-orange);
}

.upgradePlanContainer.failed .upgradePendingText,
.upgradePlanContainer.failed .paymentStatus {
	color: var(--color-red-white);
}

.paymentStatusLabel {
	margin-right: 8px;
}

.paymentStatusContainer .paymentRetryButton {
	margin-left: 20px;
	font-size: var(--global-base-fontSize);
	flex-shrink: 0;
	padding: 0 8px;
}

.whiteBtn:hover {
	opacity: var(--profileDialog-skipButton-hover-opacity);
}

.whiteBtn:disabled {
	opacity: var(--profileDialog-saveButtonDisabled-opacity);
}

.whiteBtn {
	border-radius: var(--profileDialog-button-border-radius) !important;
}

.whiteBtn:hover,
.whiteBtn {
	font-weight: var(--profileDialog-saveButton-font-weight) !important;
	background: var(--global-primaryBackground) !important;
	color: var(--global-themeColor) !important;
	text-transform: none !important;
	margin-left: 0 !important;
	margin-top: 10px !important;
	font-family: var(--profileDialog-button-font-family);
	box-shadow: none !important;
	border: 1px solid var(--global-themeColor) !important;
	flex-shrink: 0;
}

.noMargin,
.noMargin:hover {
	margin: 0 !important;
}

.contactUsWrapper {
	padding: 12px 16px;
	box-shadow: var(--profileDialog-upgrade-button-box-shadow);
	background: var(--profileDialog-list-item-background);
	border-radius: var(--item-radius-8);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.upgradePlanContainer {
	display: flex;
	padding: 10px 8px;
	gap: 2px;
	border-radius: 6px;
	background: #fcf3e6;
	margin: 18px 0;
}

.upgradePlanContainer.failed {
	background: var(--color-red-bg);
}

.plansListItem {
	margin-bottom: 16px;
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-300);
	background: var(--color-grey-100);
	padding: 16px;
	gap: 16px;
	display: flex;
	flex-direction: column;
	align-self: stretch;
	width: 209px;
	flex-shrink: 0;
	position: relative;
}

.contactUsWrapper {
	margin-top: 40px;
}

.contactUsText {
	color: var(--global-primaryTextColor);
	font-size: var(--global-base-fontSize);
	font-style: normal;
	font-weight: var(--global-base-font-weight);
	line-height: 16px;
	opacity: 0.8;
	padding: 0 12px;
}

.planCardWrapper {
	width: 120px;
	height: 75px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	align-items: center;
}

.recommendedFlag {
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 8px;
	border-radius: 0 8px;
	background: var(--color-grey-900);
	color: var(--color-white);
	font-size: 11px;
	font-weight: var(--global-title-font-weight);
	line-height: 16px;
}

.plansListItem .planCardWrapper {
	width: 88px;
	height: 64px;
	gap: 7px;
	border-radius: 12px;
}

.plansListItem .planCardWrapper > svg {
	height: 20px;
}

.planDetailsWrapper {
	margin-left: 24px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-evenly;
}

.planLeftChildrenWrapper,
.planRightChildrenWrapper {
	display: flex;
}

.planLeftChildrenWrapper.disabled {
	opacity: 0.26;
}

.planDetails {
	font-weight: var(--global-base-font-weight);
}

.currentPlanAction {
	margin-top: 8px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 16px;
}

.contentWrapper .renewBtn {
	font-weight: var(--global-title-font-weight);
	padding: 0 !important;
	gap: 6px;
	color: var(--global-themeColor);
	line-height: 20px;
	background: transparent !important;
	font-size: var(--global-base-fontSize);
	margin-left: 16px;
}

.contentWrapper .renewBtn:hover {
	background: transparent !important;
	/*background: var(--brand-color-button-background-color);*/
	/*color: var(--brand-color-button-text-color);*/
	/*opacity: 0.8;*/
}

.cancelPlan {
	color: var(--color-red-white);
	font-family: var(--global-font-family);
	font-size: var(--global-base-fontSize);
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	cursor: pointer;
}

.planName {
	color: var(--color-white);
	text-align: center;
	opacity: 0.8;
	text-shadow: 0 0 1.1px rgba(0, 0, 0, 0.25);
	font-size: var(--global-base-fontSize);
	font-style: normal;
	font-weight: var(--global-title-font-weight);
	line-height: 10.5px;
	text-transform: uppercase;
}

.planSubText {
	color: var(--global-primaryTextColor);
	font-size: var(--global-title-fontSize);
	font-style: normal;
	font-weight: var(--global-title-font-weight);
	line-height: normal;
}

.upgradePlanWrapper:not(.withMessage) {
	margin-top: 32px;
}

.plansList {
	margin-top: 24px;
	display: flex;
	gap: 16px;
	overflow: auto;
}

.plansListItemName {
	font-weight: var(--global-title-font-weight);
	font-size: var(--profileDialog-plan-name-font-size);
	display: flex;
	align-items: center;
	color: var(--color-grey-900);
	line-height: 32px;
	width: 100%;
	word-break: break-word;
	text-transform: capitalize;
}

.plansListItem .planUpgradeButton,
.plansListItem .planUpgradeButton:hover {
	border-radius: 8px;
	padding: 8px 12px;
	font-size: var(--global-base-fontSize);
	color: var(--color-white);
	background: var(--global-themeColor);
	line-height: 20px;
	box-shadow: none;
	border: 1px solid transparent;
}

.plansListItem .planUpgradeButton:hover {
	background: var(--button-hover-bg-color);
}

.plansListItem .planUpgradeButton.secondary {
	color: var(--buton-disabled-text-color);
	background: var(--button-disabled-bg-color);
}

.plansListWithPaymentError {
	margin-top: 16px;
}

.reloadIcon {
	cursor: pointer;
}

.paymentStatus.paymentStatusFail {
	/*color: var(--color-red-white);*/
}

.rotateIcon {
	animation: rotate infinite linear 0.8s;
}

.message {
	display: flex;
	justify-content: center;
	font-weight: var(--global-bold-font-subtext);
	font-size: var(--global-title-fontSize);
	opacity: 0.6;
}

.tooltip {
	z-index: 9999999 !important;
}

.contactUsTextContainer {
	display: flex;
}

.icon {
	flex-shrink: 0;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 640px) {
	.title {
		padding: var(--profileDialog-title-padding-tablet) !important;
		text-align: center;
		box-sizing: border-box;
	}

	.content {
		padding: var(--profileDialog-content-padding-tablet) !important;
	}

	.buttonContainer {
		justify-content: center !important;
		padding-bottom: var(--profileDialog-buttonContainer-padding-bottom-tablet) !important;
	}

	.saveButton,
	.whiteBtn {
		margin: 0 !important;
	}
}

.tabsWrapper {
	padding: 4px;
	border-radius: 12px;
	border: 1px solid var(--color-grey-300);
	background: var(--color-white);
	display: inline-flex;
}

.tabsList {
	display: flex;
	gap: 4px;
}

.tabsListItem {
	color: var(--color-grey-700);
	font-weight: var(--global-title-font-weight);
	line-height: 18px;
	cursor: pointer;
	padding: 0;
}

.tabsListItem .tabsListItemLink {
	line-height: initial;
	color: inherit;
	padding: 9px 16px;
	width: 120px;
	font-size: var(--global-base-fontSize);
}

.tabsListItem.activeTabsListItem {
	color: var(--color-white);
	border-radius: var(--item-radius-8);
	background: var(--color-grey-900);
	box-shadow: 0 1px 1px 1px rgba(34, 34, 34, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.03);
}

.subTitle {
	color: var(--color-grey-500);
	font-size: 12px;
	width: 100%;
	word-break: break-word;
}

.price {
	color: var(--color-grey-900);
	font-size: 20px;
	font-weight: var(--global-title-font-weight);
	line-height: 24px;
}

.priceFrequencyWrapper {
	display: inline-block;
	margin-left: 6px;
	color: var(--color-grey-700);
	font-weight: var(--global-title-font-weight);
	line-height: 20px;
}

.planFeaturesListLabel {
	color: var(--color-grey-700);
	font-size: var(--global-mini-font);
	line-height: 16px;
}

.planFeaturesListItem {
	margin-top: 4px;
	color: var(--color-grey-900);
	line-height: 20px;
	display: flex;
	word-break: break-word;
}

.planFeaturesList .planFeaturesListItem {
	margin-top: 8px;
}

.iconCheck {
	margin-right: 12px;
	stroke: var(--global-themeColor);
	flex-shrink: 0;
	position: relative;
	top: 2px;
}
