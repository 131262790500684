.nameInputContainer {
	padding: var(--profileDialog-nameInputContainer-padding);
	width: 100%;
	max-width: 360px;
}

.nameTitle {
	font-family: var(--profileDialog-naameTitle-font-family);
	font-style: normal;
	font-weight: var(--profileDialog-naameTitle-font-weight);
	font-size: var(--profileDialog-naameTitle-font-size);
	line-height: var(--profileDialog-naameTitle-line-height);
	display: flex;
	align-items: center;
	color: var(--global-primaryTextColor) !important;
}

.nameInput {
	padding: var(--profileDialog-naameInput-padding);
	color: var(--global-primaryTextColor) !important;
}

.infoIcon {
	margin-left: 8px;
	cursor: pointer;
}

.title {
	color: var(--profileDialog-title-text-color) !important;
	font-family: var(--profileDialog-title-font-family) !important;
	font-style: normal;
	font-weight: var(--profileDialog-title-font-weight) !important;
	font-size: var(--profileDialog-title-font-size) !important;
}

.title {
	padding: 0 !important;
}

.content {
	padding-top: 20px;
}

.tooltip {
	z-index: 99999999 !important;
}

.tooltipContent {
	padding: 16px !important;
}

.roleInfoItem {
	margin-bottom: 8px;
	font-weight: var(--global-base-font-weight);
}

.roleInfoItemLabel {
	font-weight: var(--global-title-font-weight);
}

@media screen and (max-width: 640px) {
	.title {
		padding: var(--profileDialog-title-padding-tablet) !important;
		text-align: center;
		box-sizing: border-box;
	}
}
