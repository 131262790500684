/* :: start of defining colors :: */

:root {
	--color-white: #ffffff;
	--color-black: #000000;
	--color-grey: #f8f8f8;
	--color-grey2: #e5e5e5;
	--color-light-grey: #fafafa;
	--color-dark-grey: #232633;
	--color-dark-dark-grey: #171921;
	--color-dark-light-grey: #ffffff19;
	--color-primary-dark: #29b2b4;
	--color-dark-red: #ff5677;
	--color-red-white: #e15a5a;
	--color-red-bg: #fcefef;
	--color-blue-very-light: #2660f40f;
	--color-blue-light: #6a94ff;
	--color-dark-primary: #655be1;
	--color-border-hover: #a1a1a1;
	--color-primary: #55916c;
	--color-light-border: #2c302e;
	--color-orange: #e08701;
	--color-dark-orange: #fbb346;
	--color-blue-v2: #655be1;
	--color-border: #e9e9eb;
	--color-border2: #0000001a;
	--color-secondary-dark-text: #545869;
	--color-dark-green: #4ba56f;
	--color-blue-info: #2660f4;
	--color-info-bg: #e9effe;
	--color-blue-bg: #efeffb;
	--color-purple-200: #a99fec;
	--color-purple-600: #3e3dbe;
}

:root {
	--color-red-400: #e15a5a;

	--color-primary-50: #ebe8f9;
	--color-primary-100: #cbc6f0;
	--color-primary-200: #a7a1e7;
	--color-primary-300: #817be0;
	--color-primary-400: #615fd9;
	--color-primary-500: #3a45d3;
	--color-primary-600: #2b40cd;
	--color-primary-700: #0939c4;
	--color-primary-800: #0634bd;
	--color-primary-900: #022aaf;

	--color-grey-50: #fafafa;
	--color-grey-100: #f6f5f6;
	--color-grey-200: #efefef;
	--color-grey-300: #e1e1e1;
	--color-grey-400: #c0c0c0;
	--color-grey-500: #a0a0a0;
	--color-grey-600: #767677;
	--color-grey-700: #626262;
	--color-grey-800: #434343;
	--color-grey-900: #222222;

	--color-green-50: #e9f6ee;
	--color-green-100: #cbe9d4;
	--color-green-200: #aadbb9;
	--color-green-300: #88cd9d;
	--color-green-400: #6fc288;
	--color-green-500: #58b774;
	--color-green-600: #4fa869;
	--color-green-700: #43955c;
	--color-green-800: #3a844f;
	--color-green-900: #2b653a;

	--color-orange-50: #fbf2e2;
	--color-orange-100: #f5deb7;
	--color-orange-200: #eec98a;
	--color-orange-300: #e8b35f;
	--color-orange-400: #e4a446;
	--color-orange-500: #e09537;
	--color-orange-600: #dc8a33;
	--color-orange-700: #d57c2f;
	--color-orange-800: #ce6e2a;
	--color-orange-900: #c45824;

	--color-red-50: #fceced;
	--color-red-100: #f7cfd0;
	--color-red-200: #e89e97;
	--color-red-300: #dd7970;
	--color-red-400: #e45e4d;
	--color-red-500: #e65236;
	--color-red-600: #d94834;
	--color-red-700: #c83e2e;
	--color-red-800: #bc3627;
	--color-red-900: #af2b1c;
}

/* :: start of Button component :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--button-font-family: var(--global-font-family);
	--button-background: var(--global--groupedButton-background);
	--button-border: 1px solid rgba(0, 0, 0, 0.12);
	--button-border-radius: calc(var(--global-border-radius) * 2);

	--buton-disabled-text-color: var(--color-white);
	--button-disabled-bg-color: var(--color-purple-200);
	--button-hover-bg-color: var(--color-purple-600);

	--button-secondary-bg-color: var(--color-grey-900);
	--button-secondary-hover-bg-color: var(--color-grey-800);
	--button-secondary-disabled-bg-color: var(--color-grey-300);
	--button-secondary-text-color: var(--color-white);

	--button-tertiary-bg-color: var(--color-white);
	--button-tertiary-hover-bg-color: var(--color-grey-300);
	--button-tertiary-text-color: var(--color-grey-800);

	--toggleButton-primary-font-family: var(--global-font-family);
	--toggleButton-primary-text-color: var(--global-toggleButtonColor);
	--toggleButton-primary-padding: 8px 24px;
	--toggleButton-primary-font-size: var(--global-base-fontSize);
	--toggleButton-primary-font-weight: 600;
	--toggleButton-primary-border-top: unset;
	--toggleButton-primary-border-bottom: unset;

	--toggleButton-primary-selected-background: var(--global-toggleButtonSelectedBg);
	--toggleButton-primary-selected-hover-background: var(--global-toggleButtonSelectedBg);
	--toggleButton-primary-selected-hover-opacity: 0.9;
	--toggleButton-primary-selected-color: var(--global-toggleButtonSelectedColor);
	--toggleButton-primary-selected-border-radius: var(--item-radius-8);
	--toggleButtongroup-border-radius: var(--item-radius-8);
}

/* ::  end of Button component :: */

/* ::  start of chip component :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--chip-filled-background: var(--global-chipDefaultBackground);
	--chip-filled-color: var(--global-chipTextColor);
	--chip-filled-font-weight: 500;

	--statusChip-font-size: var(--global-base-fontSize);
	--statusChip-font-weight: var(--global-title-font-weight);
	--statusChip-height: 24px;

	--statusChip-default-background: var(--global-chipDefaultBackground);
	--statusChip-floing-background: var(--global-chipDefaultBackground);
	--statusChip-draft-background: var(--global-chip-draft-background);
	--statusChip-closed-background: var(--global-chip-closed-background);
	--statusChip-overdue-background: var(--local-chip-overdue-background);

	--statusChip-floing-text-color: var(--global-chipTextColor);
	--statusChip-draft-text-color: var(--global-chip-draft-text-color);
	--statusChip-closed-text-color: var(--global-chip-closed-text-color);
	--statusChip-overdue-text-color: var(--local-chip-overdue-text-color);
	--statusChip-z-index: var(--global-tooltip-z-index);
}

/* ::  end of chip component :: */

/* :: start of Avatar component :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--avatar-text-color: var(--global-avatar-text-color);
	--avatar-height: calc(var(--global-size) * 4);
	--avatar-width: calc(var(--global-size) * 4);
	--avatar-border-radius: var(--global-border-radius);
	--avatar-circular-border-radius: 50%;
	--avatar-small-height: calc(var(--global-size) * 2 + 2px);
	--avatar-small-width: calc(var(--global-size) * 2 + 2px);
	--avatar-background: var(--global-invertedTextColor);
	--avatar-width: 28px;
	--avatar-height: 28px;
	--avatar-cursor: auto;
}

/* ::  end of Avatar component :: */

/* ::  start of Avatar component :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--button-outlined-border: 1px solid var(--borderColor);
	--button-outlined-text-color: var(--global-primaryTextColor);
	--tablePaginationToolbar-padding: 12px 0 0 0;
}

/* ::  end of Avatar component :: */

/* :: start of AuthenticatedRoutes :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--authenticatedRoutes-background: var(--global-primaryBackground);
	--authenticatedRoutes-container-height: calc(100% - 49px);
}

/* :: end of AuthenticatedRoutes :: */

/* :: start of Instructions :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--instructions-upload-button-border-color: #bdbec2;
	--instructions-uploadText-color: var(--global-secondaryTextColor);
	--instructions-addStepIcon-background: #232633;
}

/* :: end of Instructions :: */

/* :: start of PrimaryHeader :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--primaryHeader-conatiner-flow-direction: row;
	--primaryHeader-conatiner-padding: 0px 16px;
	--primaryHeader-conatiner-background: var(--global-primaryBackground);
	--primaryHeader-conatiner-text-color: var(--global-primaryTextColor);
	--primaryHeader-conatiner-box-shadow: 1px 1px 0px rgba(44, 48, 46, 0.2);
	--primaryHeader-conatiner-border-color: var(
		--local-primaryHeader-conatiner-border-color
	);
	--primaryHeader-conatiner-height: 56px;

	--primaryHeader-containerIcon-margin: 0 8px 0 -8px;
	--primaryHeader-containerIcon-padding: var(--item-radius-8);

	--primaryHeader-userAvatar-image-width: 30px;
	--primaryHeader-userAvatar-image-height: 30px;
	--primaryHeader-appName-font-family: var(--global-font-family);
	--primaryHeader-appName-text-color: var(--global-themeColor);

	--primaryHeader-appName-font-wight: var(--global-medium-font-weight);
	--primaryHeader-appName-font-size: var(--global-heading-fontSize);

	--primaryHeader-newFloButton-padding: 6px 12px 6px 14px;
	--primaryHeader-newFloButton-margin: 0 24px;
	--primaryHeader-newFloButton-color: var(--global-themeColor);
	--primaryHeader-newFloButton-background: rgba(61, 139, 140, 0.1);
	--primaryHeader-newFloButton-font-weignt: var(--global-medium-font-weight);
	--primaryHeader-newFloButton-font-family: var(--global-font-family);
	--primaryHeader-newFloButton-font-size: var(--global-base1-fontSize);
	--primaryHeader-newFloButton-border-radius: var(--item-radius-8);
	/* RESPONSIVE VARIABLES */
	--primaryHeader-newFloButton-padding-tablet: 0;
	--primaryHeader-newFloButton-margin-tablet: 0;
	--primaryHeader-newFloButton-height-tablet: 36px;
	--primaryHeader-newFloButton-width-tablet: 36px;
	--primaryHeader-leftGridContainer-padding-right-tablet: 10px;
	--primaryHeader-floNameContainer-padding-left-mobile: 0;
	--primaryHeader-floNameContainer-padding-left-tablet: 20px;

	--primaryHeader-buttonText-padding: 0;
	--primaryHeader-buttonText-font-family: var(--global-font-family);
	--primaryHeader-buttonText-font-weight: var(--global-bold-font-subtext);
	--primaryHeader-buttonText-font-size: var(--global-base1-fontSize);
	--primaryHeader-buttonText-margin-left: 4px;

	--primaryHeader-floNameContainer-flow-direction: column;
	--primaryHeader-floNameEditor-max-width: 70vw;

	--primaryHeader-floCreatedByInfo-font-weight: 400;
	--primaryHeader-floCreatedByInfo-font-size: var(--global-base-fontSize);
	--primaryHeader-floCreatedByInfo-font-family: var(--global-font-family);
	--primaryHeader-floCreatedByInfo-opacity: 0.6;
	--primaryHeader-floCreatedByInfo-white-space: nowrap;
	--primaryHeader-floCreatedByInfo-padding: 0 4px 0 0;
	--primaryHeader-floCreatedByInfo-max-width: 150px;

	--primaryHeader-900w-floNameContainer-flow-direction: column;
	--primaryHeader-900w-floNameContainer-align-items: baseline;
	--primaryHeader-nameEditor-height: 32px;
	--primaryHeader-900w-nameEditor-margin-top: -8px;
	--primaryHeader-900w-nameEditor-max-width: 50vw;
	--primaryHeader-900w-floCreatedByInfo-max-width: 80px;

	--primaryHeader-600w-floNameContainer-max-width: 15vw;
	--primaryHeader-600w-floCreatedByInfo-max-width: 26vw;

	--primaryHeader-floSpaceSwitcher-font-family: var(--global-font-family);
	--primaryHeader-floSpaceSwitcher-font-weight: var(--global-base-font-weight);
	--primaryHeader-floSpaceSwitcher-font-size: var(--global-base-fontSize);
	--primaryHeader-floSpaceSwitcher-line-height: 18px;
	--primaryHeader-floSpaceSwitcher-text-color: var(--global-primaryTextColor);
	--primaryHeader-floSpaceSwitcher-opacity: 0.6;

	--primaryHeader-floName-font-family: var(--global-font-family);
	--primaryHeader-floName-padding-left: 0;
	--primaryHeader-floName-font-weight: var(--global-title-font-weight);
	--primaryHeader-floName-font-size: var(--global-base-fontSize);
	--primaryHeader-floName-line-height: 20px;

	--primaryHeader-loginButton-font-family: var(--global-font-family);
	--primaryHeader-loginButton-border: 1px solid var(--global-themeColor);
	--primaryHeader-loginButton-color: var(--global-themeColor);
	--primaryHeader-loginButton-letter-spacing: 2px;
	--primaryHeader-loginButton-margin: 6px 4px;
	--primaryHeader-loginButton-font-weight: var(--global-medium-font-weight);
	--primaryHeader-loginButton-font-size: var(--local-button-fontSize);
	--primaryHeader-loginButton-border-radius: var(--item-radius-8);
	--primaryHeader-loginButton-text-color: #fff;
	--primaryHeader-loginButton-width: 71px;
	--primaryHeader-loginButton-height: 32px;
	/* RESPONSIVE VARIABLES */
	--primaryHeader-loginButton-margin-tablet: 0 0 0 10px;

	--primaryHeader-avatar-margin: 0 8px;

	--primaryHeader-menu-background: var(--global-primaryBackground);
	--primaryHeader-menu-hover-background: var(--global-dropdown-hover-background-color);
	--primaryHeader-menu-text-color: var(--global-secondaryTextColor);

	--primaryHeader-menuIcon-text-color: var(--global-secondaryTextColor);
	--primaryHeader-floNameEditor-minWidth: 200px;
	--primaryHeader-floNameEditor-maxWidth: calc(50% - 150px);
	--primaryHeader-themeIcon-width: 15px;
	--primaryHeader-themeIcon-height: 15px;
}

/* :: end of PrimaryHeader :: */

/* :: start of Annotation :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--annotationTools-bottom: 15px;
	--annotationTools-background: rgba(59, 55, 55, 0.59);
	--annotationTools-border-radius: var(--global-border-radius);

	--annotationTools-button-font-size: calc(var(--global-size) * 3);
	--annotationTools-button-font-weight: var(--global-medium-font-weight);
	--annotationTools-button-height: calc(var(--global-size-md) * 5);
	--annotationTools-button-width: calc(var(--global-size-md) * 5);
	--annotationTools-button-text-color: var(--global-primaryTextColor);

	--annotation-canvas-top: 25px;
}

/* :: end of Annotation :: */

/* :: start of Dialog :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--dialog-title-font-weight: var(--global-title-font-weight);
	--dialog-title-font-size: 22px;
	--dialog-title-line-height: 30px;
	--dialog-title-padding-bottom: 16px;
	--dialog-title-padding-right: 24px;
	--dialog-closeButton-margin-top: 6px;
	--dialog-title-text-color: var(--global-primaryTextColor);
	--dialog-message-text-color: var(--global-secondaryTextColor);
	--dialog-closeIcon-text-color: var(--global-primaryTextColor);
	--dialog-min-width: 400px;
	--dialog-border-radius: var(--item-radius-8);
	--dialog-variant-small-max-width: 400px;
	--dialog-background: var(--global-primaryBackground);
	--dialog-padding: 32px 32px 28px 32px;
	--dialog-dialogMessage: 0;
	--dialog-input-error-border-color: #e59c9e;
	/* RESPONSIVE VARIABLES */
	--dialog-closeButton-margin-top-tablet: 0;
	--dialog-closeButton-top-tablet: 6px;
	--dialog-closeButton-right-tablet: 6px;
	--dialog-dialogRootContainer-padding-tablet: 30px 20px;

	--dialog-deleteButton-background: var(--global-amberColor);
	--dialog-deleteButton-text-color: #ffffff;

	--dialog-deleteButton-hover-opacity: 0.8;
	--dialog-action-padding: 24px 0 0 0;
	--dialog-confirmButton-font-weight: var(--global-title-font-weight);
	--dialog-saveButton-background: var(--global-themeColor);
	--dialog-saveButton-border-color: var(--global-themeColor);
	--dialog-saveButton-margin: 16px;
	--dialog-button-font-family: var(--global-font-family);
	--dialog-skipButton-hover-opacity: 0.9;
	--dialog-button-border-radius: calc(var(--global-border-radius) * 2);

	--dialog-with-image-contentTitle-line-height: 28px;
	--dialog-with-image-contentTitle-padding-top: 40px;
	--dialog-with-image-contentTitle-font-family: var(--global-font-family);
	--dialog-with-image-contentTitle-font-weight: var(--dialog-title-font-weight);
	--dialog-with-image-contentTitle-font-size: 18px;

	--dialog-with-image-contentText-font-family: var(--global-font-family);
	--dialog-with-image-contentText-color: var(--color-grey-700);
	--dialog-with-image-contentText-font-style: normal;
	--dialog-with-image-contentText-font-weight: 400;
	--dialog-with-image-contentText-font-size: var(--global-base-fontSize);
	--dialog-with-image-contentText-line-height: 20px;
	--dialog-with-image-contentText-opacity: 0.6;
	--dialog-with-image-contentText-margin-bottom: 4px;
	--dialog-with-image-contentText-padding-top: 8px;

	--dialog-with-image-and-text-min-height: 100px;
	--dialog-with-image-and-text-max-height: 554px;
	--dialog-with-image-and-text-max-width: 400px;
	--dialog-with-image-and-text-height: 250px;
	--empty-flo-svg-illustration-height: 400;
	--empty-flo-svg-illustration-width: 400;
	--dialog-footer-button-font-size: var(--global-base-fontSize);
}

/* :: end of Dialog :: */

/* :: start of Share flo body :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--shareFloBody-font-weight: var(--global-base-font-weight);
	--shareFloBody-font-size: var(--global-base-fontSize);
	--shareFloBody-color: var(--global-primaryTextColor);

	--shareFloBody-linkContainer-padding: 8px 12px;
	--shareFloBody-linkContainer-border-radius: var(--global-border-radius);
	--shareFloBody-linkContainer-margin: 0 0 12px 0;
	--shareFloBody-linkContainer-background: var(
		--global-shareFloBody-linkContainer-background
	);

	--shareFloBody-link-max-width: 300px;
	--shareFloBody-link-font-weight: var(--global-base-font-weight);
	--shareFloBody-link-font-size: var(--global-base-fontSize);
	--shareFloBody-link-line-height: 14px;
	--shareFloBody-link-text-color: var(--global-secondaryTextColor);
	--shareFloBody-link-opacity: 0.6;
	--shareFloBody-link-subText-font-style: normal;

	--shareFloBody-copyButton-font-weight: var(--global-title-font-weight);
	--shareFloBody-copyButton-font-size: var(--global-base-fontSize);
	--shareFloBody-copyButton-line-height: 14px;
	--shareFloBody-copyButton-color: var(--color-dark-primary);
	--shareFloBody-copyButton-weight: 150px;
	--shareFloBody-sharing-container-padding-top: 24px;
	--shareFloBody-copiedButton-color: var(--global-menuIcon-active-text-color);
	--shareFloBody-copyButton-padding: 6px 0 6px 16px;
	/*	RESPONSIVE VARIABLES */
	--shareFloBody-copyButton-width-tablet: 150px;
	--shareFloBody-privateToggle-padding-bottom: 24px;
	--shareFloBody-collaboratorToggle-padding-bottom: 12px;
	--shareFloBody-switchToggleLabel-font-weight: var(--global-title-font-weight);
	--shareFloBody-switchToggleLabel-font-size: var(--global-base1-fontSize);
	--shareFloBody-switchToggleLabel-color: var(--global-primaryTextColor);
	--shareFloBody-switchToggleLabel-opacity: 0.8;
	--shareFloBody-switchToggleLabel-line-height: 17px;
	--shareFloBody-switchToggleLabel-font-style: normal;
	--shareFloBody-collaboratorUserName-margin: 0;
	--shareFloBody-collaboratorUserName-font-style: normal;
	--shareFloBody-collaboratorUserName-font-weight: var(--global-title-font-weight);
	--shareFloBody-collaboratorUserName-font-size: var(--global-base-fontSize);
	--shareFloBody-collaboratorUserName-line-height: 14px;
	--shareFloBody-collaboratorUserName-color: var(
		--global-shareFloBody-collaboratorUserName-color
	);
	--shareFloBody-collaboratorEmail-margin: 0;
	--shareFloBody-collaboratorEmail-font-style: normal;
	--shareFloBody-collaboratorEmail-font-weight: 400;
	--shareFloBody-collaboratorEmail-font-size: 10px;
	--shareFloBody-collaboratorEmail-line-height: 12px;
	--shareFloBody-collaboratorEmail-color: var(
		--global-shareFloBody-collaboratorUserName-color
	);
	--shareFloBody-collaboratorEmail-opacity: 0.6;
	--shareFloBody-collaboratorsList-margin: 0 0 24px 0;
	--shareFloBody-collaboratorsList-max-height: 300px;
	--shareFloBody-collaboratorsList-overflow-y: auto;
	--shareFloBody-collaborator-padding-top: 12px;
	--shareFloBody-publicToggle-padding-bottom: 11px;
	--shareFloBody-collaboratorRemoveButton-padding: 0 5px;
	--shareFloBody-collaboratorRemoveIcon-color: var(--global-primaryTextColor);
	--shareFloBody-sneakLoader-fill-color: #ffffff;
}

/* :: end of Share flo body :: */

/* :: start of switch :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--switch-padding-bottom: 16px;
	--switch-element-padding: 0px;
	--switch-element-width: 45px;

	--switch-label-padding-top: 1px;
	--switch-label-color: var(--global-primaryTextColor);
	--switch-label-opacity: 0.8;
	--switch-label-font-family: var(--global-base-fontSize);
	--switch-label-font-weight: var(--local-base-font-weight);
	--switch-label-font-size: var(--global-base-fontSize);
	--switch-label-line-height: 15px;
	--switch-label-margin: 0;

	--switch-base-top: -7px;
	--switch-base-left: -7px;
	--switch-base-hover-background: transparent;

	--switch-checked-text-color: var(--color-white);
	--switch-checked-left: -12px;

	--switch-track-background: var(--global-switch-track-background);
	--switch-track-height: 20px;
	--switch-track-width: 36px;
	--switch-track-check-background: var(--color-dark-primary);
	--switch-track-check-opacity: 1;
	--switch-thumb-height: 16px;
	--switch-thumb-width: 16px;
	--switch-icon-margin-right: 10px;
	--switch-icon-margin-top: -5px;
}

/* :: end of switch :: */

/* :: start of videoPlayer :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--videoPlayer-background: #071925;
	--videoPlayer-border-radius: calc(var(--global-border-radius) * 2);
	--videoPlayer-top-right-border-radius: calc(var(--global-border-radius) * 2);
	--videoPlayer-top-left-border-radius: calc(var(--global-border-radius) * 2);

	--videoPlayer-bigbuttonPlay-top: 45%;
	--videoPlayer-bigbuttonPlay-left: 45%;

	--videoPlayer-controls-bar-height: 108px;
	--videoPlayer-controls-bar-min-width: 100%;
	--videoPlayer-controls-bar-display: flex;
	--videoPlayer-controls-bar-bottom: -108px;
	--videoPlayer-controls-bar-background: var(
		--brand-color-videoPlayer-controls-bar-background
	);
	--videoPlayer-controls-bar-border-left: 1px solid
		var(--brand-color-videoPlayer-controls-bar-border-left);
	--videoPlayer-controls-bar-border-right: 1px solid
		var(--brand-color-videoPlayer-controls-bar-border-left);
	--videoPlayer-controls-bar-border-bottom: 1px solid
		var(--brand-color-videoPlayer-controls-bar-border-left);
	--videoPlayer-controls-bar-padding: 63px 0 0 8px;
	--videoPlayer-controls-bar-box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
		0px 1px 2px rgba(16, 24, 40, 0.06);
	--videoPlayer-controls-bar-border-bottom-left-radius: calc(
		var(--global-border-radius) * 2
	);
	--videoPlayer-controls-bar-border-bottom-right-radius: calc(
		var(--global-border-radius) * 2
	);
	/* RESPONSIVE VARIABLES */
	--videoPlayer-controls-bar-padding-tablet: 40px 12px 0;
	--videoPlayer-controls-bar-height-tablet: 85px;

	--videoPlayer-play-control-background-color: transparent;
	--videoPlayer-play-control-border-radius: 12px;
	--videoPlayer-play-control-height: 20px;
	--videoPlayer-play-control-width: 20px;
	--videoPlayer-play-control-margin-right: 22.5px;
	/* RESPONSIVE VARIABLES */
	--videoPlayer-play-control-margin-tablet: 8px 12px 0 0;
	--videoPlayer-volume-panel-vertical-width-tablet: 30px;

	--videoPlayer-playIcon-controlPlaceholder-top: 3px;
	--videoPlayer-volume-panel-color: var(--brand-color-videoPlayer-volume-panel-color);
	--videoPlayer-volume-panel-height: 16px;
	--videoPlayer-volume-panel-width: 45px;
	--videoPlayer-volume-panel-margin: 8px 0;

	--videoPlayer-volume-panel-border-right: 1px solid var(--rowBorder);
	--videoPlayer-volume-panel-padding: 0;

	--videoPlayer-mute-control-margin-left: 10px;
	--videoPlayer-mute-control-padding: 10px 0;
	--videoPlayer-videoVolumeUrl: var(--global-videoPlayer-videoVolumeUrl);
	--videoPlayer-play-control-play-icon-image: var(
		--globel-videoPlayer-control-play-icon-image
	);
	--videoPlayer-play-control-pause-icon-image: var(
		--globel-videoPlayer-control-pause-icon-image
	);
	--videoPlayer-mute-control: 20px;
	--videoPlayer-videoLowVolumeUrl: var(--global-videoPlayer-videoLowVolumeUrl);
	--videoPlayer-mute-background-size: 20px;
	--videoPlayer-videoMuteVolumeUrl: var(--global-videoPlayer-videoMuteVolumeUrl);
	--videoPlayer-videoFullScreenUrl: var(--global-videoPlayer-videoFullScreenUrl);
	--videoPlayer-volume-panel-margin-left: -1.5rem;
	--videoPlayer-progress-control-width: calc(100% - 16px);
	--videoPlayer-progress-control-top: 38px;
	--videoPlayer-progress-control-height: 12px;
	--videoPlayer-progress-control-holder-background: var(
		--global-videoPlayer-progress-control-holder-background
	);
	--videoPlayer-progress-control-holder-height: 15px;
	--videoPlayer-progress-control-holder-margin: 0;
	--videoPlayer-progress-bar-background: rgba(17, 17, 17, 0.05);
	--videoPlayer-progress-control-play-progress-background: var(
		--brand-color-videoPlayer-progress-control-play-progress-background
	);
	--videoPlayer-progress-control-border-radius: var(--global-border-radius);
	/* RESPONSIVE VARIABLES */
	--videoPlayer-progress-control-top-tablet: 15px;
	--videoPlayer-mute-control-width-tablet: 100%;
	--videoPlayer-mute-control-margin-tablet: 0 0 0 5px;

	--videoPlayer-videoTrack-width: 170px;
	--videoPlayer-videoTrack-padding-top: 0;
	--videoPlayer-videoTrack-background-color: transparent;
	--videoPlayer-videoTrack-margin-left: 1rem;
	--videoPlayer-videoTrack-border: 1px solid var(--global-borderColor);
	--videoPlayer-videoTrack-border-radius: calc(var(--global-border-radius) * 2);
	--videoPlayer-videoTrack-background: #071925;
	--videoPlayer-videoTrack-left: 0;
	--videoPlayer-videoTrack-top: 0;

	--videoPlayer-time-divider-width: 10px;
	--videoPlayer-time-divider-padding: 2px 0 0 8px;
	--videoPlayer-time-divider-bottom: 11px;

	--videoPlayer-duration-top: -9px;
	--videoPlayer-duration-left: -9px;
	--videoPlayer-duration-font-family: var(--global-font-family);
	--videoPlayer-duration-color: var(--global-primaryTextColor);
	--videoPlayer-duration-opacity: 0.6;
	--videoPlayer-duration-font-weight: 400;
	--videoPlayer-duration-font-size: 14px;

	--videoPlayer-qualitySelector-color: var(--global-primaryTextColor);

	--videoPlayer-menu-button-popup-height: 20px;
	--videoPlayer-menu-button-popup-margin: 6px 0;
	--videoPlayer-menu-button-popup-font-weight: 500;
	--videoPlayer-menu-button-popup-background-color: var(
		--local-videoPlayer-menu-button-popup-background-color
	);
	--videoPlayer-menu-button-popup-color: var(--global-primaryTextColor);
	--videoPlayer-menu-button-popup-border: var(
		--local-videoPlayer-menu-button-popup-border
	);
	--videoPlayer-menu-button-popup-border-radius: var(--global-border-radius);

	--videoPlayer-menu-content-background-color: var(
		--local-videoPlayer-menu-content-background-color
	);
	--videoPlayer-menu-content-bottom: 1.5em;
	--videoPlayer-menu-content-max-height: 15em;
	--videoPlayer-menu-content-border: 1px solid
		var(--local-videoPlayer-menu-content-border-color);
	--videoPlayer-menu-content-border-radius: 4px;
	--videoPlayer-menu-hover-background: var(--global-dropdown-hover-background-color);

	--videoPlayer-menu-title-font-weight: var(--global-bold-font-subtext);
	--videoPlayer-menu-title-padding: 4px 16px 0;

	--videoPlayer-menu-selected-background: var(
		--global-dropdown-selected-background-color
	);
	--videoPlayer-menu-selected-color: var(--global-primaryTextColor);
	--videoPlayer-menu-selected-hover-background: var(--local-videoPlayer-menu);
	--videoPlayer-menu-selected-hover-opacity: 0.8;

	--videoPlayer-thumbnail-transition: transform 0.1s, opacity 0.2s;
	--videoPlayer-thumbnail-left: -80px;
	--videoPlayer-thumbnail-bottom: 50px;
	--videoPlayer-thumbnail-box-shadow: 0 0 2px rgba(0, 0, 0, 0.34);
	--videoPlayer-thumbnail-height: 90px;
	--videoPlayer-thumbnail-width: 160px;

	/* video player module */
	--videoPlayer-container-height: calc(100% - 80px);
	--videoPlayer-container-top: 200px;
	--videoPlayer-container-left: 100px;
	--videoPlayer-container-padding: 8px;
	--videoPlayer-container-flex-flow: column;
	--videoPlayer-container-border-radius: 4px;
	--videoPlayer-container-color: #ededed;
	--videoPlayer-container-background: #2d2d2d;
	--videoPlayer-container-border: 1px solid #454545;

	--videoPlayer-sendIcon-background: var(--global-themeColor);
	--videoPlayer-sendIcon-padding: 4px;
	--videoPlayer-sendIcon-margin: 4px;
	--videoPlayer-sendIcon-border-radius: 4px;
	--videoPlayer-sendIcon-width: 25px;
	--videoPlayer-sendIcon-height: 25px;
	--videoPlayer-sendIcon-text-align: center;

	--videoPlayer-textArea-border-radius: 4px;
	--videoPlayer-textArea-background: #2d2d2d;
	--videoPlayer-textArea-border: transparent;
	--videoPlayer-textArea-resize: none;
	--videoPlayer-textArea-font-size: var(--global-base-fontSize);
	--videoPlayer-textArea-font-weight: 400;
	--videoPlayer-textArea-outline: transparent;
	--videoPlayer-textArea-color: rgba(255, 255, 255, 0.9);
	--videoPlayer-textArea-max-height: 100px;
	--videoPlayer-textArea-focus-outline: 1px solid transparent;
	--videoPlayer-font-weight: 700;

	--videoPlayer-videoContainer-border-radius: var(--item-radius-8);
	--videoPlayer-videoContainer-height: calc(100% - 8px);

	--videoPlayer-playerAnnotationContainer-height: 13px;
	--videoPlayer-playerAnnotationContainer-bottom: 116px;
	--videoPlayer-playerAmplitudeContainer-bottom: var(
		--videoPlayer-playerAnnotationContainer-bottom
	);
	--videoPlayer-playerAmplitudeContainer-height: 75px;

	--videoPlayer-thumbnail-onAmplitude--bottom: 90px;

	--videoPlayer-annotation-height: 24px;
	--videoPlayer-annotation-width: 24px;
	--videoPlayer-annotation-background: var(--local-annotation-background);
	--videoPlayer-annotation-color: var(--local-annotation-color);
	--videoPlayer-annotation-active-border: 2px solid var(--global-themeColor);
	--videoPlayer-annotation-active-bottom: -12px;
	--videoPlayer-annotation-active-pseudoAfter-border: 2px solid var(--global-themeColor);
	--videoPlayer-annotation-active-pseudoAfter-border-radius: 50%;
	--videoPlayer-active-filter: opacity(0.4);
	--videoPlayer-footer: opacity(0.4);
	--videoPlayer-container-overlay: var(--global-flo-background);
}

/* :: end of videoPlayer :: */

/* :: start of Notification :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--notification-font-family: var(--global-font-family);
	--notification-container-padding: 21px;
	--notification-container-font-weight: var(--global-title-font-weight);
	--notification-container-font-size: var(--global-base-fontSize);
	--notification-container-text-color: var(--global-primaryTextColor);
	--notification-container-background: var(--global-primaryBackground);
	--notification-container-border: 1px solid var(--local-notification-border);
	--notification-container-box-shadow: var(--local-notification-box-shadow);
	--notification-container-border-radius: calc(var(--global-border-radius) * 2);

	--notification-icon-margin-right: 10px;
	--notification-icon-height: 18px;
	--notification-icon-width: 18px;
	--notification-icon-warning-color: #e86f00;
}

/* :: end of Notification :: */

/* :: start of ProfileDialog :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--profileDialog-container-background: var(--global-primaryBackground);
	--profileDialog-contentWraper-flex-direction: row;
	--profileDialog-content-padding: 16px 32px 32px 32px;

	--profileDialog-content-sub-header-padding: 0 0 32px 0;
	--profileDialog-content-sub-header-opacity: 0.6;
	--profileDialog-content-sub-header-font-family: var(--global-font-family);
	--profileDialog-content-sub-header-font-weight: var(--global-base-font-weight);
	--profileDialog-content-sub-header-font-size: 13px;
	--profileDialog-title-font-size: 16px;
	--profileDialog-title-font-family: var(--global-font-family);
	--profileDialog-title-font-weight: var(--global-title-font-weight);
	--profileDialog-title-padding: 32px 0 0 32px;
	/* RESPONSIVE VARIABLES */
	--profileDialog-title-padding-tablet: 34px 20px 0 20px;
	--profileDialog-content-padding-tablet: 16px 20px 20px;
	--profileDialog-contentSubHeader-padding-bottom-tablet: 25px;
	--profileDialog-profilePicContainer-margin-top-tablet: 2px;
	--profileDialog-profilePicContainer-height-tablet: 45px;
	--profileDialog-profilePicContainer-width-tablet: 45px;
	--profileDialog-buttonContainer-padding-bottom-tablet: 34px;

	--profileDialog-contentWraper-min-width: 360px;
	--profileDialog-contentWraper-background: var(--global-primaryBackground);
	--profileDialog-contentWraper-text-color: var(--global-secondaryTextColor);
	--profileDialog-title-text-color: var(--global-primaryTextColor);
	--profileDialog-avatar-height: 56px;
	--profileDialog-avatar-width: 56px;
	--profileDialog-avatar-border-radius: 16px;
	--profileDialog-nameInputContainer-padding: 0 0 16px 0;
	--profileDialog-naameTitle-font-family: var(--global-font-family);
	--profileDialog-naameTitle-font-weight: var(--global-bold-font-subtext);
	--profileDialog-naameTitle-font-size: var(--global-base-fontSize);
	--profileDialog-naameTitle-line-height: 14px;

	--profileDialog-naameInput-padding: 8px 0;
	--profileDialog-input-background: var(--global-container-background-color);
	--profileDialog-input-border: 1px solid rgba(0, 0, 0, 0.2);
	--profileDialog-input-border-radius: var(--global-border-radius);
	--profileDialog-input-padding: calc(var(--global-size) * 0.5);
	--profileDialog-input-font-family: var(--global-font-family);
	--profileDialog-input-font-weight: var(--global-base-font-weight);
	--profileDialog-input-text-color: var(--global-primaryTextColor);
	--profileDialog-input-font-size: var(--global-base-fontSize);
	--profileDialog-input-line-height: 14px;

	--profileDialog-skipButton-font-weight: var(--global-title-font-weight);
	--profileDialog-skipButton-text-color: var(--global-themeColor);
	--profileDialog-skipButton-border-color: var(--global-themeColor);
	--profileDialog-skipButton-hover-opacity: 0.9;

	--profileDialog-saveButton-font-weight: var(--global-title-font-weight);
	--profileDialog-saveButton-background: var(--global-themeColor);
	--profileDialog-saveButton-border-color: var(--local-button-border-color);
	--profileDialog-saveButton-color: var(--global-button-text-color);

	--profileDialog-button-border-radius: calc(var(--global-border-radius) * 2);
	--profileDialog-saveButton-margin: 16px;
	--profileDialog-saveButtonDisabled-opacity: 0.5;

	--profileDialog-button-container-padding: 0;
	--profileDialog-button-font-family: var(--global-font-family);

	--profileDialog-popover-border: 1px solid var(--global-borderColor);
	--profileDialog-popover-box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	--profileDialog-popover-border-radius: calc(var(--global-border-radius) * 2);
	--profileDialog-popover-background: var(--global-primaryBackground);

	--profileDialog-menulist-width: 228px;
	--profileDialog-menulist-height: 110px;
	--profileDialog-menulist-padding: 8px;
	--profileDialog-menulist-grid-template-columns: repeat(4, 44px);
	--profileDialog-menulist-row-gap: 12px;
	--profileDialog-menulist-column-gap: 12px;

	--profileDialog-avatarHeader-font-weight: var(--global-bold-font-subtext);
	--profileDialog-avatarHeader-font-size: var(--local-base-fontSize, 11px);
	--profileDialog-avatarHeader-margin: 12px 16px;
	--profileDialog-avatarHeader-text-color: var(--global-secondaryTextColor);
	--profileDialog-menuItem-border0radius: calc(var(--global-border-radius) * 4);

	--profileDialog-profilePicContainer-width: 40px;
	--profileDialog-profilePicContainer-height: 40px;
	--profileDialog-profilePicContainer-border-radius: calc(
		var(--global-border-radius) * 4
	);

	--profileDialog-avatarPopup-border: 2px solid transparent;
	--profileDialog-avatarPopup-hover-border: 2px solid var(--global-themeColor);

	--profileDialog-container-border-radius: 16px;
	--profileDialog-list-item-background: #f6f6f6;
	--profileDialog-upgrade-button-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	--profileDialog-upgrade-status-text-color: var(--color-orange);
	--profileDialog-upgrade-status-text-background: rgba(224, 135, 1, 0.06);
	--profileDialog-plan-name-font-size: 24px;
}

/* :: end of ProfileDialog :: */

/* :: start of ContentEditable component :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--contentEditable-min-width: calc(var(--global-size-md) * 20);
	--contentEditable-padding: calc(var(--global-size) * 0.5) 0;
	--contentEditable-name-font-weight: var(--global-bold-font-subtext);
	--contentEditable-name-font-size: var(--global-base1-fontSize);
	--contentEditable-name-max-width: calc(100% - 9px);
	--contentEditable-name-padding: 2px 24px 2px 8px;
	--contentEditable-name-onfocus-outline: 1px solid transparent;
	--contentEditable-name-onfocus-border-radius: calc(var(--global-size) * 0.5);
	--contentEditable-editicon-font-size: var(--global-base1-fontSize);
	--contentEditable-editicon-position-top: calc(var(--global-size-md) * 0);
	--contentEditable-editicon-margin: 8px 0 0 -4px;
	--contentEditable-editicon-transform: translateX(-12px);
	--contentEditable-tooltip-z-index: var(--local-tooltip-z-index);
	/* RESPONSIVE VARIABLES */
	--contentEditable-name-font-size-mobile: 14px;
}

/* ::  end of ContentEditable component :: */

/* :: start of ManageFloPage :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--manageFlo-background: var(--global-primaryBackground);
	--manageFlo-content-background: #f6f6f6;
	--manageFlo-overlay-background: var(--global-overlay-background);
	--manageFlo-overlay-boxshadow: inset 0 0 6px 2px var(--darkBackground);
	--manageFlo-overlay-opacity: 60%;
	--manageFlo-list-wrapper-width: calc(var(--global-size-md) * 90);
	--manageFlo-list-wrapper-900-min-width: calc(var(--global-size-md) * 80);
	--manageFlo-list-wrapper-700-min-width: calc(var(--global-size-md) * 60);
	--manageFlo-list-wrapper-600-min-width: calc(var(--global-size-md) * 50);
	--manageFlo-list-wrapper-max-width: calc(var(--global-size-md) * 90);
	--manageFlo-list-wrapper-backgound: var(--local-list-wrapper-background);
	--manageFlo-container-height: calc(100% - 64px);
	--manageFlo-container-margin: calc(var(--global-size) * 3) 0
		calc(var(--global-size) * 3) calc(var(--global-size) * 3);
	--manageFlo-container-togglebutton-font-family: var(--global-font-family);
	--manageFlo-container-togglebutton-font-size: var(--global-base-fontSize);
	--manageFlo-container-togglebutton-font-weight: var(--global-bold-font-subtext);
	--manageFlo-table-margin-top: calc(var(--global-size) * 3);
	--manageFlo-toggle-button-padding: 2px;
	--manageFlo-toggle-button-border: unset;
	--manageFlo-toggle-button-height: 32px;
	--manageFlo-toggleButtonGroup-height: 70%;
	--manageFlo-toggleButtonGroup-border-left: 1px solid var(--global-borderColor);
	--manageFlo-emptyPage-transform: translate(0, -10%);
	/* RESPONSIVE VARIABLES */
	--manageFlo-container-padding-tablet: 20px 14px;

	--manageFlo-floList-height: calc(100% - 110px);
	--manageFlo-floList-margin: 0;
	--manageFlo-floList-padding-right: calc(var(--global-size) * 4);
	--manageFlo-floList-emptySvg-transform: translate(0, -10%);
	--manageFlo-rowItem_box-shadow: var(--local-manageFlo-rowItem_box-shadow);
	--manageFlo-load_more-text-color: var(--local-themeColor);
	--manageFlo-load_more-border-color: var(--local-themeColor);

	--manageFlo-totalCount-font-style: normal;
	--manageFlo-totalCount-font-family: var(--global-font-family);
	--manageFlo-totalCount-text-padding: 16px 16px 16px 0;
	--manageFlo-totalCount-font-weight: var(--local-base-font-weight);
	--manageFlo-totalCount-font-size: var(--local-base-fontSize);
	--manageFlo-totalCount-line-height: var(--local-base1-fontSize);
	--manageFlo-totalCount-color: var(--global-primaryTextColor);
	--manageFlo-totalCount-opacity: 0.6;
	--manageFlo-totalCount-margin-bottom: 0;

	--manageFlo-floRowItem-font-family: var(--global-font-family);
	--manageFlo-floRowItem-margin: var(--global-size-md) 0;
	--manageFlo-first-floRowItem-margin: 0 0 var(--global-size) 0;
	--manageFlo-floRowItem-background: var(--global-floRowItem-background);
	--manageFlo-floRowItem-border-radius: calc(var(--global-border-radius) * 2);
	--manageFlo-floRowItem-border: 1px solid var(--global-borderColor);
	--manageFlo-floRowItem-height: 56px;
	--manageFlo-floRowItem-transition: all 300ms ease-in-out;
	--manageFlo-floRowItem-text-color: var(--global-primaryTextColor);
	--manageFlo-floRowItem-floCol-padding: 0 8px;
	--manageFlo-floRowItem-floCol-margin: 0;
	--manageFlo-floRowItem-image-height: calc(var(--global-size-md) * 4);
	--manageFlo-floRowItem-image-width: calc(var(--global-size-md) * 7);
	--manageFlo-floRowItem-image-border-radius: var(--global-border-radius);
	--manageFlo-floRowItem-divider-background: var(--local-action-background);
	--manageFlo-floRowItem-divider-height: 24px;
	--manageFlo-floRowItem-divider-width: 1px;
	--manageFlo-floRowItem-divider-margin: 0 24px;
	--manageFlo-floRowItem-menuItem-margin: 0 20px;
	--manageFlo-floRowItem-menuItem-padding: 4px;
	--manageFlo-floRowItem-image-border: 1px solid var(--global-border-color2);
	/*--manageFlo-floRowItem-image-background: url('../../Pages/ManageFlos/components/FloRowItem/preview.png')*/
	/*	no-repeat center;*/
	--manageFlo-floRowItem-titleContainer-padding: 0 0 0 calc(var(--global-size) * 1.5);
	--manageFlo-floRowItem-contentEditable-left: calc(var(--global-size) * -1);
	--manageFlo-floRowItem-contentEditable-top: -2px;
	--manageFlo-floRowItem-contentEditable-NoDuration-left: calc(var(--global-size) * -1);
	--manageFlo-floRowItem-contentEditable-NoDuration-top: 3px;
	--manageFlo-floRowItem-contentEditable-nameEditor-padding-right: calc(
		var(--global-size) * 3
	);
	--manageFlo-floRowItem-contentEditable-nameEditor-font-weight: var(
		--global-bold-font-subtext
	);
	--manageFlo-floRowItem-contentEditable-nameEditor-font-style: var(
		--global-base1-fontSize
	);
	--manageFlo-floRowItem-contentEditable-nameEditor-max-width: calc(100% - 9px);

	--manageFlo-floRowItem-contentEditable-editIcon-right: calc(var(--global-size) * -1);
	--manageFlo-floRowItem-contentEditable-editIcon-font-size: var(--global-base1-fontSize);
	--manageFlo-floRowItem-contentEditable-editIcon-top: 5px;
	--manageFlo-floRowItem-container-hover-background: var(
		--global-manageFlo-floRowItem-container-hover-background
	);
	--manageFlo-floRowItem-container-border: 1px solid transparent;
	--manageFlo-floRowItem-container-hover-border: 1px solid var(--global-themeColor);
	/* RESPONSIVE VARIABLES */
	--manageFlo-floRowItem-titleContainer-padding-left-tablet: 6px;

	--manageFlo-floRowItem-duration-bottom: 8px;
	--manageFlo-floRowItem-duration-font-weight: var(--global-base-font-weight);
	--manageFlo-floRowItem-duration-font-size: var(--global-size-md);
	--manageFlo-floRowItem-duration-line-height: 11.9px;
	--manageFlo-floRowItem-duration-opacity: 0.6;
	--manageFlo-floRowItem-duration-svg-width: calc(var(--global-size) * 2);
	--manageFlo-floRowItem-duration-svg-height: calc(var(--global-size) * 2);
	--manageFlo-floRowItem-duration-svg-margin-right: 5px;
	--manageFlo-floRowItem-duration-svg-opacity: 0.6;

	--manageFlo-floRowItem-col2-padding: 0 0 0 calc(var(--global-size) * 0.5);
	--manageFlo-floRowItem-col2-opacity: 0.6;
	--manageFlo-floRowItem-action-background: var(--global-action-background);
	--manageFlo-floRowItem-action-margin: 0 0 0 24px;
	--manageFlo-floRowItem-action-padding: calc(var(--global-size) * 0.5);
	--manageFlo-floRowItem-action-border-radius: calc(var(--global-border-radius) * 2);
	--manageFlo-floRowItem-action-color: var(--local-info-text-color, black);
	--manageFlo-floRowItem-action-line-height: calc(var(--global-size) * 2);
	--manageFlo-floRowItem-column-number-font-weight: var(--global-bold-font-subtext);
	--manageFlo-floRowItem-column-number-opacity: 0.6;
	--manageFlo-floRowItem-column-image-height: calc(var(--global-size) * 2);
	--manageFlo-floRowItem-column-image-width: calc(var(--global-size) * 2);
	--manageFlo-floRowItem-column-doneIcon-color: var(--global-themeColor);
	--manageFlo-floRowItem-column-number-margin: 0 4px;
	--manageFlo-floRowItem-column-chip-margin: 0 24px 0 4px;
	--manageFlo-floRowItem-privacyType-tooltip-z-index: var(--global-tooltip-z-index);
	--manageFlo-floRowItem-commentsCount-tooltip-z-index: var(--global-tooltip-z-index);
	--manageFlo-floRowItem-decisionCount-tooltip-z-index: var(--global-tooltip-z-index);
	--manageFlo-floRowItem-status-tooltip-z-index: var(--global-tooltip-z-index);
	--manageFlo-floRowItem-icon-tooltip-z-index: var(--global-tooltip-z-index);
	--manageFlo-floRowItem-icon-exitflo-margin-left: 20px;
	--manageFlo-floRowItem-author-padding-top: 8px;
	--manageFlo-floRowItem-author-display: inline-block;
	--manageFlo-floRowItem-author-width: 100%;
	--manageFlo-floRowItem-actionColumn-margin-left: 7px;

	--manageFlo-menu-background: var(--global-primaryBackground);
	--manageFlo-menu-color: var(--global-secondaryTextColor);
	--manageFlo-decisionActive-fill: var(--local-decision-icon-color);

	--manageFlo-menuIcon-color: var(--global-secondaryTextColor);
	--manageFlo-menuIcon-active-text-color: var(--global-menuIcon-active-text-color);
	--manageFlo-menuIcon-active-background: var(--global-menuIcon-active-background);
	--manageFlo-menuIcon-padding: 12px;
	--manageFlo-menuIcon-delete-text-color: var(--global-amberColor);

	--manageFlo-menuItem-hover-background: var(--global-hover-background-color);

	--manageFlo-emptypage-imageContainer-padding: 100px;
	--manageFlo-emptypage-header-margin: 12px 0 4px 0;
	--manageFlo-emptypage-header-font-weight: 700;
	--manageFlo-emptypage-header-font-size: 30px;
	--manageFlo-emptypage-header-font-size-mobile: 24px;
	--manageFlo-emptypage-header-padding-mobile: 0 16px;
	--manageFlo-emptypage-header-line-height: 36px;
	--manageFlo-emptypage-header-text-color: var(--global-primaryTextColor);

	--manageFlo-emptypage-subText-margin: 12px 0;
	--manageFlo-emptypage-subText-font-weight: 400;
	--manageFlo-emptypage-subText-font-size: 18px;
	--manageFlo-emptypage-subText-line-height: 22px;
	--manageFlo-emptypage-subText-color: var(--global-secondaryTextColor);

	--manageFlo-emptypage-createFloButton-margin-top: 12px;
	--manageFlo-emptypage-createFloButton-background: var(--global-themeColor);
	--manageFlo-emptypage-createFloButton-color: white;
	--manageFlo-emptypage-createFloButton-font-weight: 500;
	--manageFlo-emptypage-createFloButton-font-size: var(--global-base-fontSize);
	--manageFlo-emptypage-createFloButton-line-height: 20px;
	--manageFlo-emptypage-createFloButton-text-transform: unset;

	--manageFlo-emptypage-createFloButton-hover-opacity: 0.9;

	--manageFlo-emptyFloList-imageContainer-padding: 100px;
	--manageFlo-emptyFloList-emptyInboxHeader-margin: 26px 0 4px 0;
	--manageFlo-emptyFloList-emptyInboxHeader-font-weight: 500;
	--manageFlo-emptyFloList-emptyInboxHeader-font-size: 20px;
	--manageFlo-emptyFloList-emptyInboxHeader-line-height: 24px;
	--manageFlo-emptyFloList-emptyInboxHeader-color: var(--global-primaryTextColor);

	--manageFlo-emptyFloList-createFloButton-margin-top: 12px;
	--manageFlo-emptyFloList-createFloButton-background: var(--global-themeColor);
	--manageFlo-emptyFloList-createFloButton-color: white;
	--manageFlo-emptyFloList-createFloButton-font-weight: 500;
	--manageFlo-emptyFloList-createFloButton-font-size: var(--global-base-fontSize);
	--manageFlo-emptyFloList-createFloButton-line-height: 20px;
	--manageFlo-emptyFloList-createFloButton-text-transform: unset;

	--manageFlo-emptyFloList-createFloButton-hover-opacity: 0.9;

	--manageFlo-showCloseSwitch-display: inline-flex;
	--manageFlo-showCloseSwitch-float: right;
	--manageFlo-showCloseSwitch-margin-right: 16px;

	--manageFlo-showCloseSwitchToggle-flex-direction: row-reverse;

	--manageFlo-showCloseSwitchToggle-font-style: normal;
	--manageFlo-showCloseSwitchToggle-font-weight: var(--local-base-font-weight);
	--manageFlo-showCloseSwitchToggle-font-size: var(--global-base-fontSize);
	--manageFlo-showCloseSwitchToggle-line-height: 14px;
	--manageFlo-showCloseSwitchToggle-color: var(--global-secondaryTextColor);
	--manageFlo-showCloseSwitchToggle-opacity: 0.6;

	--manageFlo-toggleContainer-display: flex;
	--manageFlo-toggleContainer-align-items: baseline;
	--manageFlo-toggleContainer-flex-direction: column;
	--manageFlo-toggleContainer-justify-content: flex-start;
	--chip-disabled-opacity: 0.5;
	--floPage-loader-background: var(--local-loader-background);
}

/* ::  end of ManageFloPage :: */

/* ::  start of FloComments :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--floComment-nocommnet-text-color: var(--global-secondaryTextColor);
	--floComment-nocommnet-font-family: var(--global-font-family);
	--floComment-nocommnet-transform: translateY(-48px);
	--floComment-nocommnet-font-size: 16px;
	--floComment-nocommnet-font-weight: 400;
	--floComment-nocommnet-opacity: 0.6;
	--floComment-nocommnet-padding: 0 48px;
	--floComment-nocommnet-text-align: center;
	--floComment-container-height: calc(100vh - 100px);
	--floComment-container-flex-direction: column;
	--floComment-commentsCount-flex-direction: column;
	--floComment-commentsCount-font-family: var(--global-font-family);
	--floComment-commentsCount-font-weight: var(--local-base-font-weight);
	--floComment-commentsCount-font-size: var(--local-base-fontSize);
	--floComment-commentsCount-line-height: var(--local-base1-fontSize);
	--floComment-commentsCount-color: var(--global-primaryTextColor);
	--floComment-commentsCount-opacity: 0.6;
	--floComment-commentsCount-padding: 16px 16px 8px 16px;
	--floComment-commentsCount-margin-bottom: 0;
	--floComment-message-padding: 0px 8px;
	--floComment-message-height: 100%;
	--floComment-sendMessageContainer-padding: 16px;
	--floComment-sendMessageContainer-background-color: var(--global-primaryBackground);
	--floComment-sendMessageContainer-box-shadow: 0px -4px 8px -2px
		var(--local-sendMessageContainer-box-shadow-color);
	--floComment-loadingMore-width: 16px;
	--floComment-loadingMore-height: 16px;
	--floComment-loadingMore-margin-right: 10px;
	--floComment-loadingMore-color: var(--global-themeColor);
	/* RESPONSIVE VARIABLES */
	--floComment-container-height-tablet: calc(100% - 32px);
}

/* ::  end of FloComments :: */

/* ::  start of FloComments :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--message-margin-bottom: 6px;
	--message-padding: 8px;
	--message-default-border: 2px solid transparent;
	--message-reply-padding-left: 6px;
	--message-date-font-family: var(--global-font-family);
	--message-date-font-weight: 400;
	--message-date-font-size: 10px;
	--message-date-color: var(--global-secondaryTextColor);
	--message-date-opacity: 0.6;

	--message-time-margin-left: 8px;
	--message-time-font-family: var(--global-font-family);
	--message-time-font-weight: 400;
	--message-time-font-size: 10px;
	--message-time-line-height: 23px;
	--message-time-color: var(--global-secondaryTextColor);
	--message-time-opacity: 0.8;
	--message-time-margin-bottom: 0;
	--message-time-svg-margin: 0 4px 0 0;
	--message-time-svg-padding: 0 0 2px 0;
	--message-time-messageHeaderRight-padding-top: 5px;
	--message-time-messageHeaderRight-cursor: pointer;

	--message-actionContainer-width: 16px;
	--message-actionContainer-height: 16px;
	--message-actionContainer-margin-right: 8px;
	--message-actionContainer-margin-left: 4px;
	--message-actionCheck-color: var(--local-actionCheck-svg-fill);
	--message-actionCheck-fill: var(--local-actionCheck-svg-fill);
	--message-decision-fill: var(--local-decision-svg-fill);
	--message-actionCheck-tick-color: var(--local-primaryBackground);
	--message-decisionActive-fill: var(--local-decision-icon-color);
	--message-resolvePath-color: #ffffffff;
	--message-resolveActive-color: var(--global-themeColor);
	--message-actionContainer-hover-opacity: 0.6;

	--message-messageHeader-info-margin: -5px 0 0 0;
	--message-messageHeader-font-family: var(--global-font-family);
	--message-messageHeader-font-size: var(--global-base1-fontSize);
	--message-messageHeader-font-weight: var(--global-bold-font-subtext);
	--message-messageHeader-text-align: left;
	--message-messageHeader-color: var(--global-secondaryTextColor);
	--message-messageHeader-opacity: 0.9;
	--message-messageHeader-margin-left: 8px;
	--message-messageHeader-margin-bottom: 0px;
	--message-messageHeader-text-overflow: ellipsis;
	--message-messageHeader-max-width: 60%;
	--message-messageHeader-white-space: nowrap;

	--message-messageCopy-font-family: var(--global-font-family);
	--message-messageCopy-font-size: var(--global-base-fontSize);
	--message-messageCopy-font-weight: var(--global-base-font-weight);
	--message-messageCopy-color: var(--global-secondaryTextColor);
	--message-messageCopy-opacity: 0.7;
	--message-messageCopy-margin-left: 8px;
	--message-messageCopy-margin-bottom: 5px;
	--message-messageCopy-width: 90%;
	--message-messageCopy-word-break: break-word;
	--message-messageCopy-cursor: pointer;

	--message-messageHeaderContainer-cursor: pointer;

	--message-activeMessageContainer-background-color: rgba(61, 139, 140, 0.06);
	--message-activeMessageContainer-border: 2px solid rgba(61, 139, 140, 0.8);
	--message-activeMessageContainer-border-radius: var(--item-radius-8);

	--message-toggle-replyContainer-padding-bottom: 0px;

	--message-toggleRepliesIcon-text-align: center;
	--message-toggleRepliesIcon-background-color: #f8f8f8;
	--message-toggleRepliesIcon-padding: 2px 10px;
	--message-toggleRepliesIcon-width: fit-content;
	--message-toggleRepliesIcon-border-radius: 5px;
	--message-toggleRepliesIcon-height: 30px;
	--message-toggleRepliesIcon-min-width: unset;

	--message-toggleRepliesIcon-image-width: 16px;
	--message-toggleRepliesIcon-image-height: 16px;
	--message-toggleRepliesIcon-image-z-index: 1;
	--message-toggleRepliesIcon-image-opacity: 0.6;

	--message-showHideReplies-font-size: var(--global-base-fontSize);
	--message-showHideReplies-font-weight: 500;
	--message-showHideReplies-margin-bottom: 8px;
	--message-showHideReplies-padding: 4px 10px;
	--message-showHideReplies-color: var(--local-message-showHideReplies-color);
	--message-headerBorder-width: 28px;
	--message-borderBody-border-color: var(--global-message-borderBody-border-color);
	--message-borderBody-height: 40px;
	--message-replyHeaderBorderContainer-width: 30px;
	--message-replyLastBorderBody-border-right: 1px solid #e7e7e7;
	--message-replyLastBorderBody-left: 13.5px;
	--message-replyLastBorderBody-height: 18px;

	--message-replySideBorder-border-bottom: 1px solid var(--floBorderBody);
	--message-replySideBorder-height: 12px;
	--message-replySideBorder-width: 30px;
	--message-replySideBorder-border-radius: 10px;
	--message-replySideBorder-position: relative;
	--message-replySideBorder-left: 11px;
	--message-replySideBorder-top: 8px;

	--message-replyContainer-margin-top: 10px;
	--message-sendContainer-padding-left: 6px;
	--message-sendContainer-margin-left: 10px;
	--message-replyContainer-max-height: 300px;

	--message-loadingmore-replyHEader-width: 45%;

	--message-loadingIcon-width: 16px;
	--message-loadingIcon-height: 16px;
	--message-onediting-highlight-box-shadow: 0 0 0 2px var(--global-themeColor);
}

/* ::  end of FloComments :: */

/* ::  start of SendMessage :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--sendMessage-sendButton-background: var(--global-themeColor);
	--sendMessage-sendButton-padding: 4px;
	--sendMessage-sendButton-margin: 8px 8px 2px 8px;
	--sendMessage-sendButton-border-radius: 4px;
	--sendMessage-sendButton-width: 25px;
	--sendMessage-sendButton-height: 25px;
	--sendMessage-sendButton-text-align: center;
	--sendMessage-sendButton-bottom: 7px;
	--sendMessage-sendButton-right: 0;
	--sendMessage-sendButton-editing-margin: 0 0 0 4px;

	--sendMessage-sendContainer-height: 40px;
	--sendMessage-sendContainer-box-shadow: none;
	--sendMessage-sendContainer-background-color: var(--local-primaryBackground);
	--sendMessage-sendContainer-border: var(--local-comment-container-border);
	--sendMessage-sendContainer-border-radius: 6px;
	--sendMessage-sendContainer-margin: 0 0 0 8px;

	--sendMessage-container-grid-template-columns: 32px calc(100% - 28px - 15px);
	--sendMessage-container-grid-editing-template-columns: calc(100% - 28px - 15px);
	--sendMessage-container-grid-gap: 7px;
	--sendMessage-container-background: transparent;

	--sendMessage-avatar-height: 28px;
	--sendMessage-avatar-width: 28px;

	--sendMessage-input-text-color: var(--global-primaryTextColor);
	--sendMessage-placeholder-fill-color: var(--global-sendMessage-placeholder-fill-color);
	--sendMessage-input-width: calc(100% - 36px);
	--sendMessage-editing-input-width: 100%;
	--sendMessage-disable: 0.4;
}

/* ::  end of SendMessage :: */

/* ::  Button :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--Button-color: var(--global-button-text-color);
	--Button-margin-bottom: 0;
	--Button-min-width: unset;
	--Button-margin-right: 16px;
	--Button-padding: 4px;
	--Button-height: 28px;
	--Button-width: 28px;
	--Button-contained-background: var(--global-themeColor);
	--Button-contained-border: 1px solid var(--global-themeColor);
	--Button-outlined-border: 1px solid var(--local-button-border-color);
}
/* :: end of Button :: */

/* ::  send of Settings :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--settings-min-width: 500px;

	--settings-title-font-family: var(--global-font-family);
	--settings-title-font-weight: var(--local-bold-font-subtext);
	--settings-title-font-size: 20px;
	--settings-title-line-height: 24px;
	--settings-title-color: var(--global-secondaryTextColor);
	--settings-title-margin-bottom: 32px;

	--settings-statusHeading-font-family: var(--global-font-family);
	--settings-statusHeading-font-weight: var(--local-title-font-weight);
	--settings-statusHeading-font-size: var(--global-base-fontSize);
	--settings-statusHeading-line-height: 17px;
	--settings-statusHeading-color: var(--global-primaryTextColor);
	--settings-statusHeading-margin-bottom: 16px;
	--settings-collaboratorHeading-margin-bottom: 24px;

	--settings-emailInput-margin-top: 8px;
	--settings-emailInput-height: 32px;
	--settings-emailInput-border: var(--local-emailInput-border);
	--settings-emailInput-grid-template-columns: calc(100% - (90px + 16px)) calc(90px);
	--settings-sendEmail-width: 90px;
	--settings-sendEmail-padding: 6px 0;
	--settings-sendEmail-font-weight: var(--global-title-font-weight);
	--settings-sendEmail-background: var(--global-themeColor);
	--settings-sendEmail-border-color: var(--global-themeColor);
	--settings-sendEmail-color: var(--local-button-text-color);
	--settings-sendEmail-font-family: var(--global-font-family);
}

/* ::  end of Settings :: */

/* ::  start of floPage :: */
:root [theme='dark'],
:root [theme='light'] {
	--floPage-container-background: var(--global-flo-background);
	--floPage-container-margin: 0 auto;
	--floPage-container-box-shadow: 0px 0px 2px 0px rgba(207, 215, 211, 0.37);
	--floPage-container-height: 100%;
	--floPage-container-padding-top: 0;
	--floPage-gridContainer-padding-top: 0;
	--floPage-gridContainer-padding-right: 16px;
	--floPage-gridContainer-min-height: 400px;
	--floPage-gridContainer-height: calc(100vh - 56px);
	--floPage-rightPanel-width: 355px;
	--floPage-leftPanel-max-width: calc(100% - 357px);

	/* RESPONSIVE VARIABLES */
	--floPage-gridContainer-padding-tablet: 0 16px;
	--floPage-gridContainer-height-tablet: 400px;
	--floPage-gridContainer-padding-right-tablet: 16px;
	--floPage-gridContainer-margin-bottom-tablet: 16px;
	--floPage-rightGridContainer-height-tablet: calc(100% - 420px);

	--floPage-chartsContainer-background-color: var(--global-primaryBackground);
	--floPage-chartsContainer-border: 1px solid
		var(--local-primaryHeader-conatiner-border-color);
	--floPage-chartsContainer-padding-top: 17px;
	--floPage-gridContainer-900px-padding: 0 9px;

	--floPage-toggleButtonGroup-padding: 2px;
	--floPage-toggleButtonGroup-margin: 0px 16px;
	--floPage-toggleButtonGroup-border: none;

	--floPage-toggleButton-font-family: var(--global-font-family);
	--floPage-toggleButton-font-size: var(--global-base-fontSize);
	--floPage-toggleButton-font-weight: var(--global-bold-font-subtext);
	--floPage-toggleButton-border: none;
	--floPage-toggleButton-border-radius: 7px;
	--floPage-toggleButton-padding: 5px;
	--floPage-toggleButton-height: 28px;

	--floPage-utilsContainer-background: transparent;
	--floPage-utilsContainer-padding: 24px 0;
	--floPage-utilsContainer-border-radius: 5px;
	/* RESPONSIVE VARIABLES */
	--floPage-utilsContainer-padding-tablet: 15px 0;

	--floPage-utilsLeft-flex: 9;
	--floPage-videoPlayerContainer-height: calc(100% - 80px);
	--floPage-dotsContainer-margin-right: 0;

	--floPage-utilsControl-margin-bottom: 0;
	--floPage-utilsControl-min-width: unset;
	--floPage-utilsControl-margin-right: 16px;
	--floPage-utilsControl-padding: 4px;
	--floPage-utilsControl-height: 28px;
	--floPage-utilsControl-width: 28px;
	--floPage-utilsSvg-color: var(--global-secondaryTextColor);
	--floPage-utilsSvgActive-color: #ffff;
	--floPage-utilsControlActive-border-radius: calc(var(--global-border-radius) * 2);
	--floPage-utilsControlActive-background: var(--global-themeColor);

	--floPage-noFloDetails-text-align: center;
	--floPage-noFloDetails-top: 40%;
	--floPage-noFloDetails-color: var(--secondaryTextColor);
	--floPage-noFloDetails-background: var(--primaryBackground);
	--floPage-noFloDetails-opacity: 0.6;
	--floPage-noFloDetails-font-size: 20px;

	--floPage-menu-background: var(--global-primaryBackground);
	--floPage-menu-color: var(--global-secondaryTextColor);
	--floPage-menuIcon-color: var(--global-secondaryTextColor);
	--floPage-menuIcon-delete-text-color: var(--global-amberColor);
	/* RESPONSIVE VARIABLES */
	--floPage-menuItem-min-height-color: 48px;

	--floPage-deleteMenuItem-hover-background: var(--global-hover-background-color);
	--floPage-MenuItem-hover-background: var(--global-hover-background-color);

	--floPage-floRowItem-column-chip-margin: 0 20px 0 4px;
	--floPage-floRowItem-column-chip-font-size: var(--global-base-fontSize);
	--floPage-floRowItem-column-chip-font-weight: var(--global-title-font-weight);
	--floPage-floRowItem-column-chip-height: 22px;

	--floPage-errorMessage-top: 8px;
	--floPage-errorMessage-color: var(--global-secondaryTextColor);
	--floPage-errorMessage-height: calc(100% - var(--floPage-errorMessage-top));
	--floPage-errorMessage-background: var(--global-flo-background);
	--floPage-errorMessage-font-weight: 600;
	--floPage-errorMessage-font-size: 18px;

	--floPage-processingContainer-height: 100%;
	--floPage-processingContainer-background-color: var(--global-primaryBackground);

	--floPage-processingTitle-font-style: normal;
	--floPage-processingTitle-font-weight: var(--global-title-font-weight);
	--floPage-processingTitle-font-size: 32px;
	--floPage-processingTitle-color: var(--global-primaryTextColor);
	--floPage-processingTitle-padding-bottom: 24px;
	--floPage-processingTitle-padding-top: 24px;
	--floPage-processingTitle-line-height: 38px;

	--floPage-processingSubTitle-font-style: normal;
	--floPage-processingSubTitle-font-weight: var(--global-base-font-weight);
	--floPage-processingSubTitle-font-size: 16px;
	--floPage-processingSubTitle-color: var(--global-primaryTextColor);
	--floPage-processingSubTitle-opacity: 0.7;
	--floPage-processingSubTitle-padding-bottom: 24px;
	--floPage-processingSubTitle-padding-responsive: 0 24px 24px;
	--floPage-processingSubTitle-text-align: center;
	--floPage-processingSubTitle-line-height: 19px;

	--floPage-statusListLabel-background-color: var(--chip-filled-background);
	--floPage-statusListLabel-color: var(--chip-filled-color);
	--floPage-statusListClosed-background-color: var(--statusChip-closed-background);
	--floPage-statusListClosed-color: var(--statusChip-closed-text-color);
	--floPage-statusListLabel-padding: 3px 12px;
	--floPage-statusListLabel-padding-responsive: 3px;
	--floPage-statusListLabel-margin: 0 20px 0 4px;
	--floPage-statusListLabel-margin-responsive: 0;
	--floPage-statusListLabel-border-radius: 16px;

	--floPage-statusListProcessing-background-color: rgba(224, 135, 1, 0.1);
	--floPage-statusListProcessing-color: #e08701;
}

/* ::  end of floPage :: */

/* ::  start of login :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--signInImageSpotlight: var(--local-signin-image-spotlight-color);
	--signInBottomShadow: var(--local-signin-bottom-shadow-color);

	--login-background: var(--global-primaryBackground);
	--login-header-font-size: 42px;
	--login-header-font-weight: 600;
	--login-header-padding-bottom: 30px;
	--login-header-text-color: #ffff;
	--login-linea-background: #ffff;
	--login-linea-height: 0.1rem;
	--login-linea-width: 40%;
	--login-linea-margin: 1rem 0;

	--login-pageContent-font-family: var(--global-font-family);
	--login-pageContent-flex-direction: column;
	--login-pageContent-background-image: url('../../Pages/Login/images/background.svg');
	--login-pageContent-background-color: var(--global-login-pageContent-background-color);
	--login-pageContent-font-size: 32px;
	--login-pageContent-text-color: #e3efea;
	--login-pageContent-info-font-weight: var(--global-title-font-weight);
	--login-pageContent-info-font-size: 16px;
	--login-pageContent-info-padding: 0 0 10px 0;
}

/* ::  end of login :: */

/* ::  start of AccountSettings :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--accountSettings-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	--brandingDialog-border-color: #d9d9d9;
	--brandingDialog-btn-border-color: #bdbec2;
}
/* ::  end of AccountSettings :: */

/* ::  start of Onboarding QA :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--onboarding-sub-title: var(--global-subtitle-color);
	--onboarding-option-bg: var(--global-selectOption-bg);
	--onboarding-option-border: var(--global-border-color);
	--onboarding-option-bg-active: var(--color-blue-light);
	--onboarding-option-border-active: var(--color-dark-primary);
}
/* ::  end of Onboarding QA :: */

/* ::  start of NewFloContainer :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--newFloPage-background: var(--global-primaryBackground);
	--newFloPage-container-background: transparent;
	--newFloPage-container-top: 0;
	--newFloPage-container-left: 0%;
	--newFloPage-container-height: 100%;
	--newFloPage-container-width: 100%;

	--newFloPage-card-min-width: 960px;
	--newFloPage-card-background: var(--global-primaryBackground);
	--newFloPage-card-border: 1px solid var(--global-newFloPage-card-border-color);
	--newFloPage-card-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
		0px 8px 8px -4px rgba(16, 24, 40, 0.03);
	--newFloPage-card-backdrop-filter: blur(8px);
	--newFloPage-card-border-radius: 16px;
	--newFloPage-card-padding: 16px 24px 24px 24px;
	--newFloPage-dialogRoot-z-index: var(--global-tooltip-z-index);
	/* RESPONSIVE VARIABLES */
	--newFloPage-card-padding-tablet: 30px 20px;
	--newFloPage-cardClose-top-tablet: 6px;
	--newFloPage-cardClose-right-tablet: 6px;

	--newFloPage-cardTitle-font-style: normal;
	--newFloPage-cardTitle-font-weight: 700;
	--newFloPage-cardTitle-font-size: var(--global-heading-fontSize);
	--newFloPage-cardTitle-line-height: 24px;
	--newFloPage-cardTitle-top: 0;
	--newFloPage-cardTitle-height: 24px;
	--newFloPage-cardTitle-color: var(--global-themeColor);
	--newFloPage-cardTitle-padding: 0 0 16px 0;
	--newFloPage-cardTitle-text-transform: capitalize;

	--newFloPage-cardClose-float: right;
	--newFloPage-cardClose-color: var(--global-primaryTextColor);

	--newFloPage-cardSubTitle-font-style: normal;
	--newFloPage-cardSubTitle-font-weight: var(--global-bold-font-subtext);
	--newFloPage-cardSubTitle-font-size: var(--global-base-fontSize);
	--newFloPage-cardSubTitle-line-height: 14px;
	--newFloPage-cardSubTitle-padding: 0 0 8px 0;
	--newFloPage-cardSubTitle-text-transform: capitalize;
	--newFloPage-cardSubTitle-color: var(--global-primaryTextColor);
	--newFloPage-cardSubTitle-opacity: 0.8;

	--newFloPage-cardCountDown-font-size: 100px;
	--newFloPage-cardCountDown-font-weight: var(--global-medium-font-weight);
	--newFloPage-cardCountDown-color: var(--global-newFloPage-cardCountDown-color);
	--newFloPage-cardCountDown-text-align: center;
	--newFloPage-cardCountDown-justify-content: center;
	--newFloPage-cardCountDown-align-items: center;
	--newFloPage-cardCountDown-height: 300px;
	--newFloPage-cardCountDown-z-index: 9999;
	--newFloPage-cardCountDown-line-height: 1.5;

	--newFloPage-cardCountDownSubTitle-opacity: 0.6;
	--newFloPage-cardCountDownSubTitle-padding-bottom: 6px;
	--newFloPage-cardCountDownSubTitle-font-size: var(--global-base-fontSize);
	--newFloPage-cardCountDownSubTitle-font-weight: 400;
	--newFloPage-cardCountDownSubTitle-line-height: 14px;

	--newFloPage-cardCountDownTitle-font-size: 16px;
	--newFloPage-cardCountDownTitle-font-weight: 600;
	--newFloPage-cardCountDownTitle-line-height: 19px;

	--newFloPage-cardRecordingStart-font-size: var(--global-heading-fontSize);
	--newFloPage-cardRecordingStart-font-weight: var(--global-medium-font-weight);
	--newFloPage-cardRecordingStart-color: var(--global-newFloPage-cardCountDown-color);
	--newFloPage-cardRecordingStart-text-align: center;
	--newFloPage-cardRecordingStart-height: 100%;
	--newFloPage-cardRecordingStart-justify-content: center;
	--newFloPage-cardRecordingStart-align-items: center;
	--newFloPage-cardRecordingStart-z-index: 9999;

	--newFloPage-cardBody-grid-template-rows: 50% 50%;
	--newFloPage-cardBody-grid-template-columns: 50% 50%;
	--newFloPage-cardBody-grid-gap: 4px;
	--newFloPage-cardBody-padding: 0 0 24px 0;
	--newFloPage-cardBody-height: calc(100% - 130px);

	--newFloPage-cardRecordingBody-font-size: 24px;
	--newFloPage-cardRecordingBody-font-weight: 500;
	--newFloPage-cardRecordingBody-padding: 40px 20px 76px 20px;
	--newFloPage-cardRecordingBody-color: #949494;
	--newFloPage-cardRecordingBody-text-align: center;
	--newFloPage-cardRecordingBody-elapsed-padding: 16px 0;

	--newFloPage-cardRecordingText-font-style: normal;
	--newFloPage-cardRecordingText-font-size: var(--global-heading-fontSize);
	--newFloPage-cardRecordingText-font-weight: var(--global-bold-font-subtext);
	--newFloPage-cardRecordingText-line-height: 24px;
	--newFloPage-cardRecordingText-color: var(--global-primaryTextColor);
	--newFloPage-cardRecordingText-text-align: center;

	--newFloPage-timeElapsed-padding: 0 0 32px 0;
	--newFloPage-timeElapsed-font-size: var(--global-title-fontSize);
	--newFloPage-timeElapsed-font-weight: var(--global-base-font-weight);
	--newFloPage-timeElapsed-color: var(--global-primaryTextColor);
	--newFloPage-timeElapsed-text-align: center;
	--newFloPage-timeElapsed-line-height: 19px;

	--newFloPage-recordButton-border: unset;
	--newFloPage-recordButton-width: 110px;
	--newFloPage-recordButton-background-color: var(
		--global-newFloPage-recordButton-background-color
	);
	--newFloPage-recordButton-margin-right: 5px;
	--newFloPage-recordButton-color: var(--global-recordButton-color);
	--newFloPage-recordButton-text-transform: none;
	--newFloPage-recordButton-font-weight: var(--global-bold-font-subtext);
	--newFloPage-recordButton-font-style: normal;
	--newFloPage-recordButton-font-size: var(--global-base-fontSize);
	--newFloPage-recordButton-line-height: 20px;
	--newFloPage-recordButton-border-radius: 8px;
	--newFloPage-recordButtonDisabled-opacity: 0.5;

	--newFloPage-stopButton-border: unset;
	--newFloPage-stopButton-width: 110px;
	--newFloPage-stopButton-background-color: var(--global-themeColor);
	--newFloPage-stopButton-color: #ffffff;
	--newFloPage-stopButton-text-transform: none;
	--newFloPage-stopButton-font-weight: var(--global-bold-font-subtext);
	--newFloPage-stopButton-font-style: normal;
	--newFloPage-stopButton-border-radius: 8px;
	--newFloPage-stopButton-font-size: var(--global-base-fontSize);
	--newFloPage-stopButtonDisabled-opacity: 0.5;

	--newFloPage-buttonIcon-text-color: var(--global-primaryTextColor);
	--newFloPage-buttonIcon-padding-right: 6px;
	--newFloPage-buttonIconPath-fill-color: var(--global-primaryTextColor);
	--newFloPage-buttonIcon-font-weight: var(--global-bold-font-subtext);

	--newFloPage-optionText-font-size: var(--global-size-md);
	--newFloPage-optionText-font-weight: var(--global-base-font-weight);
	--newFloPage-optionText-font-style: normal;
	--newFloPage-optionText-line-height: 12px;
	--newFloPage-optionText-opacity: 0.8;
	--newFloPage-optionText-color: var(--global-primaryTextColor);
	--newFloPage-optionText-padding-top: 8px;

	--newFloPage-optionTextActive-font-weight: var(--global-bold-font-subtext);
	--newFloPage-optionTextActive-color: var(--global-newFloPage-optionText-color);
	--newFloPage-optionTextActive-opacity: 1;

	--newFloPage-cardPermissionsTitle-font-size: var(--global-base-fontSize);
	--newFloPage-cardPermissionsTitle-font-weight: var(--local-bold-font-subtext);
	--newFloPage-cardPermissionsTitle-font-style: normal;
	--newFloPage-cardPermissionsTitle-line-height: 14px;
	--newFloPage-cardPermissionsTitle-opacity: var(
		--global-newFloPage-cardPermissionsTitle-opacity
	);
	--newFloPage-cardPermissionsTitle-color: var(
		--global-newFloPage-cardPermissionsTitle-color
	);
	--newFloPage-cardPermissionsTitle-padding: 0px 0 8px 0;

	--newFloPage-optionSelect-svg-background: #ffff;
	--newFloPage-optionSelect-svg-border: #d2d2d2;
	--newFloPage-optionSelect-svg-fill: var(--global-newFloPage-optionSelect-svg-fill);
	--newFloPage-optionSelect-rect-fillpath: var(--global-invertedTextColor);
	--newFloPage-optionSelect-svg-stroke: var(--global-primaryTextColor);
	--newFloPage-optionSelect-svg-opacity: 0.3;
	--newFloPage-optionSelect-width: 110px;
	--newFloPage-optionSelect-height: 70px;
	--newFloPage-optionSelect-background: var(--global-newFloPage-optionSelect-background);
	--newFloPage-optionSelect-border-radius: 8px;
	--newFloPage-optionSelect-flex-flow: column;
	--newFloPage-optionSelect-padding: 12px;
	--newFloPage-optionSelect-border: 2px solid transparent;

	--newFloPage-optionSelectActive-svg-border: var(--global-themeColor);
	--newFloPage-optionSelectActive-svg-fill: var(
		--global-newFloPage-optionSelectActive-svg-fill
	);
	--newFloPage-optionSelectActive-svg-stroke: var(--global-themeColor);
	--newFloPage-optionSelectActive-svg-opacity: 1;
	--newFloPage-optionSelectActive-border: 2px solid var(--global-themeColor);
	--newFloPage-optionSelectActive-background: var(
		--global-newFloPage-optionSelectActive-background
	);

	--newFloPage-optionSelectDisablede-svg-bgColor: #ffffff;
	--newFloPage-optionSelectDisablede-svg-pathFill: #000000;
	--newFloPage-optionSelectDisablede-svg-opacity: 0.3;
	--newFloPage-optionSelectDisablede-svg-border: #d2d2d2;
	--newFloPage-optionSelectDisablede-border: 1px solid #ffffff;
	--newFloPage-optionSelectDisablede-opacity: 0.3;

	--newFloPage-image-height: 36px;
	--newFloPage-image-width: 54px;

	--newFloPage-footer-flex-flow: column;
	--newFloPage-footer-gap: 8px;

	--newFloPage-footer-startButton-hover-background: rgba(66, 111, 83, 1);
	--newFloPage-footer-startButton-background: var(--global-themeColor);
	--newFloPage-footer-startButton-height: 40px;
	--newFloPage-footer-startButton-font-weight: 600;
	--newFloPage-footer-startButton-font-size: 14px;
	--newFloPage-footer-startButton-line-height: 20px;
	--newFloPage-footer-startButton-border-radius: 8px;
	--newFloPage-footer-startButton-margin-top: 8px;
	--newFloPage-footer-startButton-disabled-opacity: 0.5;
	--newFloPage-footer-startButton-color: var(--local-button-text-color);

	--newFloPage-footer-saveButton-background: var(--global-themeColor);
	--newFloPage-footer-saveButton-color: var(--local-button-text-color);

	--newFloPage-nameInput-height: 32px;
	--newFloPage-nameInput-margin: 0 0 16px 0;
	--newFloPage-nameInput-color: var(--global-primaryTextColor);
	--newFloPage-nameInputLabel-opacity: 0.3;
	--newFloPage-nameInputLabel-color: var(--global-primaryTextColor);
	--newFloPage-nameInput-border: 1px solid var(--global-newFloPage-nameInput-border-color);

	--newFloPage-deviceSelectorRow-margin-bottom: 16px;

	--newFloPage-buttonsContainer-flex-flow: column;
	--newFloPage-saveButton-height: 40px;
	--newFloPage-saveButton-font-weight: var(--global-bold-font-subtext);
	--newFloPage-saveButton-font-size: var(--global-base-fontSize);
	--newFloPage-saveButton-line-height: 20px;

	--newFloPage-saveButton-hover-background: var(--global-themeColor);

	--newFloPage-discardButton-padding: 16px 0 0 0;
	--newFloPage-discardButton-color: var(--global-newFloPage-discardButton-color);
	--newFloPage-discardButton-hover-background: transparent;
	--newFloPage-discardButton-font-weight: var(--global-base-font-weight);
	--newFloPage-discardButton-font-size: var(--global-base-fontSize);
	--newFloPage-discardButton-line-height: 20px;
	--newFloPage-discardButtonSvg-margin-right: 5px;
	--newFloPage-discardButton-text-transform: capitalize;

	--newFloPage-clipboardSvg-stroke-color: var(--global-primaryTextColor);
	--newFloPage-clipboardSvg-stroke-color2: var(--global-themeColor);
	--newFloPage-clipboard-margin-bottom: 9px;

	--newFloPage-pausedIcon-margin: 28px;
	--newFloPage-pausedIcon-color: var(--global-themeColor);
	--newFloPage-errorMessage-color: var(--global-amberColor);
	--newFloPage-errorMessage-margin: 0 auto;
	--newFloPage-saving-padding: 16px 0 0;
	--newFloPage-clapContainer-margin: 0 auto;
	--newFloPage-stopIcon-color: var(--global-amberColor);

	--newFloPage-videoPlayback-height: 180px;
	--newFloPage-videoPlayback-width: 250px;
	--newFloPage-videoPlayback-border: 1px solid #ffffff;
	--newFloPage-videoPlayback-border-radius: 4px;
	--newFloPage-videoPlayback-left: 10px;
	--newFloPage-videoPlayback-bottom: 20px;
	--newFloPage-videoPlayback-z-index: 99999;

	--newFloPage-deviceIcon-height: 32px;
	--newFloPage-deviceIcon-width: 32px;
	--newFloPage-deviceIcon-padding: 6px;
	--newFloPage-deviceIcon-background-color: #55916c20;
	--newFloPage-deviceIcon-border-radius: 4px;
	--newFloPage-deviceIcon-background: var(--color-dark-green);
	--newFloPage-deviceIcon-disabled-background: var(--global-amberColor);
	--newFloPage-deviceIcon-disabled-path-stroke: var(--global-amberColor);
	--newFloPage-deviceIcon-path-stroke: var(--color-dark-green);

	--newFloPage-deviceSelectionDropdown-width: 280px;
	--newFloPage-deviceSelectionDropdown-margin: 0 0 0 8px;
	--newFloPage-deviceSelectionDropdown-border: 1px solid
		var(--global-newFloPage-deviceSelectionDropdown-border-color);
	--newFloPage-deviceSelectionDropdown-border-radius: 4px;
	--newFloPage-infinity-color: var(--global-themeColor);
	--newFloPage-dialogWrapper-border-radius: 16px;
	--newFloPage-dialogWrapper-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
		0px 8px 8px -4px rgba(16, 24, 40, 0.03);
	/*--newFloPage-dialogWrapper-width: 280px;*/
	--newFloPage-dialogWrapper-width: 100%;
	--newFloPage-save-dialogWrapper-width: 1000px;

	--newFloPage-processing-font-weight: var(--global-bold-font-subtext);
	--newFloPage-processing-font-size: var(--global-heading-fontSize);
	--newFloPage-processing-font-style: normal;
	--newFloPage-processing-line-height: 24px;
	--newFloPage-processing-text-align: center;
	--newFloPage-processingSubTitle-color: var(--global-primaryTextColor);

	--newFloPage-loadingIconContainer-padding: 20px 0;
	--newFloPage-linearProgress-color: #655be1;

	--newFloPage-processingSubTitle-font-weight: var(--global-base-font-weight);
	--newFloPage-processingSubTitle-font-size: var(--global-base1-fontSize);
	--newFloPage-processingSubTitle-font-style: normal;
	--newFloPage-processingSubTitle-line-height: 17px;
	--newFloPage-processingSubTitle-text-align: center;
	--newFloPage-processingSubTitle-opacity: 0.6;
	--newFloPage-processingSubTitle-color: var(--global-primaryTextColor);

	--newFloPage-tool-button-background: var(--local-tool-button-background);
	--newFloPage-tool-button-color: var(--local-tool-button-color);

	--newFloPage-tool-active-button-background: var(--local-tool-active-button-background);
	--newFloPage-tool-active-button-color: var(--local-tool-active-button-color);
}

/* ::  end of NewFloContainer :: */

/* :: start of 404 :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--Page404-container-background: var(--global-primaryBackground);
	--Page404-container-height: 102%;
	--Page404-container-contentContainer-padding-left: 30px;
	--Page404-container-contentContainer-text-align: left;
	--Page404-container-contentContainer-margin: auto;
	--Page404-image-max-width: 100%;
	--Page404-image-height: 100%;
	--Page404-homeButton-background: var(--global-themeColor);
	--Page404-homeButton-color: var(--global-Page404-homeButton-color);
	--Page404-homeButton-border-radius: 8px;
	--Page404-homeButton-padding: 10px 12px;
	--Page404-homeButton-width: max-content;
	--Page404-homeButton-text-decoration: none;
	--Page404-imageContainer-padding-right: 30px;
	--Page404-imageContainer-margin: auto;
	--Page404-imageContainer-text-align: right;
	--Page404-imageContainer-background: var(--global-themeColor);
	--Page404-imageContainer-path-1-background: var(
		--global-Page404-imageContainer-path-1-background
	);
	--Page404-imageContainer-path-2-3-4-background: var(
		--global-Page404-imageContainer-path-2-3-4-background
	);
	--Page404-contentTitle-color: var(--global-primaryTextColor);
	--Page404-contentTitle-font-family: var(--global-font-family);
	--Page404-contentTitle-font-style: normal;
	--Page404-contentTitle-font-weight: 600;
	--Page404-contentTitle-font-size: 32px;
	--Page404-contentTitle-line-height: 44px;
	--Page404-contentTitle-margin-bottom: 16px;
	--Page404-contentText-color: var(--global-Page404-contentText-color);
	--Page404-contentText-font-family: var(--global-font-family);
	--Page404-contentText-font-style: normal;
	--Page404-contentText-font-weight: 400;
	--Page404-contentText-font-size: 16px;
	--Page404-contentText-line-height: 26px;
	--Page404-contentText-opacity: 0.6;
	--Page404-contentText-margin-bottom: 16px;

	--Page403-button-goToInbox-width: 110px;
	--Page403-button-goToInbox-font-weight: var(--global-title-font-weight);
	--Page403-button-goToInbox-background: var(--global-themeColor);
	--Page403-button-goToInbox-color: var(--color-white);
	--Page403-button-goToInbox-border-color: var(--global-themeColor);
	--Page403-button-goToInbox-font-family: var(--global-font-family);
}
/* :: end of 404 :: */

/* :: start of Adventure CTA body :: */

:root,
:root [theme='dark'],
:root [theme='light'] {
	--adventure-input-font-size: 22px;
	--adventure-select-flo-background: #f5f6fb;
	--adventure-select-flo-button-border-color: #f5f6fb;
	--adventure-flo-list-popup-border: 1px solid rgba(0, 0, 0, 0.08);
	--adventure-flo-list-popup-box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
		0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	--adventure-flo-list-popup-header-font-size: 18px;
	--adventure-select-flo-item-details-color: #545869;
	--adventure-discard-popup-message-color: #545869;
	--adventure-discard-popup-border: 1px solid rgba(0, 0, 0, 0.08);
	--adventure-popup-overlay: rgba(0, 0, 0, 0.5);
	--adventure-discard-popup-button-border: 1px solid var(--color-border);
	--adventure-discard-popup-button-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
	--adventure-discard-popup-box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
		0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

/* :: end of Adventure CTA body :: */

/* :: start of 500 :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--Page500-container-background: var(--global-primaryBackground);
	--Page500-container-height: 102%;
	--Page500-container-contentContainer-padding-left: 30px;
	--Page500-container-contentContainer-text-align: left;
	--Page500-container-contentContainer-margin: auto;
	--Page500-image-max-width: 100%;
	--Page500-image-height: 100%;
	--Page500-homeButton-background: var(--global-themeColor);
	--Page500-homeButton-color: var(--global-Page500-homeButton-color);
	--Page500-homeButton-border-radius: 8px;
	--Page500-homeButton-padding: 10px 12px;
	--Page500-homeButton-width: max-content;
	--Page500-homeButton-text-decoration: none;
	--Page500-imageContainer-padding-right: 30px;
	--Page500-imageContainer-margin: auto;
	--Page500-imageContainer-text-align: right;
	--Page500-imageContainer-background: var(--global-themeColor);
	--Page500-imageContainer-path-1-background: var(
		--global-Page500-imageContainer-path-1-background
	);
	--Page500-imageContainer-path-2-to-8-background: var(
		--global-Page500-imageContainer-path-2-to-8-background
	);
	--Page500-contentTitle-color: var(--global-primaryTextColor);
	--Page500-contentTitle-font-family: var(--global-font-family);
	--Page500-contentTitle-font-style: normal;
	--Page500-contentTitle-font-weight: 600;
	--Page500-contentTitle-font-size: 32px;
	--Page500-contentTitle-line-height: 44px;
	--Page500-contentTitle-margin-bottom: 16px;
	--Page500-contentText-color: var(--global-Page500-contentText-color);
	--Page500-contentText-font-family: var(--global-font-family);
	--Page500-contentText-font-style: normal;
	--Page500-contentText-font-weight: 400;
	--Page500-contentText-font-size: 16px;
	--Page500-contentText-line-height: 26px;
	--Page500-contentText-opacity: 0.6;
	--Page500-contentText-margin-bottom: 16px;
}
/* :: end of 500 :: */

/* :: start of Loader :: */
:root,
:root [theme='dark'],
:root [theme='light'] {
	--loader-infinity-color: var(--global-themeColor);
}
/* :: end of Loader :: */

:root,
:root [theme='dark'],
:root [theme='light'] {
	--dropdown-label-color: var(--global-primaryTextColor);
	--dropdown-label-opacity: 0.8;
	--dropdown-select-color: var(--global-primaryTextColor);
	--dropdown-select-opacity: 0.8;
	--dropdown-icon-color: var(--global-primaryTextColor);
	--dropdown-container-border: 1px solid var(--local-borderColor2);
	--dropdown-menuContainer-background-color: var(--global-primaryBackground);
	--dropdown-menuContainer-color: var(--global-primaryTextColor);
	--dropdown-selected-background-color: var(--global-dropdown-selected-background-color);
	--dropdown-hover-background-color: var(--global-dropdown-hover-background-color);
}
/* :: end of Dropdown :: */

/* :: start of Menu :: */

:root,
:root [theme='dark'],
:root [theme='light'] {
	--menu-background: var(--global-primaryBackground);
	--menu-color: var(--global-secondaryTextColor);
	--menu-active-text-color: var(--global-menuIcon-active-text-color);
	--menu-active-background: var(--global-menuIcon-active-background);
	--menu-hover-background: var(--global-dropdown-hover-background-color);
	--menu-delete-text-color: var(--global-amberColor);
	--menu-deleteMenuItem-text-color: var(--global-amberColor);
	--menu-deleteMenuItem-hover-background: var(--global-hover-background-color);
	--menu-icon-margin-bottom: 2px;
	--menu-icon-margin-left: 4px;
}
/* :: end of Menu :: */

:root,
:root [theme='dark'],
:root [theme='light'] {
	/*:: start of defining local sizes */
	--local-size: 8px;
	--local-size-md: 10px;
	--local-border-radius: 4px;
	--local-icon-fontSize: 16px;
	--local-title-fontSize: 16px;
	--local-heading-fontSize: 22px;
	--local-page-heading-fontSize: 24px;
	--local-mini-font: 12px;
	--local-base-fontSize: 14px;
	--local-button-fontSize: 13px;
	--local-subtitle-fontSize: 11px;
	--local-base1-fontSize: 14px;
	--local-base-font-weight: 400;
	--local-title-font-weight: 500;
	--local-bold-font-subtext: 600;
	--local-medium-font-weight: 700;
	--local-font-family: Rubik, Helvetica, sans-serif;
	--local-transition-time: 100ms;
	--local-transition-function: ease-in;
	--local-tooltip-z-index: 999999;
	--local-text-transform: none;
	/*:: end of defining local sizes */

	/*:: end of defining global sizes */
	--global-size: var(--local-size);
	--global-size-md: var(--local-size-md);
	--global-border-radius: var(--local-border-radius);
	--global-icon-fontSize: var(--local-icon-fontSize);
	--global-title-fontSize: var(--local-title-fontSize);
	--global-heading-fontSize: var(--local-heading-fontSize);
	--global-page-heading-fontSize: var(--local-page-heading-fontSize);
	--global-base-fontSize: var(--local-base-fontSize);
	--global-mini-font: var(--local-mini-font);
	--global-subtitle-fontSize: var(--local-subtitle-fontSize);
	--global-base1-fontSize: var(--local-base1-fontSize);
	--global-base-font-weight: var(--local-base-font-weight);
	--global-title-font-weight: var(--local-title-font-weight);
	--global-medium-font-weight: var(--local-medium-font-weight);
	--global-bold-font-subtext: var(--local-bold-font-subtext);
	--global-font-family: var(--local-font-family);
	--global-transition-time: var(--local-transition-time);
	--global-transition-function: var(--local-transition-function);
	--global-tooltip-z-index: var(--local-tooltip-z-index);
	--global-text-transform: var(--local-text-transform);
	--global-font-color: var(--local-primaryTextColor);
	--global-title-disabled-opacity: 0.5;
	--global-transparent-bg: transparent;
	--global-button-fontSize: var(--local-button-fontSize);
	--global-border-color: #e9e9eb;
	--global-tertiary-button-hover-bg: #e9e9eb;
	--global-subtitle-color: #656770;
	--global-selectOption-bg: #f6f6f6;
	--global-selectOption-active-bg: #e2e0f9;
}

:root,
:root [theme='light'] {
	--local-themeColor: #655be1;

	--local-scrollbar-width: 5px;
	--local-scrollbar-height: 6px;
	--local-scrollbar-background: #dbdfe1;
	--local-scrollbar-thumb-background: #707178;
	--local-scrollbar-thumb-hover-background: #707178;

	--local-onbording-background: var(--global-primaryTextColor);
	--local-onbording-text-color: #ffffff;

	--local-primaryBackground: #ffffff;
	--local-invertedBackground: #121e18f0;

	--local-primaryTextColor: var(--color-grey-900);
	--local-secondaryTextColor: var(--color-grey-700);
	--local-tertiaryTextColor: var(--color-grey-500);

	--local-invertedTextColor: #ffffff;
	--local-annotation-background: #111111;
	--local-annotation-color: #ffffff;
	--local-overlay-background: #111111;
	--local-borderColor: #e1e1e1;
	--local-borderColor2: #11111120;
	--local-amberColor: #e15a5a;
	--local-floRowItem-background: #ffffff;
	--local-action-background: #f2f2f2;
	--local-info-text-color: #00000099;
	--local-videoBorderColor: #fafafa;
	--local--groupedButtonBackground: #1111110d;
	--local-tool-button-background: #1111110d;
	--local-tool-button-color: var(--local-primaryTextColor);
	--local-tool-active-button-background: #232633;
	--local-tool-active-button-color: #ffffff;

	--local-flo-background: var(--color-light-grey);
	--local-profileDialog-input-background: #ffffff;
	--local-switch-track-background: #e1e1e1;
	--local-switch-track-bg-disabled: #ccc5f3;
	--local-switch-base: #fff;
	--local-videoPlayer-menu: transparent;
	--local-videoPlayer-menu-content-background-color: rgb(252 253 255);
	--local-videoPlayer-menu-content-border-color: rgba(0, 0, 0, 0.03);
	--local-videoPlayer-menu-button-popup-border: 1px solid #fafafa;
	--local-videoPlayer-menu-button-popup-background-color: #23263314;
	--local-list-wrapper-background: var(--local-flo-background);
	--local-manageFlo-rowItem_box-shadow: 0px 1px 2px 0px #1018280f;
	--local-comment-container-border: 1px solid rgba(17, 17, 17, 0.2);
	--local-sendMessageContainer-box-shadow-color: rgba(16, 24, 40, 0.1);
	--local-primaryHeader-conatiner-border-color: rgba(44, 48, 46, 0.1);
	--local-decision-svg-fill: rgba(17, 17, 17, 0.3);
	--local-actionCheck-svg-fill: rgba(17, 17, 17, 0.3);
	--local-decision-icon-color: #e91b0e;
	--local-notification-border: #eaeaea;
	--local-notification-box-shadow: 0px 20px 24px rgba(16, 24, 40, 0.08);
	--local-emailInput-border: 1px solid rgba(17, 17, 17, 0.2);
	--local-button-text-color: var(--local-borderColor);
	--local-button-border-color: var(--local-button-text-color);
	--local-signin-image-spotlight-color: #fff;
	--local-signin-bottom-shadow-color: #f5f5f5;
	--local-loader-background: rgb(255 255 255 / 50%);
	--local-chip-overdue-text-color: rgb(17, 17, 17);
	--local-chip-overdue-background: rgb(17, 17, 17, 0.1);
	--local-message-showHideReplies-color: #2660f4;
	--local-rangeSlider-overlay-color: #f1f1f1;

	--global-themeColor: var(--local-themeColor);
	--global-scrollbar-width: var(--local-scrollbar-width);
	--global-scrollbar-height: var(--local-scrollbar-height);
	--global-scrollbar-background: var(--local-scrollbar-background);
	--global-scrollbar-thumb-background: var(--local-scrollbar-thumb-background);
	--global-scrollbar-thumb-hover-background: var(
		--local-scrollbar-thumb-hover-background
	);
	--global-primaryBackground: var(--local-primaryBackground);
	--global-invertedBackground: var(--local-invertedBackground);
	--global-primaryTextColor: var(--local-primaryTextColor);
	--global-invertedTextColor: var(--local-invertedTextColor);
	--global-secondaryTextColor: var(--local-secondaryTextColor);
	--global-tertiaryTextColor: var(--local-tertiaryTextColor);
	--global-overlay-background: var(--local-overlay-background);
	--global-floRowItem-background: var(--local-floRowItem-background);
	--global-borderColor: var(--local-borderColor);
	--global-amberColor: var(--local-amberColor);
	--global-action-background: var(--local-action-background);
	--global--groupedButton-background: var(--local--groupedButtonBackground);
	--global-avatar-text-color: var(--local-invertedTextColor);
	--global-toggleButtonColor: rgba(44, 48, 46, 0.7);
	--global-toggleButtonSelectedBg: #2c302e;
	--global-toggleButtonSelectedColor: #ffffff;
	--global-chipDefaultBackground: #2e5cd21a;
	--global-chipTextColor: #2660f4;
	--svgActiveColor: #111111;
	--global-flo-background: var(--local-flo-background);
	--global-container-background-color: var(--local-flo-background);
	--global-profileDialog-input-background: var(--local-profileDialog-input-background);
	--global-switch-track-background: var(--local-switch-track-background);
	--global-shareFloBody-linkContainer-background: #f6f6f6;
	--globel-videoPlayer-control-play-icon-image: url('../../../public/images/play.svg');
	--globel-videoPlayer-control-pause-icon-image: url('../../../public/images/pause.svg');
	--global-videoPlayer-videoVolumeUrl: url('../../../public/images/volume.svg');
	--global-videoPlayer-videoLowVolumeUrl: url('../../../public/images/volume-low.svg');
	--global-videoPlayer-videoMuteVolumeUrl: url('../../../public/images/volume-mute.svg');
	--global-videoPlayer-videoFullScreenUrl: url('../../../public/images/maximize-dark.svg');
	--global-videoPlayer-progress-control-holder-background: rgba(17, 17, 17, 0.06);
	--global-login-background: #137866;
	--global-login-pageContent-background-color: #137866;
	--global-manageFlo-floRowItem-container-hover-background: #f2f2f2;
	--global-menuIcon-active-text-color: var(--global-themeColor);
	--global-menuIcon-active-background: rgba(85, 145, 108, 0.1);
	--global-chip-draft-background: rgba(224, 135, 1, 0.1);
	--global-chip-draft-text-color: #e08701;
	--global-chip-closed-background: rgba(85, 145, 108, 0.1);
	--global-chip-closed-text-color: var(--global-themeColor);
	--global-Page404-homeButton-color: #ffffff;
	--global-Page404-imageContainer-path-1-background: #f5f5f5;
	--global-Page404-imageContainer-path-2-3-4-background: #e0e0e0;
	--global-Page404-contentText-color: var(--local-secondaryTextColor);
	--global-Page500-homeButton-color: #ffffff;
	--global-Page500-imageContainer-path-1-background: #f5f5f5;
	--global-Page500-imageContainer-path-2-to-8-background: #e0e0e0;
	--global-Page500-contentText-color: var(--local-secondaryTextColor);
	--global-newFloPage-cardPermissionsTitle-opacity: 1;
	--global-newFloPage-cardPermissionsTitle-color: var(--local-primaryTextColor);
	--global-newFloPage-cardCountDown-color: var(--global-themeColor);
	--global-recordButton-color: #232633;
	--global-newFloPage-card-border-color: #eaeaea;
	--global-newFloPage-optionSelect-background: rgba(17, 17, 17, 0.05);
	--global-newFloPage-optionSelectActive-background: rgba(85, 145, 108, 0.1);
	--global-newFloPage-optionText-color: var(--global-themeColor);
	--global-newFloPage-deviceIcon-background-color: #55916c20;
	--global-newFloPage-discardButton-color: var(--global-amberColor);
	--global-newFloPage-recordButton-background-color: #23263310;
	--global-newFloPage-deviceSelectionDropdown-border-color: #ffffff20;
	--global-newFloPage-container-background: #ffffff;
	--global-newFloPage-clipboardSvg-stroke-color: #000000;
	--global-newFloPage-nameInput-border-color: #11111120;
	--global-newFloPage-optionSelect-svg-fill: #f3f3f3;
	--global-newFloPage-optionSelectActive-svg-fill: #eef4f0;
	--global-dropdown-selected-background-color: #efeffb;
	--global-dropdown-hover-background-color: var(--color-grey-100);
	--global-message-borderBody-border-color: #2c302e15;
	--global-button-text-color: var(--global-invertedTextColor);
	--global-hover-background-color: #00000020;
	--global-loader-background: var(--global-primaryBackground);
	--global-sendMessage-placeholder-fill-color: var(--local-primaryTextColor);

	--global-proceesing-svg-background-color: #fafafa;
	--global-proceesing-svg-step-background-color: #e0e0e0;
	--global-proceesing-svg-item-background-color: #fafafa;
	--global-proceesing-svg-square-box-color: #e0e0e0;
	--global-proceesing-svg-slider-gray-color: #e0e0e0;
	--global-proceesing-svg-shirt-color: #f0f0f0;
	--global-proceesing-svg-collar-color: #e0e0e0;
	--global-slider-hightlight-color: var(--color-grey-900);
	--global-shareFloBody-collaboratorUserName-color: #181818;
	--global-onboardingBackground: var(--local-onbording-background);
	--global-onboardingBackground-text: var(--local-onbording-text-color);
	--global-border-color: #eaeaea;

	--input-border-color: var(--color-grey-300);
}

/* :: end of defining colors :: */

@import 'theme-dark.css';

:root {
	--brand-color: var(--local-themeColor);
	--brand-color-hotspot-background: var(--local-themeColor);
	--brand-color-click-animation-color: var(--color-dark-red);
	--brand-color-activity-border-color: var(--color-orange);
	--brand-color-videoPlayer-controls-bar-background: var(--color-white);
	--brand-color-videoPlayer-controls-bar-border-left: var(--color-light-grey);
	--brand-color-videoPlayer-volume-panel-color: var(--color-dark-grey);
	--brand-color-videoPlayer-progress-control-bar-background: rgb(115 133 159 / 25%);
	--brand-color-videoPlayer-progress-control-holder-background: rgba(17, 17, 17, 0.06);
	--brand-color-videoPlayer-progress-control-play-progress-background: var(
		--local-themeColor
	);
	--brand-color-instructions-list-step-index-background: #3b69eb;
	--brand-color-instructions-list-step-index-color: #3b69eb;
	--brand-color-button-text-color: var(--color-white);
	--brand-color-button-background-color: var(--global-themeColor);
	--brand-color-tabs-border-color: var(--global-themeColor);
	--brand-color-tick-color: var(--global-themeColor);
	--brand-color-switch-checked-text-color: var(--global-themeColor);
	--brand-color-switch-track-check-background: var(--global-themeColor);
}
