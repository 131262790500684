.video-js {
	height: 100% !important;
	width: 100% !important;
}

.video-js,
.video-js.vjs-fluid {
	background-color: var(--videoPlayer-background);
	width: 100%;
	border-radius: var(--videoPlayer-border-radius);
}
.video-js .vjs-tech {
	border-top-right-radius: var(--videoPlayer-top-right-border-radius);
	border-top-left-radius: var(--videoPlayer-top-left-border-radius);
}

.video-js .vjs-big-play-button {
	top: var(--videoPlayer-bigbuttonPlay-top);
	left: var(--videoPlayer-bigbuttonPlay-left);
}

.video-js .vjs-control {
	width: 3em;
}

.video-js .vjs-control-bar {
	height: var(--videoPlayer-controls-bar-height);
	display: var(--videoPlayer-controls-bar-display);
	bottom: var(--videoPlayer-controls-bar-bottom);
	background: var(--videoPlayer-controls-bar-background);
	border-left: var(--videoPlayer-controls-bar-border-left);
	border-right: var(--videoPlayer-controls-bar-border-right);
	border-bottom: var(--videoPlayer-controls-bar-border-bottom);
	padding: var(--videoPlayer-controls-bar-padding);
	box-shadow: var(--videoPlayer-controls-bar-box-shadow);
	border-bottom-left-radius: var(--videoPlayer-controls-bar-border-bottom-left-radius);
	border-bottom-right-radius: var(--videoPlayer-controls-bar-border-bottom-right-radius);
}

.video-js .vjs-play-control {
	background-color: var(--videoPlayer-play-control-background-color);
	border-radius: var(--videoPlayer-play-control-border-radius);
	height: var(--videoPlayer-play-control-height);
	width: var(--videoPlayer-play-control-width);
	background-image: var(--videoPlayer-play-control-play-icon-image);
	background-repeat: no-repeat;
	margin: 8px 22.5px 0 0;
	transition: none;
}

.video-js :focus:not(:focus-visible) {
	outline: none;
}

.video-js .vjs-slider:focus {
	outline: none;
	box-shadow: unset;
	text-shadow: unset;
}

.vjs-play-control.vjs-playing {
	background-image: var(--videoPlayer-play-control-pause-icon-image);
}

.video-js .vjs-play-control:hover {
	opacity: 0.9;
}

.video-js .vjs-play-control .vjs-icon-placeholder {
	display: none;
}

.video-js .vjs-volume-panel,
.video-js .vjs-time-control {
	color: var(--videoPlayer-volume-panel-color);
	height: var(--videoPlayer-volume-panel-height);
	width: var(--videoPlayer-volume-panel-width);
	margin: var(--videoPlayer-volume-panel-margin);
}

.video-js .vjs-volume-panel {
	border-right: var(--videoPlayer-volume-panel-border-right);
	padding: var(--videoPlayer-volume-panel-padding);
}

.video-js .vjs-mute-control {
	margin-left: var(--videoPlayer-mute-control-margin-left);
	padding: var(--videoPlayer-mute-control-padding);
}

.video-js .vjs-mute-control {
	transition: 200ms all ease-in-out;
}
.video-js .vjs-mute-control.vjs-vol-3 .vjs-icon-placeholder:before {
	content: '';
	background-image: var(--videoPlayer-videoVolumeUrl);
	background-repeat: no-repeat;
	background-size: var(--videoPlayer-mute-control);
}

.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before,
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
	content: '';
	background-image: var(--videoPlayer-videoLowVolumeUrl);
	background-repeat: no-repeat;
	background-size: var(--videoPlayer-mute-background-size);
}

.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
	content: '';
	background-image: var(--videoPlayer-videoMuteVolumeUrl);
	background-repeat: no-repeat;
	background-size: var(--videoPlayer-mute-background-size);
}

.video-js .vjs-fullscreen-control {
	cursor: pointer;
	flex: none;
	display: flex;
	top: 8px;
}

.vjs-fullscreen-control .vjs-icon-placeholder:before {
	content: '';
	position: absolute;
	height: 30px;
	width: 30px;
	background-image: var(--videoPlayer-videoFullScreenUrl);
	background-repeat: no-repeat;
	background-size: var(--videoPlayer-mute-background-size);
}

.video-js .vjs-volume-control {
	z-index: 1;
}

.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode),
.video-js .vjs-tech {
}

.video-js.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
	opacity: 1;
}

.video-js .vjs-progress-control {
	position: absolute;
	width: var(--videoPlayer-progress-control-width);
	top: var(--videoPlayer-progress-control-top);
	height: var(--videoPlayer-progress-control-height);
}

.video-js .vjs-progress-control .vjs-progress-holder {
	background-color: var(--videoPlayer-progress-control-holder-background);
	height: var(--videoPlayer-progress-control-holder-height);
	margin: var(--videoPlayer-progress-control-holder-margin);
}

.video-js .vjs-progress-control .vjs-load-progress,
.video-js .vjs-progress-control .vjs-load-progress div {
	background-color: var(--videoPlayer-progress-bar-background);
}

.video-js .vjs-progress-control .vjs-play-progress {
	background-color: var(--videoPlayer-progress-control-play-progress-background);
}

.video-js .vjs-progress-control .vjs-play-progress::before {
	display: none;
}

.video-js .vjs-progress-control .vjs-load-progress,
.video-js .vjs-progress-control .vjs-play-progress,
.video-js .vjs-progress-control .vjs-load-progress div,
.video-js .vjs-progress-control .vjs-progress-holder {
	border-radius: var(--videoPlayer-progress-control-border-radius);
}

.videoTrack,
.video-js.videoTrack {
	position: absolute;
	z-index: 99999;
	padding-top: var(--videoPlayer-videoTrack-padding-top) !important;
	background-color: var(--videoPlayer-videoTrack-background-color) !important;
	border: var(--videoPlayer-videoTrack-border) !important;
	background: var(--videoPlayer-videoTrack-background) !important;
	border-radius: var(--videoPlayer-videoTrack-border-radius);
	box-sizing: border-box;
}

.video-js.videoTrack video {
	position: relative !important;
	border-radius: var(--videoPlayer-videoTrack-border-radius);
	left: var(--videoPlayer-videoTrack-left) !important;
	top: var(--videoPlayer-videoTrack-top) !important;
}

.video-js.videoTrack .vjs-big-play-button {
	display: none;
}

.video-js.audioTrack {
	display: none;
}

.videoTrack .vjs-loading-spinner {
	display: none !important;
}

.video-js .vjs-current-time,
.video-js .vjs-duration,
.video-js .vjs-time-divider {
	display: block;
}

.video-js .vjs-remaining-time {
	display: none;
}

.video-js .vjs-time-divider {
	width: var(--videoPlayer-time-divider-width);
	padding: var(--videoPlayer-time-divider-padding);
}
.video-js .vjs-time-divider div {
	position: relative;
	bottom: var(--videoPlayer-time-divider-bottom);
}

.video-js .vjs-current-time-display,
.video-js .vjs-duration-display {
	position: absolute;
	top: var(--videoPlayer-duration-top);
}

.video-js .vjs-duration-display {
	left: var(--videoPlayer-duration-left);
}

.video-js .vjs-current-time-display,
.video-js .vjs-duration-display,
.video-js .vjs-time-divider div {
	font-family: var(--videoPlayer-duration-font-family);
	color: var(--videoPlayer-duration-color);
	opacity: var(--videoPlayer-duration-opacity);
	font-weight: var(--videoPlayer-duration-font-weight);
	font-size: var(--videoPlayer-duration-font-size);
}

.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-playback-rate,
.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector {
	color: var(--videoPlayer-qualitySelector-color);
	margin: 1px 0 0 0;
}

.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-playback-rate
	+ .vjs-button.vjs-playback-rate,
.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector
	+ .vjs-button.vjs-quality-selector {
	visibility: hidden;
}

.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-playback-rate button,
.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector
	button {
	height: var(--videoPlayer-menu-button-popup-height);
	margin: var(--videoPlayer-menu-button-popup-margin);
	background-color: var(--videoPlayer-menu-button-popup-background-color);
	color: var(--videoPlayer-menu-button-popup-color);
	border-radius: var(--videoPlayer-menu-button-popup-border-radius);
	text-transform: capitalize;
}

.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector
	button {
	padding: 0 2px;
}

.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-playback-rate
	button
	span,
.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector
	button
	span {
	font-weight: var(--videoPlayer-menu-button-popup-font-weight);
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
	position: absolute;
	width: 100%;
	background-color: var(--videoPlayer-menu-content-background-color);
	bottom: var(--videoPlayer-menu-content-bottom);
	max-height: var(--videoPlayer-menu-content-max-height);
	border: var(--videoPlayer-menu-content-border);
	border-radius: var(--videoPlayer-menu-content-border-radius);
	z-index: 9999;
	box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
}

.vjs-menu {
	bottom: 68px;
}

.vjs-menu li.vjs-menu-title {
	font-weight: var(--videoPlayer-menu-title-font-weight);
	padding: var(--videoPlayer-menu-title-padding);
	text-align: left;
}

.vjs-menu li {
	padding: 4px 16px;
	text-align: left;
	text-transform: capitalize;
}

li.vjs-menu-item:hover {
	background-color: var(--videoPlayer-menu-hover-background) !important;
}

li.vjs-menu-item.vjs-selected {
	background: var(--videoPlayer-menu-selected-background);
	color: var(--videoPlayer-menu-selected-color);
}

li.vjs-menu-item.vjs-selected:hover {
	color: var(--videoPlayer-menu-selected-color) !important;
	background: var(--videoPlayer-menu-selected-hover-background);
	opacity: var(--videoPlayer-menu-selected-hover-opacity);
}

.video-js.vjs-vtt-thumbnails {
	display: block;
}

.vjs-vtt-thumbnail-display {
	opacity: 0;
}

.video-js .vjs-vtt-thumbnail-display {
	position: absolute;
	pointer-events: none;
	z-index: 99999;
	transition: var(--videoPlayer-thumbnail-transition);
	left: var(--videoPlayer-thumbnail-left);
	bottom: var(--videoPlayer-thumbnail-bottom);
	box-shadow: var(--videoPlayer-thumbnail-box-shadow);
	height: var(--videoPlayer-thumbnail-height);
	width: var(--videoPlayer-thumbnail-width);
	background-size: cover;
}

.screenShareTrack {
	min-width: 50px;
	min-height: 50px;
}

@media screen and (max-width: 640px) {
	.video-js .vjs-control-bar {
		min-width: auto;
		padding: var(--videoPlayer-controls-bar-padding-tablet);
		height: var(--videoPlayer-controls-bar-height-tablet);
	}
	.video-js .vjs-progress-control {
		top: var(--videoPlayer-progress-control-top-tablet);
	}
	.video-js .vjs-play-control {
		margin: var(--videoPlayer-play-control-margin-tablet);
	}
	.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
		width: var(--videoPlayer-volume-panel-vertical-width-tablet);
	}
	.video-js .vjs-mute-control {
		width: var(--videoPlayer-mute-control-width-tablet);
		margin: var(--videoPlayer-mute-control-margin-tablet);
	}
	.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector
		+ .vjs-button.vjs-quality-selector {
		display: none;
	}
	.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-quality-selector {
		margin-left: auto;
	}
}

.video-js .vjs-playback-rate .vjs-playback-rate-value {
	font-size: var(--global-base-fontSize);
	color: var(--videoPlayer-duration-color) !important;
	padding: 4px 0 0 0;
}

.video-js .vjs-menu-button {
	position: absolute;
	right: 0;
}

.video-js .vjs-menu-button.vjs-quality-selector {
	right: 60px;
}

.video-js .vjs-menu-button.vjs-playback-rate {
	right: 8px;
	margin: 1px 0 0 0;
}

.vjs-menu li {
	color: var(--videoPlayer-menu-button-popup-color);
}

.vjs-quality-selector .vjs-menu,
.vjs-playback-rate .vjs-menu {
	top: 16px;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
	pointer-events: auto !important;
}

.rangeSliderWrapper {
	left: 0;
	position: absolute;
	user-select: none;
	/*pointer-events: none;*/
	top: 17px;
}

.rangeSliderOverlap {
	top: 15px;
	height: 16px;
	position: absolute;
	user-select: all;
	pointer-events: all;
	background-color: var(--local-rangeSlider-overlay-color);
	border-radius: 3px;
}
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
	pointer-events: auto !important;
}

.vjs-volume-control.vjs-control.vjs-volume-vertical {
	z-index: 99;
}

.react-resizable-handle {
	border-right: 3px solid white;
	border-bottom: 3px solid white;
	width: 10px;
	height: 10px;
	z-index: 99999;
	cursor: grab;
}

.react-draggable .react-resizable-handle {
	opacity: 0;
}

.hiddenVideo.videoTrack video {
	opacity: 0;
}

.react-draggable:hover .react-resizable-handle {
	opacity: 1;
}

.videoTrack,
.video-js.videoTrack {
	background: transparent !important;
	border: none !important;
	z-index: 9;
}

.react-draggable:hover .video-js.videoTrack video {
}

.react-draggable:hover .video-js.videoTrack,
.react-draggable:hover .video-js.videoTrack video {
	opacity: 1;
	background: var(--videoPlayer-videoTrack-background) !important;
	border: initial;
}

.hiddenVideo.react-draggable .video-js.videoTrack video,
.hiddenVideo.react-draggable:hover .video-js.videoTrack video {
	opacity: 0 !important;
}

.video-js .vjs-progress-control .vjs-mouse-display {
	z-index: 999 !important;
}

.vjs-time-tooltip {
	z-index: 99 !important;
}

.vjs-text-track-display {
	z-index: 2;
}

.vjs-text-track-display div {
	font-size: 1em;
	padding: 2px;
}
