.container fieldset {
	border: var(--dropdown-container-border) !important;
}

.label {
	color: var(--dropdown-label-color);
	opacity: var(--dropdown-label-opacity);
}

.select {
	color: var(--dropdown-select-color) !important;
	opacity: var(--dropdown-select-opacity);
	z-index: 999;
}

.head {
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
}

.head .headText {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.menuItem {
	display: flex;
	overflow: hidden;
}

.menuItemLabel {
	flex-grow: 1;
	overflow: hidden;
	white-space: initial;
}

.menuItem.optionsLabel {
	background: transparent;
	font-weight: 500;
	padding-bottom: 4px;
	line-height: 16px;
	pointer-events: none;
}

.icon {
	color: var(--dropdown-icon-color) !important;
}

.formControl {
	position: relative;
}

.leftIcon {
	margin-right: 4px;
	width: 20px;
	height: 16px;
	flex-shrink: 0;
}

img.leftIcon {
	margin-right: 8px;
}

.menuRoot {
	z-index: 999999 !important;
}

.menuContainer {
	background-color: var(--dropdown-menuContainer-background-color) !important;
	color: var(--dropdown-menuContainer-color) !important;
	max-height: 345px !important;
}

.menuContainer.menuContainerWithSearch {
	padding-top: 56px;
}

.menuItem:hover {
	background-color: var(--dropdown-hover-background-color) !important;
}

.selected {
	background-color: var(--global-dropdown-selected-background-color) !important;
	color: var(--local-themeColor) !important;
}

.selected .subText {
	color: var(--local-themeColor) !important;
}

.menuContainer .checkbox {
	padding: 0 8px 0 0;
}

.searchInputWrapper {
	width: 100%;
	padding: 0 16px;
	position: absolute;
	top: calc(100% + 16px);
	z-index: 9999999;
}

.searchInput {
	height: 40px;
	padding: 10px 12px 10px 36px;
	width: 100%;
	border-radius: 6px;
	border: 1px solid var(--color-grey-300);
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.searchInput::placeholder {
	color: var(--color-grey-500);
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-base-font-weight);
	line-height: 20px;
}

.searchIcon {
	position: absolute;
	top: 50%;
	left: 28px;
	transform: translateY(-50%);
	stroke: var(--global-tertiaryTextColor);
}
.subText {
	color: var(--global-tertiaryTextColor);
	white-space: pre;
}

.overflownHeadTextWrapper {
	display: flex;
	width: 100%;
	overflow: hidden;
	align-items: center;
}

.overflownHeadText {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.head .hiddenOptionsCount {
	font-size: 11px;
	font-weight: var(--global-title-font-weight);
	height: 16px;
	padding: 0 4px;
	background: var(--color-grey-900);
	border-radius: 100px;
	letter-spacing: 0.5px;
	color: var(--color-white);
	display: inline-flex;
	align-items: center;
	margin-left: 4px;
}

.popper {
	z-index: 99999999 !important;
}
