.container {
	display: flex;
	height: 100%;
	width: 100%;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.contentContainer {
}

.contentTitle {
	font-size: var(--global-heading-fontSize) !important;
	font-weight: var(--global-title-font-weight);
}

.goToInboxBtn,
.goToInboxBtn:hover {
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-title-font-weight);
	background: var(--global-themeColor) !important;
	color: var(--color-white) !important;
	border-color: var(--global-themeColor) !important;
	font-family: var(--global-font-family);
	text-transform: none !important;
	display: inline-block;
	padding: 14px;
	border-radius: var(--global-border-radius);
	text-decoration: none;
}
