.notificationRoot {
	z-index: 999999999999 !important;
}
.notificationContainer {
	font-family: var(--notification-font-family);
	padding: var(--notification-container-padding);
	font-weight: var(--notification-container-font-weight);
	font-size: var(--notification-container-font-size);
	color: var(--notification-container-text-color);
	background-color: var(--notification-container-background);

	border: var(--notification-container-border);
	box-shadow: var(--notification-container-box-shadow);
	border-radius: var(--notification-container-border-radius);
}

.notificationIcon {
	margin-right: var(--notification-icon-margin-right);
	height: var(--notification-icon-height);
	width: var(--notification-icon-width);
}

.notificationIcon.warning path {
	stroke: var(--notification-icon-warning-color);
}
