.container {
	height: 100%;
	background: var(--authenticatedRoutes-background) !important;
	overflow: hidden;
}

.routeContent {
	position: relative;
	height: var(--authenticatedRoutes-container-height);
	/*overflow: auto;*/
	border-radius: 0 !important;
	box-shadow: unset !important;
}

.loaderOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99999;
	background-color: var(--global-loader-background);
}
