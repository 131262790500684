.dialogRoot {
	z-index: 999999 !important;
}

.dialogContainer {
}

.dialogContainer.top {
	align-items: flex-start;
}

.dialogContainer.bottom {
	align-items: flex-end;
}

.dialogContainer.left {
	justify-content: flex-start;
}

.dialogContainer.right {
	justify-content: flex-end;
}

.dialogRoot.blurBackdrop > div {
	backdrop-filter: blur(2px);
}

.positionRelative {
	position: relative !important;
	height: 100%;
	width: 100%;
}

.positionAbsolute {
	position: absolute !important;
	height: 100%;
	width: 100%;
}

.container .title {
	font-weight: var(--dialog-title-font-weight);
	font-size: var(--dialog-title-font-size);
	line-height: var(--dialog-title-line-height);
	color: var(--dialog-title-text-color) !important;
	font-family: var(--global-font-family) !important;
	padding: 0px 0 var(--dialog-title-padding-bottom) 0px !important;
	word-wrap: break-word;
}

.closeButton {
	position: absolute;
	right: 16px;
	top: 16px;
}

.dialogContent {
	padding: 0 !important;
	font-family: var(--global-font-family) !important;
	overflow-y: unset !important;
}

.contentWrapper {
	display: block;
	overflow: auto;
}

.contentWrapper::-webkit-scrollbar {
	width: var(--global-scrollbar-width);
}

/* Track */
.contentWrapper::-webkit-scrollbar-track {
	display: none;
}

/* Handle */
.contentWrapper::-webkit-scrollbar-thumb {
	background: var(--color-grey-400);
}

/* Handle on hover */
.contentWrapper:hover::-webkit-scrollbar {
	display: block;
}

.contentWrapper:hover::-webkit-scrollbar-thumb,
.contentWrapper::-webkit-scrollbar-thumb:hover {
	background: var(--color-grey-400);
}

.dialogMessage {
	color: var(--dialog-message-text-color) !important;
	font-family: var(--global-font-family) !important;
	overflow-x: hidden;
	padding: var(--dialog-dialogMessage);
	word-wrap: break-word;
	padding: 0 0 1px 0;
	line-height: 22px;
}

.dialogMessage p,
.dialogMessage div,
.dialogMessage a {
	margin-bottom: 0;
}

.closeIcon {
	color: var(--dialog-closeIcon-text-color) !important;
}

.dialogRoot .container {
	min-width: var(--dialog-min-width) !important;
	max-width: 600px;
	border-radius: var(--dialog-border-radius);
	background-color: var(--dialog-background);
	padding: var(--dialog-padding) !important;
}

.dialogRoot .container.large {
	width: min(875px, 100vw - 64px);
	max-width: initial;
	padding: 24px !important;
}

.dialogRoot .container.preview {
	max-width: 400px;
}

.dialogRoot .container.hasEditIcon {
	overflow: initial;
}

.dialogRoot .container .editButton,
.dialogRoot .container .editButton:hover {
	height: 28px;
	width: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: var(--color-black);
	color: var(--color-white);
}

.dialogRoot .container.small {
	max-width: var(--dialog-variant-small-max-width) !important;
	min-width: 400px !important;
}

.actions {
	padding: var(--dialog-action-padding) !important;
}

.actions.center {
	justify-content: center;
}

.actions .cancelButton {
	border: 1px solid var(--color-border);
	padding: 3px 12px;
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	border-radius: var(--dialog-button-border-radius) !important;
	box-sizing: content-box;
	line-height: 22px;
}

.actions .cancelButtonText {
	opacity: 0.9;
	font-size: var(--dialog-footer-button-font-size);
	color: var(--global-primaryTextColor);
}

.actions .cancelButton:hover {
	background: var(--color-border);
}

.actions .confirmButton:hover,
.actions .confirmButton {
	font-size: var(--dialog-footer-button-font-size);
	font-weight: var(--dialog-confirmButton-font-weight) !important;
	/*background: var(--dialog-saveButton-background) !important;*/
	background: var(--button-secondary-bg-color) !important;
	text-transform: none !important;
	margin-left: var(--dialog-saveButton-margin) !important;
	font-family: var(--dialog-button-font-family);
	box-shadow: none !important;
	box-sizing: content-box;
	line-height: 22px;
}

.center .confirmButton:hover,
.center .confirmButton {
	margin-left: 0 !important;
}

.actions .confirmButton:hover,
.actions .confirmButton {
	border-radius: var(--dialog-button-border-radius) !important;
	padding: 3px 12px !important;
	font-size: var(--dialog-footer-button-font-size);
}

.actions .confirmButton:hover {
	background-color: var(--button-secondary-hover-bg-color) !important;
}

.actions .deleteButton:hover,
.actions .deleteButton {
	background-color: var(--dialog-deleteButton-background) !important;
	border-color: var(--dialog-deleteButton-background) !important;
	color: var(--dialog-deleteButton-text-color) !important;
	font-family: var(--global-font-family) !important;
	box-shadow: none !important;
	box-sizing: content-box;
	line-height: 22px;
}

.actions .deleteButton:hover {
	opacity: var(--dialog-deleteButton-hover-opacity);
}

.buttonContainer {
	padding-top: 16px;
	display: flex;
	gap: 8px;
	flex-direction: column;
}

.buttonContainer.noSubHeading {
	padding-top: 0;
}

.buttonContainer .userActionButton,
.buttonContainer .userActionButton:hover {
	color: var(--color-white);
	padding: 3px 12px;
	box-sizing: content-box;
	margin-right: 0;
	word-break: break-word;
	height: auto;
	background: var(--brand-color);
	border-color: var(--brand-color);
}

.preview .buttonContainer .userActionButton,
.preview .buttonContainer .userActionButton:hover {
}
.preview .buttonContainer .userActionButton.noLabel {
	opacity: 0.7;
}

.noData {
	font-size: var(--global-heading-fontSize);
	font-weight: var(--global-title-font-weight);
	color: var(--color-grey-700);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dialogMessage ul,
.dialogMessage ul li {
	list-style: disc;
}

.dialogMessage ul ul,
.dialogMessage ul ul li {
	list-style: circle;
	margin: 0;
}

.dialogMessage ul ul ul,
.dialogMessage ul ul ul li {
	list-style: disc;
	margin: 0;
}

.dialogMessage ul ul ul ul,
.dialogMessage ul ul ul ul li {
	list-style: disc;
	margin: 0;
}

.dialogMessage ol,
.dialogMessage ol li {
	list-style: auto;
	/* padding-left: revert; */
}

.dialogMessage ol li {
	list-style: decimal;
}

.dialogMessage ol ol,
.dialogMessage ol ol li {
	list-style: lower-alpha;
	margin: 0;
}

.dialogMessage ol ol ol,
.dialogMessage ol ol ol li {
	list-style: lower-roman;
	margin: 0;
}

.dialogMessage ol ol ol ol,
.dialogMessage ol ol ol ol li {
	list-style: decimal;
	margin: 0;
}

.dialogMessage a {
	color: var(--color-primary-500);
}

.dialogMessage ul,
.dialogMessage ol {
	padding-left: 20px;
	margin: 16px 0;
}

@media screen and (max-width: 640px) {
	.closeButton {
		margin-top: var(--dialog-closeButton-margin-top-tablet) !important;
		top: var(--dialog-closeButton-top-tablet) !important;
		right: var(--dialog-closeButton-right-tablet) !important;
	}
	.dialogRoot .container {
		padding: var(--dialog-dialogRootContainer-padding-tablet) !important;
		min-width: unset !important;
		max-width: 100%;
	}
}
