.infinity {
	left: 50%;
	top: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}

.fullPageInfinity svg > g g,
.infinity svg > g g {
	transform: translate(34%, 33%) scale(0.6) !important;
}

.fullPageInfinity svg path,
.infinity svg path {
	stroke: var(--loader-infinity-color);
}

.loaderContainer {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loaderOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99999;
	background-color: var(--global-loader-background);
}
