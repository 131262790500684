.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.title {
	color: var(--profileDialog-title-text-color) !important;
	font-family: var(--profileDialog-title-font-family) !important;
	font-style: normal;
	font-weight: var(--profileDialog-title-font-weight) !important;
	font-size: var(--settings-title-font-size) !important;
	line-height: normal;
	padding: 0 !important;
}

.tableWrapper {
	margin-top: 24px;
	width: 100%;
	flex-grow: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.tableHead {
	width: 100%;
	display: flex;
	padding: 8px 0 14px 0;
}

.tableHead .tableCell {
	font-weight: var(--global-title-font-weight);
}

.tableHead .tableCell:last-child {
	text-align: center;
}

.tableBody {
	flex-grow: 1;
	width: 100%;
}

.tableRow {
	height: 56px !important;
	border-radius: var(--item-radius-8);
	background: var(--color-grey-100);
	display: flex;
	align-items: center;
}

.tableCell {
	padding-left: 16px;
	color: var(--color-grey-900);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tableCell .downloadButton {
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-300);
	background: var(--global-primaryBackground);
	padding: 8px 12px !important;
	color: var(--color-grey-900);
	font-size: var(--global-base-fontSize);
	line-height: 20px;
}

.tableCell .downloadButton:first-child {
	margin-left: 0;
}

.downloadButtonText {
	opacity: 0.9;
	margin-left: 8px;
}

.tableCell .downloadButton:hover {
	background: var(--global-tertiary-button-hover-bg);
	border-color: var(--color-grey-300);
	color: var(--color-grey-900);
}

.tableCell:last-child {
	padding-right: 16px;
	flex-grow: 1;
	max-width: initial;
	min-width: 250px;
	flex-shrink: 0;
}

.tableCell:nth-child(3) {
	min-width: 103px;
	max-width: 103px;
}

.tableHead .tableCell:nth-child(3) {
	text-align: center;
}

.tableCell.rightAlign {
	text-align: right;
}
