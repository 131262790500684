.templateItem {
	position: relative;
	cursor: pointer;
	background-color: transparent !important;
	width: 280px;
	/*height: 224px;*/
	/*box-shadow: none !important;*/
}

.templateItem:not(:first-child) {
	margin-left: 16px !important;
}

.disabled {
	/*opacity: 0.5;*/
	cursor: no-drop;
}

.templateTitle {
	font-size: var(--global-base1-fontSize);
	font-weight: var(--global-title-font-weight);
	padding-top: 8px;
	display: flex;
	align-items: center;
	/*overflow: hidden;*/
	width: calc(100% - 15px);
}

.templateTitle svg {
	flex-shrink: 0;
}

.templateTitleText {
	font-size: var(--global-base1-fontSize);
	padding-left: 8px;
	font-weight: var(--global-bold-font-subtext);
	color: var(--global-font-color);
	opacity: 0.8;
	text-overflow: ellipsis;
	overflow: hidden;
	flex-shrink: 0;
	width: calc(100% - 24px);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.templatePreviewWrapper {
	position: relative;
	border-radius: 8px;
	width: 280px;
	height: 140px;
	background-size: contain;
	overflow: hidden;
	background: url('../../../../../Common/images/bg-img.svg') no-repeat top center, #acacac;
}

.templatePreviewImg {
	overflow: hidden;
	width: 280px;
	/*height: calc(9 * 16px);*/
	height: 140px;
}

.templateImage {
	box-shadow: 0 16px 12px 7px rgba(14, 22, 62, 0.34);
	border-radius: 6px 6px 0 0;
	object-fit: cover;
	object-position: top;
}

.templateDescription {
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-base-font-weight);
	color: var(--global-font-color);
	opacity: 0.6;
	display: -webkit-box;
	padding-top: 12px;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.comingSoon {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #0000007f;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: var(--global-base-font-weight);
	color: white;
	border-radius: 8px;
}

.templateHoverWrapper {
	visibility: hidden;
}

.templateHoverWrapper:hover {
	visibility: visible;
}

.templateHover {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 8px;
}

.templateHoverWrapper .useTemplateBtn {
	position: absolute !important;
	right: 16px;
	top: 16px;
	background-color: #ffffff !important;
	border-radius: 4px !important;
	color: var(--color-dark-grey) !important;
	box-shadow: none !important;
	padding: 2px 8px;
	line-height: 24px;
	font-size: 12px;
}

.templateHoverWrapper .useTemplateBtn:hover {
	opacity: 0.9;
}

.templatePreviewWrapper:hover .templateHover,
.templatePreviewWrapper:hover .templateBg {
	visibility: visible;
	background: var(--color-dark-grey);
	opacity: 0.5;
}

.templateBg {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(179.34deg, #a08eeb 0.59%, #533cb8 99.45%);
	opacity: 0.8;
	border-radius: 8px;
}

.cancelButton {
	color: black;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.warningIcon {
	color: #ffc107;
	width: 16px;
	height: 16px;
	margin-right: 4px;
}

.fileUploadcontainer {
}

.fileUploadingHeader {
}

.fileUploaduploadWrapper {
}

.fileUploadimageWrapper {
}

.fileUploaddragDropTextContainer {
}

.fileUploadsupportedFormats {
}

.fileUploador {
}

.uploadInputFile {
}
