.wrapper {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
}

.title {
	color: var(--global-primaryTextColor);
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 32px;
}

.subHeading {
	color: var(--global-primaryTextColor);
	font-weight: 500;
	margin-bottom: 12px;
}

.dropdownWrapper .rolesDropdown {
	margin: 0 8px 0 0;
}

.dropdownLeftIcon {
	order: 2;
	color: var(--color-grey-700);
}

.searchWrapper .dropdownWrapper .dropdownContainer {
	border-radius: var(--item-radius-6);
	min-width: 180px;
}

.dropdownHead .dropdownLeftIcon {
	display: none;
}

.dropdownContainer .dropdownIcon {
	color: var(--color-grey-700) !important;
}

.dropdownWrapper .dropdownSelect {
	padding-left: 12px;
	-webkit-text-fill-color: var(--dropdown-select-color) !important;
}

.dropdownMenuRoot .dropdownMenuContainer {
	width: 180px !important;
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-200);
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.dropdownWrapper ul {
	padding: 4px 0;
}

.row .rolesDropdown fieldset {
	border: 0 !important;
}

.dropdownWrapper .rolesDropdown {
	min-width: initial;
}

.dropdownMenuRoot .dropdownMenuItem {
	padding: 8px;
	min-height: initial;
	margin: 0 4px;
	border-radius: var(--item-radius-6);
}

.dropdownMenuRoot .dropdownMenuItem:first-child {
	padding-bottom: 4px;
}

.dropdownHeadText {
	color: var(--color-grey-500);
}

.dropdownHeadTextSelected {
	color: var(--color-grey-700);
}

.dropdownMenuRoot .menuItemLabel {
	line-height: 16px;
}

.searchWrapper {
	display: flex;
}

.searchContainer {
	width: 320px;
	height: 36px;
	flex-shrink: 0;
	border-radius: var(--item-radius-8);
	border: 1px solid #e9e9eb;
	margin-right: 8px;
}

.searchInput {
	padding: 0 12px;
	height: 100%;
	width: 100%;
	background: transparent;
}

.searchInput::placeholder {
	color: var(--global-primaryTextColor);
	opacity: 0.4;
	font-weight: var(--global-base-font-weight);
}

.searchWrapper .inviteButton,
.searchWrapper .inviteButton:hover {
	font-size: var(--global-base-fontSize);
	line-height: normal;
	border-radius: var(--item-radius-8);
	box-shadow: none;
	padding: 10px 12px;
	background: var(--global-themeColor);
}

.tableWrapper {
	margin-top: 24px;
	width: 100%;
	flex-grow: 1;
	overflow: hidden;
}

.row {
	display: flex;
	margin-bottom: 16px;
}

.row:last-child {
	margin-bottom: 0;
}

.column {
	flex-basis: 25%;
	display: flex;
	align-items: center;
	overflow: hidden;
	flex-shrink: 0;
	padding-right: 8px;
}

.column.roleColumn {
	flex-basis: 20%;
}

.column.emailColumn {
	flex-basis: 25%;
}

.column.actions {
	flex-basis: 30%;
	padding-right: 0;
	justify-content: flex-end;
}

.column.usernameWrapper {
	flex-grow: 1;
}

.usernameWrapper {
	gap: 8px;
}

.username {
	color: var(--global-primaryTextColor);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.usernameIcon {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	color: var(--global-primaryTextColor);
	background: #ccbef9;
	flex-shrink: 0;
}

.email {
	color: var(--global-secondaryTextColor);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.actions .actionButton,
.actions .actionButton:hover {
	padding: 6px 12px;
	border-radius: var(--item-radius-8);
	border: 1px solid #e9e9eb;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
	background: var(--global-primaryBackground);
}

.actionButtonText {
	color: var(--global-primaryTextColor);
	opacity: 0.9;
	line-height: normal;
}

.actionInfo {
	color: #4ba56f;
	margin-right: 16px;
}

.actions .actionRemoveButton,
.actions .actionRemoveButton:hover {
	font-weight: var(--global-base-font-weight);
	color: var(--global-amberColor);
	font-size: var(--global-base-fontSize);
	margin-right: 4px;
	background: var(--global-primaryBackground);
}

.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.inviteButton.disabled {
	color: var(--buton-disabled-text-color) !important;
	background: var(--button-disabled-bg-color) !important;
	opacity: initial;
}

.actionRemoveButton.disabled,
.actionRemoveButton.disabled:hover {
	color: #232633 !important;
	opacity: 1 !important;
}

@keyframes animate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.actions .actionButton.inviting {
	opacity: 0.7;
	cursor: not-allowed;
	background: linear-gradient(-45deg, #ffffff, #efefef, #fafafa, #ffffff);
	background-size: 500%;
	animation: animate 4s linear infinite;
}

.diamondIcon {
	margin-left: 8px;
}

.roleOwnerText {
	color: var(--color-secondary-dark-text);
	padding-left: 12px;
}
