.imageWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: var(--item-radius-8);
	flex-shrink: 0;
	cursor: pointer;
	overflow: hidden;
}

.image {
	pointer-events: none;
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.imageWhileLoading {
	height: 0;
}
