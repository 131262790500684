.wrapper,
.videoContainer {
	width: 100%;
	height: 100%;
	position: relative;
}

.wrapper {
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-300);
	background: lightgray -309.167px -40.701px / 169.711% 221.807% no-repeat;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.videoContainer {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.controlsWrapper {
	background: var(--color-grey-50);
	padding: 0 16px;
	display: flex;
	height: 67px;
	align-items: center;
	flex-shrink: 0;
	gap: 16px;
}

.timeSliderWrapper {
	flex-grow: 1;
}

.timeSliderWrapper .timeSlider {
	position: relative;
	top: initial;
	height: auto;
	background: transparent;
}

.controlsWrapper .playButton,
.controlsWrapper .playButton:hover {
	padding: 0;
	color: var(--color-grey-900);
	background: transparent;
}

.controlsWrapper .playButton.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.timeSliderWrapper .timeSliderValue {
    color: var(--global-secondaryTextColor);
    letter-spacing: 0.28px;
}

.controlsWrapper .iconButton,
.popupInputsWrapper .iconButton {
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-300);
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04), 0 1px 2px 0 rgba(16, 24, 40, 0.04);
	padding: 5px;
	color: var(--color-grey-800);
}

.popupInputsWrapper {
	display: flex;
	gap: 8px;
}

.popupInputsWrapper .iconButton {
	padding: 0px 9px;
	height: 36px;
}

.controlsWrapper .dropdown {
	margin: 0;
	min-width: 93px;
	z-index: 9999;
}

.dropdown .dropdownHeadText {
    font-size: var(--global-mini-font);
    font-weight: var(--global-title-font-weight);
    color: var(--global-primaryTextColor);
}

.dropdown .dropdownContainer {
	border-radius: var(--item-radius-8);
	background: var(--color-white);
}

.dropdown .dropdownSelect {
	border: 1px solid var(--color-grey-300);
	background: transparent;
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04), 0 1px 2px 0 rgba(16, 24, 40, 0.04);
	padding: 3px 12px;
	opacity: 0.9;
	border-radius: var(--item-radius-8);
}

.popoverRoot {
	z-index: 999999 !important;
}

.popoverRoot .popoverPaper {
	border-radius: var(--item-radius-8);
	border: 1px solid var(--color-grey-300);
	background: var(--color-white);
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
	width: 320px;
	padding: 16px;
	color: var(--color-grey-900);
	line-height: 20px;
}

.colorPrimary {
	color: var(--color-primary-400);
}

.thumbColorPrimary {
	color: white;
	border: 4px solid var(--color-primary-400);
	width: 18px;
	height: 18px;
}

.rail {
	color: var(--color-grey-400);
}

.slider {
	padding: 0 0 0 8px;
	flex-grow: 1;
	display: flex;
	align-items: center;
}

.popupContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.popupInputsWrapper .popupInput {
	width: 100%;
	height: 100%;
	border-radius: var(--item-radius-6);
	border: 1px solid var(--color-grey-300);
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
	padding: 0 12px 0 38px;
	color: var(--color-grey-900);
}

.popupInputContainer {
	position: relative;
	flex-grow: 1;
}

.clockIcon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 12px;
	color: var(--color-grey-700);
}

.buttonsWrapper {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}

.buttonsWrapper .cancelButton,
.buttonsWrapper .cancelButton:hover {
	border: 1px solid var(--color-grey-300);
	padding: 7px 12px;
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	border-radius: 8px !important;
	box-sizing: content-box;
	line-height: unset !important;
	height: auto;
	gap: 8px;
}

.buttonsWrapper .cancelButtonText {
	opacity: 0.9;
	font-size: var(--global-base-fontSize);
	color: var(--color-grey-900);
}

.buttonsWrapper .cancelButton:hover {
	background: var(--color-border);
}

.buttonsWrapper .confirmButton:hover,
.buttonsWrapper .confirmButton {
	opacity: 0.9;
	padding: 7px 12px !important;
	height: auto !important;
	line-height: unset !important;
}

.buttonsWrapper .confirmButton:hover {
	opacity: 1;
}

.dropdownMenuItemLabel {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdownMenuContainer {
	max-width: 400px;
}
