#root {
	height: 100vh;
	width: 100vw;
	position: relative;
	background: var(--global-primaryBackground);
	color: var(--global-primaryTextColor);
}

body {
	font-family: var(--global-font-family);
	font-size: var(--global-base-fontSize);
	font-weight: var(--global-base-font-weight);
}

.height-100-percent {
	height: 100%;
}

.MuiButton-text {
	color: var(--global-primaryTextColor);
	font-family: var(--global-font-family);
}

.MuiButton-outlined {
	border: var(--button-outlined-border);
	color: var(--button-outlined-text-color);
}

.MuiTablePagination-toolbar {
	align-items: baseline;
	padding: var(--tablePaginationToolbar-padding);
}

.hide {
	display: none;
}
