.container {
}

.container:before {
	content: '';
	width: 8px;
	height: 8px;
	position: absolute;
	background: orange;
	border-radius: 50%;
	left: -2px;
	top: -2px;
	box-shadow: 0 0 3px 0 orange;
}

.popupContainer {
	position: fixed;
	display: block;
	z-index: 9999;
	background-color: transparent !important;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.popperHidden {
	display: none;
}

.popper {
	display: flex;
	flex-direction: column;
	z-index: 9999;
	/*background: white;*/
}

.messageContainer {
	padding: 16px 12px;
}

.message {
	font-family: var(--global-font-family);
	font-size: 14px;
}

.bottom {
	flex-direction: column;
}

.top {
	flex-direction: column-reverse;
}

.left {
	flex-direction: row-reverse;
}

.right {
	flex-direction: row;
}

.anchor {
	display: inline-block;
	height: 25px;
	width: 25px;
	z-index: -1;
	flex-grow: 0;
	position: relative;
	transform-origin: 0 0;
}

.cardContainer {
	padding: 8px;
	background-color: var(--global-onboardingBackground) !important;
	color: var(--global-onboardingBackground-text) !important;
}

.orb {
	width: 10px;
	height: 10px;
	background: orange;
	position: fixed;
	border-radius: 50%;
	box-sizing: content-box;
	box-shadow: 0 0 0 6px #ffa50063;
}

.dashedLine {
	position: fixed;
	content: '';
	width: 1px;
	height: 100%;
	border-left: 1px dashed orange;
	transform: translate(5px, 10px);
	top: -4px;
}

.anchorBottom {
	transform: translateY(-5px) rotate(0deg);
}

.anchorTop {
	transform: translateY(25px) rotate(180deg);
}

.anchorLeft {
	transform: translateY(20px) rotate(90deg);
}

.anchorRight {
	transform: translateY(20px) rotate(270deg);
}

.footer {
	justify-content: end;
	padding: 8px 12px 12px 0;
}

.footer .closeButton,
.footer .closeButton:hover {
	margin: 0;
	padding: 0 24px;
	border-color: var(--global-themeColor) !important;
}
