.cardBody {
	height: 70vh;
}

.permissionBody {
	min-width: auto;
	width: min-content !important;
	padding: 40px 20px 20px 20px;
}

.leftSection {
	min-width: 220px;
	height: 100%;
	background: var(--color-grey);
	/*box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);*/
	border-right: 1px solid rgba(35, 38, 51, 0.1);
}

.templateItem {
	/*width: 230px !important;*/
	/*height: 132px !important;*/
	/*width: fit-content !important;*/
	height: fit-content !important;
}

.templateTitle {
	text-align: left;
	display: flex;
	align-items: center;
}

.templateTitleText {
	width: 235px;
}

.templateImage {
	width: 230px !important;
	height: 132px !important;
}

.templateDescription {
}

.listContainer {
	display: flex;
	flex-direction: column;
	gap: 1px;
	padding: 16px 16px;
	height: calc(100% - 56px);
	overflow: auto;
}

.listItem {
	position: relative;
	height: 36px;
	display: flex;
	font-size: var(--global-base1-fontSize);
	font-weight: var(--global-title-font-weight);
	align-items: center;
	border-radius: 8px;
	padding: 8px 16px;
	cursor: pointer;
	color: var(--global-primaryTextColor);
	text-align: left;
}

.listItem:nth-of-type(3):hover::after,
.listItem:nth-of-type(3)::after {
	content: '';
	position: absolute;
	width: calc(100% - 32px);
	height: 1px;
	border-bottom: 1px solid var(--global-border-color);
	bottom: -8px;
	left: 16px;
}

.listItem:hover,
.listItemActive {
	/*font-weight: var(--global-bold-font-subtext);*/
	color: var(--color-dark-primary);
	background: rgba(38, 96, 244, 0.06);
}

.listItemNonClickable,
.listItemNonClickable:hover {
	cursor: default;
	margin-top: 16px;
	opacity: 0.5;
	color: var(--color-dark-grey);
}

.listItem.listItemNonClickable:hover {
	background: unset;
	filter: unset;
}

.leftPaneTitle {
	padding: 32px 32px 0;
	margin: 0;
	font-weight: 600;
}

.rightSection {
	width: 685px;
	height: 100%;
	overflow: auto;
	padding-right: 40px;
	padding-bottom: 40px;
}

.rightPaneTitle {
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	padding: 32px 32px 0 40px;
	margin: 0 0 32px;
}

.searchBarContainer {
	padding-top: 16px;
}

.searchContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 0 4px 0;
}

.input {
}

.searchIcon {
	width: 22px;
	height: 22px;
	/*margin-right: 8px;*/
	/*margin-top: 18px;*/
}

.templatesContainer {
	gap: 32px !important;
	padding-left: 40px;
}

.noData {
	height: calc(100% - 56px);
}

.templateFooter {
	display: flex;
	justify-content: space-around;
	flex-direction: row;
}

.backButton:hover,
.backButton {
	border: var(--Button-outlined-border) !important;
}

.startButton:hover,
.startButton {
	color: var(--Button-color) !important;
	background-color: var(--Button-contained-background) !important;
	border: var(--Button-contained-border) !important;
}

.backButton:hover,
.startButton:hover {
	opacity: 0.8 !important;
}

.startButton.disabled,
.startButton.disabled:hover {
	opacity: 0.5;
	cursor: no-drop;
}

.installExtension {
	background: var(--global-primaryTextColor);
	border-radius: 16px;
	padding: 24px;
}

@media screen and (max-width: 640px) {
	.leftSection {
		height: auto;
	}
}

.hide {
	display: none;
}

.loaderWrapperClass {
	width: inherit;
	height: calc(100% - 84px);
	position: absolute;
	z-index: 1;
	background: #e5e5e57d;
}

.fileUploadContainer {
	border: 1.5px dashed var(--global-border-color);
	border-radius: 8px;
	margin-left: 41px;
	/* padding: 40px; */
	display: flex;
	justify-content: center;
}

.fileUploadWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 24px 0 40px 0;
	width: min(450px, 100%);
}

.fileUploadimageWrapper {
}

.fileUploaddragDropTextContainer {
	color: var(--local-themeColor);
}

.fileUploadsupportedFormats {
	color: var(--global-secondaryTextColor);
}

.fileUploador {
	color: var(--color-grey-900);
}

.uploadInputFile {
	height: 0;
	position: absolute;
}

.uploadButton,
.uploadButton:hover {
	flex-grow: 0;
	width: max-content;
	align-self: center;
	padding: 7px 12px !important;
	height: auto !important;
	line-height: unset !important;
}

.uploadButton:hover {
	background: var(--button-hover-bg-color);
}

.uploadButton.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.fileUploadingHeader {
	color: var(--color-grey-900);
	font-size: var(--global-page-heading-fontSize);
	font-weight: var(--global-title-font-weight);
}

.fileUploadingDescription {
	color: var(--color-grey-600);
	font-weight: 400;
}

.fileName {
	overflow: hidden;
	white-space: nowrap;
	padding-top: 8px;
	color: var(--color-grey-900);
	font-weight: var(--global-title-font-weight);
}

.fileUploadingTags {
	display: flex;
	flex-direction: row;
	gap: 4px;
	justify-content: center;
	padding-bottom: 16px;
}

.fileDetailsTag {
	background: var(--color-primary-50);
	color: var(--color-primary-400);
	padding: 2px 6px;
	border-radius: 24px;
}

.dotIcon {
	margin: 0 4px;
}

.fileUploadingProgress {
}

.fileUploadingProgress .dashed {
	left: 0;
	background-image: radial-gradient(
		var(--newFloPage-linearProgress-color) 0%,
		var(--newFloPage-linearProgress-color) 16%,
		transparent 42%
	);
}
.fileUploadingProgress .progressBar,
.progressBarRoot .progressBarColorPrimary {
	background-color: var(--newFloPage-linearProgress-color);
}

.uploadingData {
	font-size: 12px;
	font-weight: 400;
	padding-top: 8px;
}

.cancelButton {
	height: 36px !important;
}

.error {
	color: var(--color-orange);
	font-size: 14px;
	font-weight: 500;
}

.uploadCountInfo {
	color: var(--global-primaryTextColor);
	font-weight: var(--global-base-font-weight);
}

.rightPaneTitleWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.uploadDisabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.uploadDisabled .uploadButton {
	pointer-events: none;
}

.diamondIcon {
	margin-left: 8px;
}

.hovering {
}

.hovering * {
	pointer-events: none;
}

.onHovering {
	pointer-events: none;
	display: none;
	position: absolute;
	/* left: 50%; */
	/* top: 50%; */
	height: 100%;
	justify-content: center;
	align-items: center;
	width: 100%;
	background: #ffffffc4;
	z-index: 9;
	font-size: 22px;
	font-weight: 500;
}

.hovering .onHovering {
	display: flex;
}

.uploadDisabled.hovering {
	background: transparent;
}
