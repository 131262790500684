.dialogRoot {
	z-index: 99999 !important;
}

.dialogRoot .container {
	background-color: var(--profileDialog-container-background);
	border-radius: var(--profileDialog-container-border-radius);
}

.dialogRoot .contentWrapper {
	display: flex;
	flex-direction: var(--profileDialog-contentWraper-flex-direction);
	min-width: var(--profileDialog-contentWraper-min-width);
	background-color: var(--profileDialog-contentWraper-background);
	color: var(--profileDialog-contentWraper-text-color) !important;
}

.content,
.title {
	color: var(--profileDialog-title-text-color) !important;
	font-family: var(--profileDialog-title-font-family) !important;
	font-style: normal;
	font-weight: var(--profileDialog-title-font-weight) !important;
	font-size: var(--profileDialog-title-font-size) !important;
}

.title {
	padding: 0 !important;
}

.closeIcon {
	color: var(--profileDialog-title-text-color) !important;
	font-size: 16px;
}

.contentSubHeader {
	padding: var(--profileDialog-content-sub-header-padding) !important;
	color: var(--profileDialog-title-text-color) !important;
	opacity: var(--profileDialog-content-sub-header-opacity);
	font-family: var(--profileDialog-content-sub-header-font-family) !important;
	font-style: normal;
	font-weight: var(--profileDialog-content-sub-header-font-weight);
	font-size: var(--profileDialog-content-sub-header-font-size) !important;
}

.content {
	padding: 20px 0 0 0 !important;
}

.avatar,
.avatar .profilePicContainer {
	height: var(--profileDialog-avatar-height) !important;
	width: var(--profileDialog-avatar-width) !important;
	border-radius: var(--profileDialog-avatar-border-radius);
	cursor: pointer;
	flex-shrink: 0;
}

.nameInputContainer {
	padding: var(--profileDialog-nameInputContainer-padding);
	width: 100%;
	max-width: 360px;
}

.nameTitle {
	font-family: var(--profileDialog-naameTitle-font-family);
	font-style: normal;
	font-weight: var(--profileDialog-naameTitle-font-weight);
	font-size: var(--profileDialog-naameTitle-font-size);
	line-height: var(--profileDialog-naameTitle-line-height);
}

.nameInput {
	padding: var(--profileDialog-naameInput-padding);
}

.input input {
	background: var(--profileDialog-input-background);
	border: var(--profileDialog-input-border);
	border-radius: var(--profileDialog-input-border-radius);
	padding: var(--profileDialog-input-padding);
	font-family: var(--profileDialog-input-font-family);
	font-style: normal;
	font-weight: var(--profileDialog-input-font-weight);
	color: var(--profileDialog-input-text-color);
	font-size: var(--profileDialog-input-font-size);
	line-height: var(--profileDialog-input-line-height);
}

.input div::before,
.input div::after {
	content: unset;
}

.buttonContainer {
	padding: var(--profileDialog-button-container-padding) !important;
}

.skipButton,
.skipButton:hover {
	font-weight: var(--profileDialog-skipButton-font-weight) !important;
	color: var(--profileDialog-skipButton-text-color) !important;
	border-color: var(--profileDialog-skipButton-border-color) !important;
	text-transform: none !important;
	font-family: var(--profileDialog-button-font-family);
}

.saveButton:hover,
.saveButton {
	font-weight: var(--profileDialog-saveButton-font-weight) !important;
	background: var(--profileDialog-saveButton-background) !important;
	border-color: var(--profileDialog-saveButton-border-color) !important;
	color: var(--profileDialog-saveButton-color) !important;
	text-transform: none !important;
	margin-left: 0 !important;
	margin-top: 10px !important;
	font-family: var(--profileDialog-button-font-family);
	box-shadow: none !important;
}

.skipButton:hover,
.saveButton:hover {
	opacity: var(--profileDialog-skipButton-hover-opacity);
}

.saveButton:disabled {
	opacity: var(--profileDialog-saveButtonDisabled-opacity);
}

.skipButton,
.saveButton {
	border-radius: var(--profileDialog-button-border-radius) !important;
}

.popover {
	border: var(--profileDialog-popover-border);
	box-shadow: var(--profileDialog-popover-box-shadow);
	border-radius: var(--profileDialog-popover-border-radius);
	overflow: visible;
	background: var(--profileDialog-popover-background) !important;
}

.picChooserRoot {
	z-index: 999999 !important;
}

.menuList {
	display: grid;
	width: var(--profileDialog-menulist-width);
	padding: var(--profileDialog-menulist-padding);
	grid-template-columns: var(--profileDialog-menulist-grid-template-columns);
	row-gap: var(--profileDialog-menulist-row-gap);
	column-gap: var(--profileDialog-menulist-column-gap);
	overflow: auto;
	height: var(--profileDialog-menulist-height);
	overflow-x: hidden;
}

.avatarHeader {
	font-weight: var(--profileDialog-avatarHeader-font-weight);
	font-size: var(--profileDialog-avatarHeader-font-size);
	margin: var(--profileDialog-avatarHeader-margin);
	color: var(--profileDialog-avatarHeader-text-color);
}

.menuItem,
.menuItem:hover {
	cursor: pointer;
	border-radius: 16px;
}

.avatarPopup,
.avatarPopup .profilePicContainer {
	height: var(--profileDialog-profilePicContainer-width);
	width: var(--profileDialog-profilePicContainer-height);
	border-radius: var(--profileDialog-profilePicContainer-border-radius);
}

.avatarPopup {
	border: var(--profileDialog-avatarPopup-border);
	box-sizing: content-box;
}

.avatarPopup.active,
.avatarPopup:hover {
	border: var(--profileDialog-avatarPopup-hover-border);
}

.editButton {
	color: var(--color-dark-primary) !important;
}

.text {
	padding: var(--profileDialog-naameInput-padding);
	font-weight: var(--global-base-font-weight);
}

@media screen and (max-width: 640px) {
	.title {
		padding: var(--profileDialog-title-padding-tablet) !important;
		text-align: center;
		box-sizing: border-box;
	}

	.content {
		padding: var(--profileDialog-content-padding-tablet) !important;
	}

	.contentSubHeader {
		text-align: center;
		padding-bottom: var(
			--profileDialog-contentSubHeader-padding-bottom-tablet
		) !important;
	}

	.dialogRoot .contentWrapper {
		min-width: auto;
	}

	.avatar,
	.avatar .profilePicContainer {
		margin-top: var(--profileDialog-profilePicContainer-margin-top-tablet);
		height: var(--profileDialog-profilePicContainer-height-tablet);
		width: var(--profileDialog-profilePicContainer-width-tablet);
	}

	.buttonContainer {
		justify-content: center !important;
		padding-bottom: var(--profileDialog-buttonContainer-padding-bottom-tablet) !important;
	}

	.saveButton {
		margin: 0 !important;
	}
}
