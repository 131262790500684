.cardBody {
	height: fit-content;
}

.headerSection {
	align-items: center;
	display: flex;
	text-align: left;
	padding: 32px 0 0 29px;
	width: 0;
}

.headerText {
	padding-left: 8px;
	color: var(--global-primaryTextColor);
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
}

.contentWrapper {
	height: fit-content;
}

.isGuideContainer {
	height: calc(100% - 56px);
}

.templatePopup {
	/*top: 0;*/
	/*left: 0;*/
	/*right: 0;*/
	/*bottom: 0;*/
	/*position: fixed;*/
	/*margin: 60px 50px;*/
	/*z-index: 9999;*/
	/*background: #ffffff;*/
	/*border: 1px solid rgba(0, 0, 0, 0.1);*/
	/*box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),*/
	/*	0 8px 8px -4px rgba(16, 24, 40, 0.03);*/
	/*border-radius: 16px;*/
}

.leftSection {
	display: flex;
	align-items: flex-start;
	flex-direction: column !important;
	height: fit-content;
	padding: 28px 16px 16px 32px;
	gap: 24px;
	overflow: auto;
	min-width: 350px;
	/*max-width: 350px !important;*/
}

.templateItem {
	width: 100%;
	height: fit-content;
}

.templateTitle {
	margin-top: 32px;
	text-align: left;
}

.templateTitleText {
}

.templateImage {
	width: 100%;
	background-repeat: no-repeat;
	height: fit-content;
}

.templateDescription {
	opacity: 0.8;
	color: var(--global-primaryTextColor);
	font-style: normal;
	font-weight: 400;
	font-size: var(--global-base-fontSize);
	line-height: 22px;
	text-align: start;
}

.templateSubHeading {
	font-style: normal;
	font-weight: 500;
	font-size: var(--global-base-fontSize);
	line-height: 20px;
	text-transform: uppercase;
	color: var(--global-primaryTextColor);
}

.howToWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 24px;
	column-gap: 12px;
}

.howToElem {
	border: 1px solid #e9e9eb;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 155px;
	align-items: center;
	cursor: pointer;
	height: 32px;
	padding: 0 8px;
}

.howToElem:hover {
	opacity: 0.8;
	color: unset;
}

.howToElem:disabled {
	opacity: 0.6;
}

.iconTextWrapper {
	display: flex;
	align-items: center;
}

.howToElemText {
	white-space: nowrap;
	padding: 0 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.warningIcon {
	color: #ffc107;
	width: 16px;
	height: 16px;
	margin-right: 4px;
}

.cancelButton {
	color: black;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.howToBtn {
	color: var(--brand-color-button-background-color);
}

/* Add left margin to all child divs except the first one */
.templateHowToIframe {
	width: 100%;
	height: 100%;
	border-radius: 8px;
}

.rightSection {
	box-sizing: border-box;
	min-width: calc(100% - 350px);
	height: calc(100% - 43px);
	width: 100%;
	/*min-height: 400px;*/
	padding: 0 32px 32px 16px;
}

.isGuideIframe {
	width: 860px;
	height: 670px;
}

@media screen and (max-width: 640px) {
	.leftSection {
		height: auto;
	}
}

section.templateContainer {
	width: 100%;
}

.usesTitle {
}

.usesTitle {
	font-size: 15px;
	font-weight: 600;
	padding-bottom: 8px;
}

section.howTo {
	color: var(--global-primaryTextColor);
	text-align: left;
	width: 100%;
}

ul.usesList {
	text-align: left;
	list-style: disc;
	padding: 0 16px;
}

ul.usesList.styleNone {
	list-style: none;
	padding: 0;
}

li.usesListItem {
	padding: 4px 0 0 0;
}

ul.usesList.styleNone li.usesListItem {
	display: flex;
	padding: 4px 0 8px 0;
	align-items: flex-start;
}

section.permissionsRequired {
	text-align: left;
	color: var(--global-primaryTextColor);
	width: 100%;
}

.permissionNeededTitle {
	font-size: 15px;
	font-weight: 600;
	text-align: left;
	padding-bottom: 8px;
}

.video {
	border: 1px solid rgba(72, 72, 139, 0.58);
	max-width: min(320px, 100%);
	max-height: 180px;
	background: #1c1f2c;
	border-radius: 8px;
}

.video.hideVideo {
	display: none;
}

.micPlayerContainer {
	width: min(320px, 100%);
	height: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f7f7f7;
	border-radius: 8px;
}

.micPlayerContainer.hideMicrophoneContainer {
	display: none;
}

.permissionHelperContainer {
	flex-direction: column;
	justify-content: space-between;
	font-size: 15px;
	font-weight: 500;
	height: 160px;
}

.permissionHelperHeading {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	color: var(--global-primaryTextColor);
	opacity: 0.7;
	padding: 16px 0;
}

.permissionHelperImage {
	width: 248px;
	height: 118px;
	background-image: url(https://zeus.floik.com/system/app/media/images/step-by-step-preview-image.svg);
	background-position-y: bottom;
	background-repeat: no-repeat;
}

.micSvgWrapper {
	position: relative;
	/*box-sizing: content-box;*/
	/*background: rgb(85 145 108 / 10%);*/
	/*border-radius: 50%;*/
	/*height: 64px;*/
	/*width: 64px;*/
	/*display: inline-block;*/
	/*color: var(--global-themeColor);*/
	/*border: 16px solid rgb(255 255 255 / 50%);*/
	/*padding: 8px 13px;*/
}

.micSvgWrapper svg {
	/*margin: 25% 25%;*/
	/*height: 34px;*/
	/*width: 34px;*/
}

.mic {
	position: absolute;
	left: 33%;
	top: 33%;
	width: 34px;
	height: 34px;
	color: var(--global-themeColor);
}

ul.permissionList {
	/* width: 100%; */
	/* display: flex; */
	/* flex-direction: column; */
}

li.permissionListItem {
	display: grid;
	grid-template-columns: 25px calc(100% - 50px) 25px;
	background: #f8f8f8;
	padding: 10px 12px;
	margin-bottom: 6px;
	border-radius: 8px;
}

.permissionItemTIck svg {
	height: 16px;
	width: 16px;
}

.permissionItemIcon img {
	height: 16px;
}

.permissionItemTIck {
	color: rgba(0, 0, 0, 0.3);
	text-align: end;
}

.permissionItemTIck.enabled {
	color: var(--color-dark-green);
}

.permissionDescription {
	font-weight: 400;
	font-size: var(--global-base-fontSize);
	line-height: 14px;

	/* dark- grey */
	color: var(--global-primaryTextColor);
}

.permissionItemIcon {
	color: #171921;
}

.permissionSubDescription {
	display: block;
	color: var(--color-dark-primary);
	cursor: pointer;
}

.installExtension {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: 24px;
}

.installExtensionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
}

.chromeVector {
	height: 116px;
	position: absolute;
	top: -44px;
	left: 31%;
}

.installExtensionRightSection {
	position: absolute;
	right: -28px;
	top: -28px;
}

.installExtensionHeader {
	font-family: var(--global-font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: var(--global-primaryTextColor);
}

.installExtensionDescription {
	font-family: var(--global-font-family);
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.02em;
	color: var(--global-primaryTextColor);
	padding: 24px 0;
}

.chromeBtn {
	color: #ffffff;
	margin-right: 6px;
}

.installExtensionLeftSection {
	text-align: left;
}

.installExtensionButton,
.installExtensionButton:hover,
.installExtensionButton:visited {
	background-color: var(--global-themeColor) !important;
	color: var(--global-button-text-color) !important;
	font-size: var(--global-base-fontSize) !important;
	font-weight: 500 !important;
	line-height: 20px !important;
}

.installExtensionButtonIcon {
	margin-left: 8px;
}

.templatePermissionError {
	padding: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.permissionDescription {
	font-weight: 400;
	font-size: var(--global-base-fontSize);
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
	color: #000000;
	opacity: 0.7;
	width: 325px;
}

.deviceSelection {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 32px 18px;
}

.permissionTitle {
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	/* identical to box height, or 111% */

	text-align: center;

	color: #000000;
}

.startButton,
.startButton:hover,
.startButton:disabled {
	background-color: var(--brand-color-button-background-color) !important;
	color: var(--brand-color-button-text-color) !important;
	width: 100%;
	max-width: min(320px, 100%);
	font-size: var(--global-base-fontSize) !important;
}

.startButton:hover {
	background: var(--button-hover-bg-color) !important;
}

.startButton:disabled {
	opacity: 0.6;
}

.listIcon {
	padding-right: 8px;
	width: 22px;
}

.mr2 {
	margin-right: 7px;
}

.helperContainerView {
	width: 320px;
	height: 160px;
	margin-bottom: 24px;
}

.helperContainerView > img {
	width: 100%;
	border-radius: 8px;
	box-shadow: #ddd 1px 2px 8px;
}

.helpText {
	font-size: var(--global-base1-fontSize);
	font-weight: var(--global-base-font-weight);
	align-items: center;
	padding: 8px 16px;
}

.helpLink {
	font-weight: var(--global-base-font-weight);
	color: var(--color-dark-primary);
}

.buttonsContainer {
	display: flex;
	width: 100%;
	padding-top: 16px;
}

.buttonsContainer button {
	width: 50%;
}

.buttonsContainer .cancelButton {
	border: 1px solid var(--color-border);
	padding: 5px 12px;
	background: var(--color-white);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	border-radius: var(--dialog-button-border-radius) !important;
}

.buttonsContainer .cancelButtonText {
	opacity: 0.9;
	font-size: var(--dialog-footer-button-font-size);
	color: var(--color-black);
}

.buttonsContainer .cancelButton:hover {
	background: var(--color-border);
}

.buttonsContainer .confirmButton:hover,
.buttonsContainer .confirmButton {
	font-weight: var(--dialog-confirmButton-font-weight) !important;
	background: var(--dialog-saveButton-background) !important;
	border-color: var(--dialog-saveButton-border-color) !important;
	text-transform: none !important;
	margin-left: var(--dialog-saveButton-margin) !important;
	font-family: var(--dialog-button-font-family);
	box-shadow: none !important;
	border-radius: var(--dialog-button-border-radius) !important;
	padding: 5px 12px !important;
	font-size: var(--dialog-footer-button-font-size);
}

.buttonsContainer .confirmButton:hover {
	opacity: var(--dialog-skipButton-hover-opacity);
}

.permissionImage {
	height: 165px;
}
